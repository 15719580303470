import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {store} from './app/store';
import App from './app/App';

import {ToastContainer} from 'react-toastify';
import './i18n.ts';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <>
    <Provider store={store}>
      <>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        <ToastContainer autoClose={1000} />
      </>
    </Provider>
  </>
);
