import styles from './ToggleSwitch.module.scss';

interface ToggleSwitchProps {
  id?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (params: boolean) => void;
}

export default function ToggleSwitch({
  id = 'switch',
  className = '',
  label,
  disabled,
  checked,
  onChange
}: ToggleSwitchProps) {
  const handleChange = () => onChange(!checked);

  return (
    <label className={`${styles.root}${disabled ? ` ${styles.disabled}` : ''}${className && ` ${className}`}`}>
      {label && (
        <label className={`${styles.label} truncated`} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        className={`${styles.input} visuallyHidden`}
        type='checkbox'
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      />
      <span className={styles.slider} />
    </label>
  );
}
