import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {langList} from '../../../app/const';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';

import {changeLanguage, selectLanguage} from '../../../app/profileSlice';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import {ReactComponent as CheckmarkIcon} from '../../../assets/icons/checkmark.svg';
import styles from './BotLanguage.module.scss';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

export default function BotLanguage() {
  const navigate = useNavigate();
  const language = useAppSelector(selectLanguage);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const [selectedLang, setSelectedLang] = useState(language);
  const handleLang = (event: any) => setSelectedLang(event.target.value);
  const saveLang = () => {
    dispatch(changeLanguage({language: selectedLang}));

    i18n.changeLanguage(selectedLang);

    navigate(-1);
  };

  useEffect(() => {}, []);

  return (
    <PageWrap mainClass='pt32 px16 pb16' title={t('BOTLANGUAGE.bot')}>
      <div className='btnGroup mb32'>
        {langList.map(({value, icon, label}) => (
          <label key={value} className={`${styles.langBtn} ${language === 'en' ? 'navBtn' : 'navBtn lower'}`}>
            <input
              className='visuallyHidden'
              type='radio'
              value={value}
              checked={selectedLang === value}
              onChange={handleLang}
            />
            <img src={icon} alt='Flag' width='24px' height='24px' />
            <span className='label'>{label}</span>
            {selectedLang === value && <CheckmarkIcon />}
          </label>
        ))}
      </div>

      <Button className={styles.btn} disabled={language === selectedLang} onClick={saveLang}>
        {t('BOTLANGUAGE.save')}
      </Button>
    </PageWrap>
  );
}
