import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { API_BASE_URL } from '../../app/const';
export interface singleInterest {
  id: number;
  name: string;
  count_profiles: number;
}

export interface EventsList {
  active: Active[];
  recent: Recent[];
}

export interface Active {
  id: number;
  name: string;
  venue: Venue;
  status: string;
  picture: string;
}

export interface Venue {
  id: number;
  name: string;
  address: string;
  picture: string;
}

export interface Recent {
  id: number;
  name: string;
  venue: Venue;
  status: string;
  picture: string;
}

export interface SingleEvent {
  id: number;
  venue: VenueFull;
  contact_number: string;
  images: any;
  interest: any;

  pictures: any[];
  name: string;
  description: string;
  status: string;
  start_date: string;
  end_date: string;
  latitude: number;
  longitude: number;
  address: string;
  interesed_people: number;
  qr_code_amount: number;
  profile: number;
  is_canceled: boolean;
  is_attended: boolean;
  is_interested: boolean;
  total_attendees: number;
  max_attendees: number;

}

export interface VenueFull {
  id: number;
  images: [];
  name: string;
  pictures: any;
  description: string;
  status: string;
  contact_number: string;
  created: string;
  latitude: number;
  longitude: number;
  address: string;
  rate: number;
  qr_code_amount: number;
  profile: number;
}

export interface AttendedEvents {
  id: number;
  name: string;
  picture: string;
  start_date: string;
  venue: {
    id: number;
    name: string;
    address: string;
  };
  status: string;
}

export interface SingleAttendEvent {
  photos: string[];
  is_interested: boolean;
  interesed_people: number,
  max_attendees: number,
  total_attendees: number,
  event: {
    id: number;
    name: string;
    description: string;
    start_date: string;
    status: string;
    interesed_people: number | null;
    creator: {
      full_name: string;
      picture: string;
    };
    venue: {
      id: number;
      name: string;
      address: string;
    };
  };
  interests: string[];
  is_interested_by_user: boolean;
}

export interface Profile {
  id: number;
  first_name: string;
  picture: string;
  gender: string;
  bio: string;
  birth_date: string;
  city: string;
}

export interface eventsInterface {
  loading: string;
  error: string | undefined;
  events: EventsList[] | any;
  interests: singleInterest[];
  selectedInterest: singleInterest[] | [];
  selectedInterestNames: string[];
  singleEvent: SingleEvent | null;
  attendedEvents: { attended_events: AttendedEvents[], interested_events: AttendedEvents[] };
  attendedSingleEvent: SingleAttendEvent | null;
  eventsSearchedResult: [],
  search_id: string,
  candidates: Profile[] | null;
  isAttendedEventsRequestUsed: boolean;
  closestVenuesList: {
    our_venues: any,
    positionstack_venues: any,
    positionstack_request_id: string
  },
  searchResults: {
    events: number,
    id: string,
    interested: number,
    more_events: number,
    more_interested: number
  }
}

export const initialState: eventsInterface = {
  loading: '',
  error: '',
  interests: [],
  isAttendedEventsRequestUsed: false,
  selectedInterest: [],
  selectedInterestNames: [],
  singleEvent: null,
  events: null,
  eventsSearchedResult: [],
  attendedEvents: { attended_events: [], interested_events: [] },
  attendedSingleEvent: null,
  search_id: "",
  candidates: null,
  closestVenuesList: {
    our_venues: [],
    positionstack_venues: [],
    positionstack_request_id: ""
  },
  searchResults: {
    events: 0,
    id: "",
    interested: 0,
    more_events: 0,
    more_interested: 0
  }
};

export const getInterests = createAsyncThunk('GET_LIST_OF_INTERESTS', async (request?: string) => {
  const response = await axios.get(
    `${API_BASE_URL}/profiles/interests/${request ? `?search=${request}` : '?page=1'}`
  );
  return response.data;
});

export const getListOfEvents = createAsyncThunk('GET_LIST_OF_EVENTS', async () => {
  const response = await axios.get(`${API_BASE_URL}/creator/my/events/`);
  return response.data;
});

export const getSingleEvent = createAsyncThunk('GET_SINGLE_EVENT', async (id: any) => {
  const response = await axios.get(`${API_BASE_URL}/creator/my/events/${id}/`);
  return response.data;
});

export const getEventsList = createAsyncThunk('GET_ATTENDED_EVENTS', async () => {
  const response = await axios.get(`${API_BASE_URL}/profiles/my/events/`);
  return response.data;
});

export const getAttendedSingleEvent = createAsyncThunk('GET_ATTENDED_SINGLE_EVENT', async (id: string | number) => {
  const response = await axios.get(`${API_BASE_URL}/creator/public/events/${id}/`);
  return response.data;
});

export const getListOfIneterestedPeople = createAsyncThunk('GET_INTERESTED_PEOPLE', async (requestBody: { id: string | number, type: string }) => {
  const response = await axios.get(`${API_BASE_URL}/creator/my/events/${requestBody.id}/${requestBody.type}/`);
  return response.data;
});

export const addEvent = createAsyncThunk('ADD_EVENT', async (body: {
  name: string,
  description: string,
  start_date: string,
  end_date: string,
  max_attendees: number
}) => {
  const response = await axios.post(`${API_BASE_URL}/creator/my/create-event/`, body);
  return response.data;
});

export const togleAsInterested = createAsyncThunk('TOGLE_AS_INTERESTED', async (id: number | string) => {
  const response = await axios.post(`${API_BASE_URL}/creator/events/${id}/toggle-as-interest/`, {});
  return response.data;
});

export const searchEvents = createAsyncThunk('SEACRH_EVENTS', async (body: any) => {
  const response = await axios.post(`${API_BASE_URL}/searches/event/`, body);
  return response.data;
});

export const getSearchedData = createAsyncThunk('GET_SEACRH_EVENTS', async (requestParams: {
  id: string,
  type: "matching" | "other"
}) => {
  const response = await axios.get(`${API_BASE_URL}/searches/event/${requestParams.id}/?type=${requestParams.type}`,);
  return response.data;
});


export const cancelEvent = createAsyncThunk('CANCEL_EVENT', async (id: number,) => {
  const response = await axios.patch(`${API_BASE_URL}/creator/my/events/${id}/`, {
    is_canceled: true
  });
  return response.data;
});

export const deleteEvent = createAsyncThunk('DELETE_EVENT', async (id: number,) => {
  const response = await axios.delete(`${API_BASE_URL}/creator/my/events/${id}/`);
  return response.data;
});

export const updateEventData = createAsyncThunk('UPDATE_EVENT_DATA', async (request: { id: number | string, data: any }) => {
  const response = await axios.patch(`${API_BASE_URL}/creator/my/events/${request.id}/`, request.data);
  return response.data;
});

export const toggleAsAttended = createAsyncThunk('TOGGLE_ATTENDING', async (id: number | string) => {
  const response = await axios.post(`${API_BASE_URL}/creator/events/${id}/toggle-as-attended/`, {});
  return response.data;
});

export const checkInterestedPeople = createAsyncThunk('CHECK_INTERESTED_PEOPLE', async (id: number | string) => {
  const response = await axios.post(`${API_BASE_URL}/searches/event/${id}/total/`, {});
  return response.data;
});

export const checkInterestedPeopleByCreator = createAsyncThunk('CHECK_INTERESTED_PEOPLE_BY_CREATOR', async (id: number | string) => {
  const response = await axios.get(`${API_BASE_URL}/creator/my/events/${id}/interested/`, {});
  return response.data;
});


export const updateEventVenue = createAsyncThunk('UPDATE_EVENT_VENUE', async (body: {
  event_id: number,
  id: number
}) => {
  const response = await axios.post(`${API_BASE_URL}/creator/my/events/${body.event_id}/venue/`, { id: body.id });
  return response.data;
});


export const closestVenues = createAsyncThunk('GET_CLOSEST_VENUES', async (body: {
  latitude: number,
  longitude: number
}) => {
  const response = await axios.post(`${API_BASE_URL}/creator/public/closest-venues/`, body);
  return response.data;
});



export const verifyEvent = createAsyncThunk('VERIFY_EVENT', async (data: {
  id: number,
  body: {
    code: number,
    phone_number: string
  }
}) => {
  const response = await axios.post(`${API_BASE_URL}/creator/my/events/${data.id}/verify-code/`, data.body);
  return response.data;
});



export const getVenueByPs = createAsyncThunk('GET_VENUE_BY_PS', async (body: {
  id: number,
  name: string

}) => {
  const response = await axios.post(`${API_BASE_URL}/creator/public/get-venue-by-ps-name/${body.id}/`, { name: body.name });
  return response.data;
});



const eventsSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setInterestsParams: (state: any, action) => {
      state.selectedInterest = action.payload;
      state.selectedInterestNames = action.payload.map((item: any) => item.name);
    }
  },
  extraReducers(builder) {
    builder

      //GET LIST OF INTERESTS

      .addCase(getInterests.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getInterests.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.interests = action.payload.results ? action.payload.results : action.payload;
      })
      .addCase(getInterests.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET CLOSEST VENUES

      .addCase(closestVenues.pending, (state, action) => {
        state.loading = 'loading';
        state.closestVenuesList = {
          our_venues: [],
          positionstack_venues: [],
          positionstack_request_id: ""
        }
      })
      .addCase(closestVenues.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.closestVenuesList = action.payload
      })
      .addCase(closestVenues.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //GET LIST OF EVENTS

      .addCase(getListOfEvents.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getListOfEvents.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.events = action.payload;
      })
      .addCase(getListOfEvents.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET SINGLE EVENT
      .addCase(getSingleEvent.pending, (state, action) => {
        state.loading = 'loading';
        state.singleEvent = null
      })
      .addCase(getSingleEvent.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.singleEvent = action.payload;
      })

      .addCase(getSingleEvent.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET EVENTS LIST

      .addCase(getEventsList.pending, (state, action) => {
        state.loading = 'loading';
        state.attendedEvents = { attended_events: [], interested_events: [] };
      })
      .addCase(getEventsList.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.attendedEvents = action.payload;
        state.isAttendedEventsRequestUsed = true;

      })

      .addCase(getEventsList.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //SEARCH EVENTS (POST PARAMS)

      .addCase(searchEvents.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(searchEvents.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.search_id = action.payload.id
        state.searchResults = action.payload
      })

      .addCase(searchEvents.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //SEARCH EVENTS (GET EVENTS)

      .addCase(getSearchedData.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getSearchedData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.eventsSearchedResult = action.payload.events;
      })

      .addCase(getSearchedData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //GET ATTENDED SINGLE EVENT

      .addCase(getAttendedSingleEvent.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getAttendedSingleEvent.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.attendedSingleEvent = action.payload;
      })

      .addCase(getAttendedSingleEvent.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET LIST OF INTERESTED PEOPLE FOR SINGLE EVENT (CREATOR MODE)

      .addCase(getListOfIneterestedPeople.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getListOfIneterestedPeople.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.candidates = action.payload;
      })

      .addCase(getListOfIneterestedPeople.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //ADD EVENT
      .addCase(addEvent.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(addEvent.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //TOGLE AS INTERESTED

      .addCase(togleAsInterested.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(togleAsInterested.fulfilled, (state, action) => {
        state.loading = 'succeeded';

      }
      )
      .addCase(togleAsInterested.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )


      //Cancel Event

      .addCase(cancelEvent.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(cancelEvent.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(cancelEvent.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )


      //Delete event

      .addCase(deleteEvent.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(deleteEvent.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )



      //Update Event Data

      .addCase(updateEventData.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(updateEventData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.singleEvent = action.payload;
      }
      )
      .addCase(updateEventData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )

      //toggle as attended

      .addCase(toggleAsAttended.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(toggleAsAttended.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(toggleAsAttended.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //check interested people (send message to bot)

      .addCase(checkInterestedPeople.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(checkInterestedPeople.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(checkInterestedPeople.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message;
      })

      //get list of interested people (creator mode)
      .addCase(checkInterestedPeopleByCreator.pending, (state, action) => {
        state.loading = 'loading';
      }
      )

      .addCase(checkInterestedPeopleByCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(checkInterestedPeopleByCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })






  }
});


export const getClosestVenuesList = (state: RootState) => state.events.closestVenuesList;
export const interestsList = (state: RootState) => state.events.interests;
export const eventsList = (state: RootState) => state.events.events;
export const searchResults = (state: RootState) => state.events.searchResults;
export const singleEvent = (state: RootState) => state.events.singleEvent;
export const attendedEvent = (state: RootState) => state.events.attendedSingleEvent;
export const attendedEvents = (state: RootState) => state.events.attendedEvents;
export const selectedInterest = (state: RootState) => state.events.selectedInterest;
export const selectedInterestNames = (state: RootState) => state.events.selectedInterestNames;
export const selectedCandidates = (state: RootState) => state.events.candidates;
export const matchedEvents = (state: RootState) => state.events.eventsSearchedResult;
export const selectLoading = (state: RootState) => state.events.loading;
export const selectEventsAllInfo = (state: RootState) => state.events;

export const { setInterestsParams } = eventsSlice.actions;

export default eventsSlice.reducer;
