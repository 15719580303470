import PageWrap from '../../../layout/PageWrap';
import PeopleSlider from '../../../shared/PeopleSlider';
// import styles from './AttendingPeopleResults.module.scss';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';

interface EventPeopleResultsProps {
  interested?: boolean;
}

export default function EventPeopleResults({interested}: EventPeopleResultsProps) {
  const {t} = useTranslation();

  //const type = new URL(window.location.href).searchParams.get('type');
  //get query params from url
  const [type, setType] = useState<string | any>();
  const search = useLocation()?.search;
  const splitSearch = search?.split('?');

  useEffect(() => {
    splitSearch.map((item) => {
      return item.includes('type') && setType(item.split('=')[1]);
    });
  }, []);


  return (
    <PageWrap
      title={`${type === 'interested' ? t('EVENTPEOPLE.interested') : t('EVENTPEOPLE.attneding')} ${t(
        'EVENTPEOPLE.people'
      )}`}
    >
      <PeopleSlider />
    </PageWrap>
  );
}
