import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {langList, Path} from '../../app/const';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import PageWrap from '../../layout/PageWrap';
import ToggleSwitch from '../../shared/ToggleSwitch';
import Button from '../../shared/Button';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {
  patchPersonalData,
  selectLanguage,
  selectPersonalInfo,
  selectProfileType,
  fetchPersonalData,
  selectProfileUser,
  selectProfileCreator,
  resetState
} from '../../app/profileSlice';
import {toast} from 'react-toastify';
import styles from './Profile.module.scss';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as LogOut} from './ProfilePreview/images/Out.svg';

export default function Profile() {
  const dispatch = useAppDispatch();
  const lang = useAppSelector(selectLanguage);
  const navigate = useNavigate();
  const profile_type = useAppSelector(selectProfileType);
  const userData = useAppSelector(selectProfileUser);
  const personalInfo = useAppSelector(selectPersonalInfo);
  const creatorInfo = useAppSelector(selectProfileCreator);
  const {t} = useTranslation();
  const [fistLoad, setFirstLoad] = useState(true);
  const [publicProfile, setPublicProfile] = useState(false);
  const toastText = t('PROFILE.please');

  const [preview, setPreview] = useState({render: false, show: false});
  const handlePreview = (state: boolean) => {
    if (!state) {
      setPreview((prev) => ({...prev, show: false}));
      setTimeout(() => {
        setPreview((prev) => ({...prev, render: false}));
      }, 300);
    } else {
      setPreview((prev) => ({...prev, render: true}));
      setTimeout(() => {
        setPreview((prev) => ({...prev, show: true}));
      }, 200);
    }
  };

  useEffect(() => {
    if (fistLoad) {
      publicProfile && setPreview((prev) => ({...prev, render: true, show: true}));
    } else {
      handlePreview(publicProfile);
    }
  }, [publicProfile]);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  const [currentLang, setCurrentLang] = useState('');
  const filterLang = () => {
    langList.filter((item) => {
      if (item.value === lang) setCurrentLang(item.label);
    });
  };

  const logOut = () => {
    localStorage.removeItem('hash');
    navigate(Path.ChooseLanguage);
    dispatch(resetState(1));
  };

  useEffect(() => {
    filterLang();
  }, [lang]);

  return (
    <PageWrap mainClass='py32 px16' title={t('PROFILE.your')} goBack='/'>
      <div className='btnGroup'>
        <Link className={lang === 'en' ? 'navBtn' : 'navBtn lower'} to={Path.ChangeProfilePhoto}>
          <span className='label'>{t('PROFILE.change')}</span>
          <ArrowIcon />
        </Link>
        <Link className={lang === 'en' ? 'navBtn' : 'navBtn lower'} to={Path.EditPersonalInformation}>
          <span className='label'>{t('PROFILE.edit')}</span>
          <ArrowIcon />
        </Link>
        <Link className={lang === 'en' ? 'navBtn' : 'navBtn lower'} to={Path.EditPhoneNumber}>
          <span className='label'>{t('PROFILE.change2')}</span>
          <ArrowIcon />
        </Link>

        {(profile_type === 'default' ? userData?.profile?.is_verified : creatorInfo.is_verified) && (
          <span className='caption'>
            {t('PROFILE.you')} <b>{personalInfo.phone_number}</b>
          </span>
        )}
      </div>

      {(profile_type === 'default' || profile_type === 'unset') && (
        <ToggleSwitch
          className='mt32'
          label={t('PROFILE.profile')}
          checked={userData?.profile?.is_public}
          onChange={() =>
            userData?.profile?.is_anonymous && !userData?.profile?.is_public
              ? toast.error(toastText, {
                  toastId: 'error-data-first'
                })
              : dispatch(patchPersonalData({body: {is_public: !userData?.profile?.is_public}})).then((res) => {
                  if (res.type && res.type.includes('fulfilled')) {
                    dispatch(fetchPersonalData());
                  }
                })
          }
        />
      )}
      {(profile_type === 'default' || profile_type === 'unset') && (
        <span className='caption'>
          {userData.first_name && userData.gender && userData.birth_date && userData.city
            ? t('PROFILE.make')
            : t('PROFILE.to')}
        </span>
      )}

      {preview.render && (
        <div className={`${styles.box}${preview.show ? ` ${styles.show}` : ''}`}>
          <Button className='mt16' outlined to={Path.ProfilePreview}>
            {t('PROFILE.profile2')}
          </Button>
        </div>
      )}

      <Link className={lang === 'en' ? 'navBtn my32' : 'navBtn lower my32'} to={Path.BotLanguage}>
        <span className='label'>{t('PROFILE.bot')}</span>
        <span className='value'>{currentLang}</span>
        <ArrowIcon />
      </Link>

      <Button className={styles.btn} to={Path.CreatorMode} outlined>
        {profile_type === 'default' ? t('PROFILE.switch') : t('PROFILE.switch2')}
      </Button>

      <button onClick={() => navigate(Path.DeleteAccount)} className={styles.btn__delete}>
        {t('PROFILE.delete')}
      </button>

      <button onClick={logOut} className={styles.btn__logout}>
        <span className='mr5'>
          <LogOut />
        </span>
        {t('PROFILE.logout')}
      </button>
    </PageWrap>
  );
}
