import {A11y, Navigation} from 'swiper';
import {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {getSearchedData, matchedEvents} from '../../features/AttendTheEvent/eventsSlice';
import {Swiper, SwiperSlide} from 'swiper/react';
import EventCard from '../EventCard';
import 'swiper/css';
import styles from './EventSlider.module.scss';
import {useSearchParams} from 'react-router-dom';
import SlideCounter from '../SlideCounter';
import 'swiper/css/navigation';

export default function EventSlider() {
  const eventsArr = useAppSelector(matchedEvents);
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const type = searchParams.get('type');

  useEffect(() => {
    uuid && type && dispatch(getSearchedData({id: uuid, type: type === 'matching' ? 'matching' : 'other'}));
  }, []);

  return (
    <div className={styles.root}>
      <Swiper className={styles.slider} modules={[A11y, Navigation]} navigation spaceBetween={8}>
        {eventsArr?.length > 0 &&
          eventsArr?.map((result: any, index) => (
            <SwiperSlide key={index} className={styles.slide}>
              {({isActive}) => (
                <>
                  <EventCard uuidPath={`uuid=${uuid}&type=${type}`} className={styles.card} eventInfo={result} />

                  <SlideCounter length={eventsArr.length} index={index} isActive={isActive} />
                </>
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}
