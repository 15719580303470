import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {API_BASE_URL, Path} from '../../app/const';
import {getVenues} from './venuesAPI';
import PageWrap from '../../layout/PageWrap';
import NoVenues from './NoVenues';
import placeholderImg from '../../assets/icons/placeholder.svg';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import styles from './Venues.module.scss';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import SkeletonSquare from '../../shared/SkeletonSquare';

export default function Venues() {
  const [loading, setLoading] = useState(true);
  const [venues, setVenues] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getVenues(setVenues, setLoading);
  }, []);
  const {t} = useTranslation();

  const goToCreateVenue = () => {
    navigate(Path.CreateVenue);
  };

  return (
    <PageWrap
      mainClass={styles.root}
      title={t('VENUES.venue')}
      headerButtonTitle={venues?.length ? ` ${t('VENUES.add')} +` : ''}
      headerButtonFunction={goToCreateVenue}
    >
      {loading && !venues?.length && [...Array(3)].map((element, index) => <SkeletonSquare key={index} />)}

      {!loading && venues?.length
        ? venues.map(({id, name, address, picture}) => (
            <Link key={id} className={styles.card} to={`${Path.VenueDetails}/${id}`}>
              <img
                className={styles.cardImg}
                src={picture ? API_BASE_URL + picture : placeholderImg}
                width='64px'
                height='64px'
                alt={name}
              />
              <div className={styles.cardCol}>
                <h2 className={`${styles.cardTitle} truncated`}>{name ? name : '-'}</h2>
                <div className={styles.cardAddress}>
                  <LocationIcon /> <span className='truncated details'>{address ? address : '-'}</span>
                </div>
                <ArrowIcon className={styles.cardArrow} />
              </div>
            </Link>
          ))
        : null}
      {!loading && !venues?.length ? <NoVenues /> : null}
    </PageWrap>
  );
}
