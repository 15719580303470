import React from 'react';
import styles from '../ProfileCard/ProfileCard.module.scss';
import Skeleton from 'react-skeleton-loader';

const ProfileCardSkeleton = () => {
  return (
    <div className={styles.root}>
      {/* <img className={styles.photo} src={pictureLink} width='343px' height='343px' alt='Profile' /> */}
      <Skeleton className={styles.photo} width='100%' height='343px' widthRandomness='0' />
      <div className='profileCardInfo'>
        <div className={styles.name}>
          <Skeleton className={styles.photo} width='180px' height='24px' widthRandomness='0' />
        </div>
        <span className='details truncated'>
          <Skeleton className={styles.photo} width='230px' height='24px' widthRandomness='0' />
        </span>
        <div className={styles.tagList}>
          <Skeleton className={styles.photo} width='60px' height='18px' widthRandomness='0' />
          <Skeleton className={styles.photo} width='60px' height='18px' widthRandomness='0' />
          <Skeleton className={styles.photo} width='60px' height='18px' widthRandomness='0' />
          <Skeleton className={styles.photo} width='60px' height='18px' widthRandomness='0' />
        </div>
        <p className={styles.bio}>
          <Skeleton className={styles.photo} width='230px' height='24px' widthRandomness='0' />
        </p>
      </div>
    </div>
  );
};

export default ProfileCardSkeleton;
