import React from 'react';
import Skeleton from 'react-skeleton-loader';
import styles from './SkeletonChatContent.module.scss';

function SkeletonChatContent() {
  return (
    <>
      <div className={styles.skeleton__template}>
        <div className={styles.skeleton__messages_my}>
          <Skeleton height='56px' width='134px' color='#fdeff1' widthRandomness='0' />
          <Skeleton height='56px' width='90px' color='#fdeff1' widthRandomness='0' />
          <Skeleton height='56px' width='150px' color='#fdeff1' widthRandomness='0' />
        </div>
        <div className={styles.skeleton__messages_his}>
          <Skeleton height='56px' width='180px' widthRandomness='0' />
          <Skeleton height='56px' width='80px' widthRandomness='0' />
        </div>
        <div className={styles.skeleton__messages_my}>
          <Skeleton height='56px' width='60px' color='#fdeff1' widthRandomness='0' />
        </div>
        <div className={styles.skeleton__messages_his}>
          <Skeleton height='56px' width='120px' widthRandomness='0' />
          <Skeleton height='56px' width='60px' widthRandomness='0' />
          <Skeleton height='56px' width='74px' widthRandomness='0' />
        </div>
      </div>
    </>
  );
}

export default SkeletonChatContent;
