import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Path} from '../../../app/const';
import {getVenue, updateVenue} from '../venuesAPI';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow.svg';
import styles from './VenueEdit.module.scss';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../app/hooks';
import {selectLanguage} from '../../../app/profileSlice';

export default function VenueEdit() {
  const {venueId} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [venue, setVenue] = useState({name: '', description: ''});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const handleInput = (setter: any) => (event: any) => setter(event.target.value);
  const {t} = useTranslation();
  const lang = useAppSelector(selectLanguage);

  useEffect(() => {
    venueId && getVenue(venueId, setVenue);
  }, []);

  useEffect(() => {
    venue?.name && setName(venue.name);
    venue?.description && setDescription(venue.description);
  }, [venue]);

  const sendVenueData = () => venueId && updateVenue(venueId, {name, description}, setLoading, setVenue);

  return (
    <PageWrap mainClass={styles.root} title={t('VENUEEDIT.edit')} goBack={Path.VenuesCreator}>
      <label className='inputWrap mb16'>
        <span className='inputLabel'>{t('VENUEEDIT.name')}</span>
        <input type='text' placeholder={t('VENUEEDIT.venue')} value={name} onChange={handleInput(setName)} />
      </label>
      <label className='inputWrap mb32'>
        <span className='inputLabel'>{t('VENUEEDIT.description')}</span>
        <textarea
          rows={4}
          placeholder={t('VENUEEDIT.venue1')}
          value={description}
          onChange={handleInput(setDescription)}
        />
      </label>
      <Button
        className={styles.btn}
        disabled={loading || !name || !description || (name === venue?.name && description === venue?.description)}
        onClick={sendVenueData}
      >
        {loading ? t('VENUEEDIT.saving') : t('VENUEEDIT.save')}
      </Button>

      <div className={styles.separator} />

      <span className={styles.label}>{t('VENUEEDIT.more')}</span>
      <div className='btnGroup'>
        <button className='navBtn' type='button' onClick={() => navigate(`${Path.VenueChangeNumber}/${venueId}`)}>
          <span className='label'>{t('VENUEEDIT.change')}</span>
          <ArrowIcon />
        </button>
        <button
          className={lang === 'en' ? 'navBtn' : 'navBtn lower'}
          type='button'
          onClick={() => navigate(`${Path.VenueEditLocation}/${venueId}`)}
        >
          <span className='label'>{t('VENUEEDIT.change2')}</span>
          <ArrowIcon fill='red' />
        </button>

        <button
          className={lang === 'en' ? 'navBtn' : 'navBtn lower'}
          type='button'
          onClick={() => navigate(`${Path.VenuePhotos}/${venueId}`)}
        >
          <span className='label'>{t('VENUEEDIT.venue3')}</span>
          <ArrowIcon />
        </button>
      </div>
    </PageWrap>
  );
}
