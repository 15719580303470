import PageWrap from '../../../layout/PageWrap';
import ProfileCard from '../../../shared/ProfileCard';
import {profilePreview} from '../../../app/const';
// import styles from './ProfilePreview.module.scss';
import {useTranslation} from 'react-i18next';

export default function ProfilePreview() {
  const {t} = useTranslation();
  return (
    <PageWrap mainClass='py24 px16' title={t("PROFILEPREVIEW.profile")}>
      <p className='mb24'>{t("PROFILEPREVIEW.heres")}</p>
      {/* <ProfileCard {...profilePreview} /> */}
    </PageWrap>
  );
}
