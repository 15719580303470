import {useState} from 'react';
import {Path} from '../../../app/const';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './EventPeople.module.scss';
import {useLocation} from 'react-router-dom';
import {useAppDispatch} from '../../../app/hooks';
import {getAttendedPeople} from '../../../features/MeetSomeone/searchSlice';
import RangeSlider from '../../../shared/RangeSlider';
import SelectButtons from '../../../shared/SelectButtons';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';

interface EventPeopleProps {
  interested?: boolean;
}

export default function EventPeople({interested}: EventPeopleProps) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  const {search} = useLocation();
  const navigate = useNavigate();

  let uuid = '';

  let id = searchParams.get('event_id');
  let type = searchParams.get('type');

  const [minAge, setMinAge] = useState(18);
  const [maxAge, setMaxAge] = useState(60);

  const [gender, setGender] = useState('none');
  const {t} = useTranslation();

  const genderButtons = [
    {id: 1, value: 'male', label: t('EVENTPEOPLE.man')},
    {id: 2, value: 'female', label: t('EVENTPEOPLE.woman')},
    {id: 3, value: 'none', label: t('EVENTPEOPLE.doesnt')}
  ];

  search.split('?').map((item) => {
    if (item.includes('uuid')) {
      uuid = item.split('=')[1];
    }
  });

  return (
    <PageWrap
      mainClass={styles.root}
      goBack={uuid ? `${Path.MatchingResultsEvent}?${uuid}&${type}` : -1}
      title={`${type === 'interested' ? t('EVENTPEOPLE.interested') : t('EVENTPEOPLE.attneding')} ${t(
        'EVENTPEOPLE.people'
      )}`}
    >
      <p className='my8'>{t('EVENTPEOPLE.who')}</p>

      <SelectButtons
        options={genderButtons}
        defaultValue={'none'}
        onChange={setGender}
        title={t('EVENTPEOPLE.gender')}
      />

      <RangeSlider
        min={16}
        max={60}
        setMinAge={setMinAge}
        setMaxAge={setMaxAge}
        onChange={() => console.log('changed')}
      />

      {/* <Button className={styles.btn} to={interested ? Path.InterestedPeopleResults : Path.AttendingPeopleResults}>
        Continue
      </Button> */}

      <Button
        className={styles.btn}
        onClick={() =>
          id &&
          type &&
          dispatch(
            getAttendedPeople({
              id: id,
              type: type,
              body:
                gender === 'none'
                  ? {age_from: minAge, age_to: maxAge}
                  : {
                      gender: gender,
                      age_from: minAge,
                      age_to: maxAge
                    }
            })
          ).then((res: any) => {
            if (res.type && res.type.includes('fulfilled')) {
              navigate(interested ? Path.InterestedPeopleResults : Path.AttendingPeopleResults);
            } else {
              // window.Telegram.WebApp.close();
            }
          })
        }
      >
        {t('EVENTPEOPLE.continue')}
      </Button>
    </PageWrap>
  );
}
