import {Navigate, Route, Routes} from 'react-router-dom';
import axios from 'axios';
import {useState} from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import {override} from './const';

import ErrorBoundary from '../shared/ErrorBoundary';
import {Path} from './const';
import MainMenu from '../features/MainMenu';

import SignUp from '../features/SignUp';
import SignIn from '../features/SignIn';

import MeetSomeone from '../features/MeetSomeone';
import MeetSomeoneLocation from '../features/MeetSomeoneLocation';
import SearchResults from '../features/MeetSomeone/SearchResults';
import VenueLocation from '../features/Venues/VenueLocation';
import EventLocation from '../features/EventCreator/EventLocation';
import BrowseInterests from '../features/BrowseInterests';
import InterestSearchResults from '../features/BrowseInterests/InterestSearchResults';
import AttendTheEvent from '../features/AttendTheEvent';
import AttendTheEventChangeParams from '../features/AttendTheEvent/AttendTheEventChangeParams';
import EventCreator from '../features/EventCreator';
import EventDetails from '../features/AttendTheEvent/EventDetails';
import EventDetailsCreator from '../features/EventCreator/EventDetails';
import EventPeople from '../features/AttendTheEvent/EventPeople';
import EventEdit from '../features/EventCreator/EventEdit';

import EventCreatorVenueProfile from '../features/EventCreator/VenueProfile';
import EventPeopleResults from '../features/AttendTheEvent/EventPeopleResults';
import MatchingResults from '../features/AttendTheEvent/MatchingResults';
import OtherResults from '../features/AttendTheEvent/OtherResults';
import VenueProfile from '../features/AttendTheEvent/VenueProfile';
import EmptyResults from '../features/BrowseInterests/EmptyResults';
import Chats from '../features/Chats';
import Chat from '../features/Chats/Chat';
import UserProfile from '../features/Chats/UserProfile';
import CreatorMode from '../shared/CreatorMode';
import Venues from '../features/Venues';
import VenueDetails from '../features/Venues/VenueDetails';
import VenueEdit from '../features/Venues/VenueEdit';
import VenueRemove from '../features/Venues/VenueRemove';

import VenueChangeNumber from '../features/Venues/VenueChangeNumber';
import EventsChangeNumber from '../features/AttendTheEvent/EventChangeNumber/EventChangeNumber';
import VenuePhotos from '../features/Venues/VenuePhotos';
import '../assets/styles/main.scss';
import {toast} from 'react-toastify';
import {API_BASE_URL, exceptLinks} from './const';

import ChooseLanguage from '../features/ChooseLanguage';
import BrowseInterestsLocation from '../features/BrowseInterests/BrowseInterestsLocation';
import AttendTheEventLocation from '../features/AttendTheEvent/AttendTheEventLocation';
import AttendTheEventEmptyResults from '../features/AttendTheEvent/EmptyResults';
import CreateVenue from '../features/Venues/CreateVenue';
import VenuePhoneNumber from '../features/Venues/VenuePhoneNumber/VenuePhoneNumber';
import VenuePhoto from '../features/Venues/VenuePhoto';
import CreateEvent from '../features/EventCreator/CreateEvent';
import AttendeesAndInterested from '../features/AttendTheEvent/AttendeesAndInterested';
import AttendedInterestedPeople from '../features/EventCreator/AttendedInterestedPeople';
import CancelDeleteEvent from '../features/EventCreator/CancelDeleteEvent';
import {useNavigate} from 'react-router-dom';

import Profile from '../features/Profile';
import ChangePhoto from '../features/Profile/ChangePhoto';
import EditInfo from '../features/Profile/EditInfo';
import ProfilePreview from '../features/Profile/ProfilePreview';
import BotLanguage from '../features/Profile/BotLanguage';
import SubscriptionDetails from '../features/SubscriptionDetails';
import DeleteProfile from '../features/Profile/DeleteProfile/DeleteProfile';
import EditNumber from '../features/EditNumber';
import CreateProfile from '../features/Profile/CreateProfile';

export default function App() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  axios.interceptors.request.use((config: any) => {
    const hash = localStorage.getItem('hash');
    config.baseURL = API_BASE_URL;

    //LOADER SHOULD BE SHOWN ONLY ON PAGES WHERE IT'S NOT EXCEPTED
    exceptLinks.includes(config.url.split('=')[0]) && setLoader(true);

    if (hash) {
      config.headers.Authorization = `Hash ${hash}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      const link = response && response.config && response.config.url && response.config.url.split('=')[0];

      exceptLinks.includes(`${link}`) && setLoader(false);
      return response;
    },
    (error) => {
      setLoader(false);
      if (error.response.status === 500) {
        toast.error('Server error. Please try later!', {
          toastId: 'error 500'
        });
      } else if (error.response.status === 404) {
        !error.config.url.includes('/searches/my/last-search/') &&
          toast.error(error.response?.data?.detail, {
            toastId: 'error'
          });
      } else if (error.response.status === 401) {
        navigate(Path.SignIn);
        localStorage.clear();
      } else if (error.response.status === 400) {
        Object.entries(error.response.data).map((arr: any) => {
          toast.error(Array.isArray(arr[1]) ? arr[1][0] : arr[1], {
            toastId: 'error 400'
          });
        });
      } else {
        toast.error(error.response?.data?.detail, {
          toastId: 'error'
        });
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <ErrorBoundary>
        <Routes>
          <Route path={Path.Root} element={<MainMenu />} />
          <Route path={Path.SignUp} element={<SignUp />} />
          <Route path={Path.SignIn} element={<SignIn />} />
          <Route path={Path.ChooseLanguage} element={<ChooseLanguage />} />

          {/* PROFILE LINKS */}
          <Route path={Path.Profile} element={<Profile />} />
          <Route path={Path.BotLanguage} element={<BotLanguage />} />
          <Route path={Path.EditPhoneNumber} element={<EditNumber />} />
          <Route path={Path.DeleteAccount} element={<DeleteProfile />} />
          <Route path={Path.ProfilePreview} element={<ProfilePreview />} />
          <Route path={Path.ChangeProfilePhoto} element={<ChangePhoto />} />
          <Route path={Path.EditPersonalInformation} element={<EditInfo />} />
          <Route path={Path.CreatePersonalInformation} element={<CreateProfile />} />
          <Route path={Path.SubscriptionDetails} element={<SubscriptionDetails />} />

          {/* MEET SOMEONE*/}
          <Route path={Path.MeetSomeone} element={<MeetSomeone />} />
          <Route path={Path.MeetSomeoneLocation} element={<MeetSomeoneLocation />} />
          <Route path={Path.SearchResults} element={<SearchResults />} />
          <Route path={Path.SearchResultsEmpty} element={<AttendTheEventEmptyResults type='meetsomeone' />} />

          {/* ATTEND THE EVENT */}
          <Route path={Path.OtherResults} element={<OtherResults />} />
          <Route path={Path.VenueProfile} element={<VenueProfile />} />
          <Route path={Path.AttendingPeople} element={<EventPeople />} />
          <Route path={Path.AttendTheEvent} element={<AttendTheEvent />} />
          <Route path={Path.MatchingResultsEvent} element={<MatchingResults />} />
          <Route path={`${Path.EventDetails}/:eventId`} element={<EventDetails />} />
          <Route path={Path.InterestedPeople} element={<EventPeople interested />} />
          <Route path={Path.AttendingPeopleResults} element={<EventPeopleResults />} />
          <Route path={Path.AttendingPeopleLocation} element={<AttendTheEventLocation />} />
          <Route path={Path.InterestedPeopleResults} element={<EventPeopleResults interested />} />
          <Route path={Path.AttendTheEventChangeParams} element={<AttendTheEventChangeParams />} />
          <Route path={`${Path.EventAttendedPeople}/:eventId`} element={<AttendedInterestedPeople />} />
          <Route path={`${Path.AttendeesInterestedEvents}/:eventId`} element={<AttendeesAndInterested />} />
          <Route path={Path.AttendTheEventEmptyResults} element={<AttendTheEventEmptyResults type='attend' />} />
          <Route path={`${Path.InterestedPeopleResultsCreator}/:eventID`} element={<EventPeopleResults interested />} />

          {/* VENUE CREATOR */}
          <Route path={Path.VenueSetLocation} element={<VenueLocation type='create' />} />
          <Route path={Path.VenueEnterNumber} element={<VenuePhoneNumber />} />
          <Route path={Path.VenueAddPhotos} element={<VenuePhoto />} />
          <Route path={Path.CreateVenue} element={<CreateVenue />} />
          <Route path={`${Path.VenueChangeNumber}/:venueId`} element={<VenueChangeNumber />} />
          <Route path={`${Path.VenuePhotos}/:venueId`} element={<VenuePhotos />} />
          <Route path={`${Path.VenueRemove}/:venueId`} element={<VenueRemove />} />
          <Route path={Path.VenuesCreator} element={<Venues />} />
          <Route path={`${Path.VenueDetails}/:venueId`} element={<VenueDetails />} />
          <Route path={`${Path.VenueEdit}/:venueId`} element={<VenueEdit />} />
          <Route path={`${Path.VenueEditLocation}/:venueId`} element={<VenueLocation type='edit' />} />

          {/* EVENT CREATOR */}
          <Route path={Path.EventCreator} element={<EventCreator />} />
          <Route path={Path.CreateEvent} element={<CreateEvent />} />
          <Route path={`${Path.EventCreatorDetails}/:eventId`} element={<EventDetailsCreator />} />
          <Route path={`${Path.EventCreatorEdit}/:eventId`} element={<EventEdit />} />
          <Route path={Path.EventCreatorVenueProfile} element={<EventCreatorVenueProfile />} />
          <Route path={`${Path.EventClose}/:venueId`} element={<CancelDeleteEvent type='cancel' />} />
          <Route path={`${Path.DeleteClose}/:venueId`} element={<CancelDeleteEvent type='delete' />} />
          <Route path={`${Path.EventEditLocation}/:eventId`} element={<EventLocation type='edit' />} />
          <Route path={`${Path.EventCreateLocation}/:eventId`} element={<EventLocation type='create' />} />
          <Route path={`${Path.EventsPhotos}/:eventId`} element={<VenuePhotos />} />
          <Route path={`${Path.EventsChangeNumber}/:venueId`} element={<EventsChangeNumber />} />
          <Route path={`${Path.EventsRemove}/:eventId`} element={<CancelDeleteEvent type='delete' />} />

          {/* BROWSE INTERESTS */}
          <Route path={Path.BrowseInterestsLocation} element={<BrowseInterestsLocation />} />
          <Route path={Path.BrowseInterestsEmptyResults} element={<EmptyResults />} />
          <Route path={Path.BrowseInterests} element={<BrowseInterests />} />
          <Route path={Path.MatchingResultsInterest} element={<InterestSearchResults />} />

          {/* CHAT */}
          <Route path={Path.Chats} element={<Chats />} />
          <Route path={`${Path.Chats}/:chatId`} element={<Chat />} />
          <Route path={`${Path.UserProfile}/:userId`} element={<UserProfile />} />

          <Route path={`${Path.CreatorMode}`} element={<CreatorMode />} />
          <Route path='*' element={<Navigate to={Path.Root} replace />} />
        </Routes>

        {loader && (
          <div className='loader-container'>
            <FadeLoader className='loader' color='#ec5b75' cssOverride={override} />
          </div>
        )}
      </ErrorBoundary>
    </>
  );
}
