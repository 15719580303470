import React, { Component, ErrorInfo, ReactNode } from 'react';
import styles from './ErrorBoundary.module.scss';

interface ErrorBoundaryProps {
  children?: ReactNode;
  className?: string;
  details?: boolean;
}

interface State {
  hasError: boolean;
  error: any;
  errorInfo: any;
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  public static getDerivedStateFromError(_: Error): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });
  }

  public render() {
    const { className, children, details } = this.props;
    const { hasError, error, errorInfo } = this.state;

    if (hasError) {
      return (
        <div className={`${styles.wrap}${className ? ` ${className}` : ''}`}>
          <h2 className={styles.title}>Something went wrong!</h2>
          {details && (
            <details className={styles.details}>
              <summary className={styles.summary}>Details</summary>
              <div className={styles.desc}>
                <div className={styles.text}>
                  {error?.toString()}
                  <br />
                  {errorInfo?.componentStack}
                </div>
              </div>
            </details>
          )}
        </div>
      );
    }
    // Normally, just render children
    return children;
  }
}
