import PageWrap from '../../../layout/PageWrap';
import styles from './EmptyResults.module.scss';
import {Path} from '../../../app/const';
import {useTranslation} from 'react-i18next';
import image from './image/empty_list.png';
import image2 from './image/list.png';
import Button from '../../../shared/Button';
import {selectInterestsList} from '../interestsSlice';
import {useAppSelector} from '../../../app/hooks';

const EmptyResults = () => {
  const {t} = useTranslation();
  const interestData = useAppSelector(selectInterestsList);
  const isEmpty =
    interestData?.other_interests.length === 0 && interestData.wanted_interests.length === 0 ? true : false;

  return (
    <PageWrap title={t('BROWSEINTERESTS.browse')} goBack={Path.BrowseInterests} mainClass={styles.root}>
      {isEmpty && <img className='mb16' src={image} alt='empty' />}
      {!isEmpty && <img className='mb16' src={image2} alt='empty' />}

      {isEmpty ? (
        <>
          <b> {t('BROWSEINTERESTS.sorry')}</b>
          <p> {t('BROWSEINTERESTS.anything')}</p>
          <p className='mt16'> {t('BROWSEINTERESTS.change')}</p>
          <Button className={styles.button} to={Path.BrowseInterests} outlined>
            {t('BROWSEINTERESTS.change2')}
          </Button>
        </>
      ) : (
        <>
          <b> {t('BROWSEINTERESTS.amazing')}</b>
          <p>
            {t('BROWSEINTERESTS.found')} <b>{interestData?.wanted_interests.length}</b>
            {t('BROWSEINTERESTS.wanted2')} <b>{interestData?.other_interests.length}</b>
            {t('BROWSEINTERESTS.other')}
          </p>
          <p className='mt16'> {t('BROWSEINTERESTS.lets')}</p>
          <Button className={styles.button} to={Path.MatchingResultsInterest}>
            {t('BROWSEINTERESTS.see')}
          </Button>
          <Button className={'mt16'} to={Path.BrowseInterests} outlined>
            {t('BROWSEINTERESTS.change2')}
          </Button>
        </>
      )}
    </PageWrap>
  );
};

export default EmptyResults;
