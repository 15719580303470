import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './EventChangeNumber.module.scss';
import {getSingleEvent, singleEvent, verifyEvent} from '../../../features/AttendTheEvent/eventsSlice';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {selectPersonalInfo} from '../../../app/profileSlice';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getSmsCode} from '../../../app/profileSlice';
import {Path} from '../../../app/const';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import parsePhoneNumber from 'libphonenumber-js';

export default function EventChangeNumber() {
  const {venueId} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState(1);
  const [venue, setVenue] = useState({contact_number: ''});
  const [phone, setPhone] = useState('');
  const [sms, setSms] = useState('');
  const handleInput = (setter: any) => (event: any) => setter(event.target.value);
  const {pathname} = useLocation();
  const eventId = pathname.split('/')[3];
  const currentEvent = useAppSelector(singleEvent);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const type = searchParams.get('type');
  const clearPhoneNumner = phone.replace(/[^0-9,+]+/g, '');
  const personalInfo = useAppSelector(selectPersonalInfo);

  useEffect(() => {
    !currentEvent && getSingleEvent(eventId);
    personalInfo.is_phone_number_verified && personalInfo.phone_number && setPhone(personalInfo.phone_number);
  }, []);

  useEffect(() => {
    venue?.contact_number && setPhone(venue.contact_number);
  }, [venue]);

  const resendCode = () => {
    dispatch(
      getSmsCode({
        phone_number: phone,
        purpose: 'event_creation'
      })
    );
  };

  const sendVenueNumber = async (): Promise<void> => {
    if (step === 1) {
      const res = await dispatch(
        getSmsCode({
          phone_number: phone,
          purpose: 'event_creation'
        })
      );
      if (res.type && res.type.includes('fulfilled') && res.payload.is_verified) {
        navigate(`${Path.EventsPhotos}/${venueId}?type=creator`);
      } else {
        setStep(2);
      }
    }
    if (step === 2 && venueId) {
      const res = await dispatch(
        verifyEvent({
          id: +venueId,
          body: {
            phone_number: phone,
            code: +sms
          }
        })
      );
      if (res.type && res.type.includes('fulfilled')) {
        navigate(
          type === 'create' ? `${Path.EventsPhotos}/${venueId}?type=creator` : `${Path.EventCreatorEdit}/${venueId}`
        );
      }
    }
  };

  const onPhoneChange = (phone: any) => {
    setStep(1);
    setPhone(phone);
  };
  const phoneNumberData = phone && parsePhoneNumber(`+${phone}`, 'US');
  const isValid = phoneNumberData && phoneNumberData.isValid();

  return (
    <PageWrap
      mainClass={styles.root}
      title={type === 'create' ? t('EVENTCHANGENUMBER.contact') : t('EVENTCHANGENUMBER.change')}
      goBack={-1}
    >
      <p className='mb24'>{t('EVENTCHANGENUMBER.enter')}</p>
      <label className='inputWrap mt24'>
        <span className='inputLabel'>{t('EVENTCHANGENUMBER.contact')} </span>

        <PhoneInput
          containerClass={styles.contact_form__phone}
          inputClass={styles.phone__input}
          buttonClass={styles.phone__btn}
          dropdownClass={styles.phone__dropdown}
          country={'ee'}
          value={clearPhoneNumner}
          onChange={onPhoneChange}
        />
      </label>
      {step === 2 && (
        <label className='inputWrap mt16'>
          <span className='inputLabel'>{t('EVENTCHANGENUMBER.sms')}</span>
          <input type='number' placeholder={t('EVENTCHANGENUMBER.type')} value={sms} onChange={handleInput(setSms)} />
        </label>
      )}

      <Button
        className='my32'
        disabled={
          step === 1
            ? personalInfo.phone_number === `${phone.includes('+') ? phone : `+${phone}`}`
              ? false
              : typeof isValid === 'undefined' || !isValid
            : +sms <= 100000
        }
        onClick={sendVenueNumber}
      >
        {t('EVENTCHANGENUMBER.continue')}
      </Button>

      {step === 2 && (
        <Button onClick={resendCode} className={styles.resendBtn} outlined disabled={loading}>
          {t('EVENTCHANGENUMBER.resend')}
        </Button>
      )}
    </PageWrap>
  );
}
