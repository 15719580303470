import PageWrap from '../../../layout/PageWrap';
import PeopleSlider from '../../../shared/PeopleSlider';
// import styles from './SearchResults.module.scss';

import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../app/hooks';
import {selectCandidates} from '../searchSlice';
import {useNavigate} from 'react-router-dom';
import {Path} from '../../../app/const';

export default function SearchResults() {
  const {t} = useTranslation();
  const {candidates} = useAppSelector(selectCandidates);
  const navigate = useNavigate();

  if (candidates.length === 0) {
    navigate(Path.MeetSomeone);
  }

  return (
    <PageWrap title={t('SEARCHRESULTS.search')} goBack='/meet-someone/'>
      <PeopleSlider />
    </PageWrap>
  );
}
