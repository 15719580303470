import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

import photoImg from '../assets/images/profile-preview.jpg';

export interface AppState {
  profile: {
    photo: string | null;
    name: string | null;
    sex: 'male' | 'female' | null;
    city: string | null;
    age: number | null;
  };
  subscription: 'paid' | 'qr' | 'free';
  language: string;
}

const initialState: AppState = {
  profile: {
    photo: null,
    name: 'Anonymous #4593',
    sex: null,
    city: null,
    age: null
  },
  subscription: 'free',
  language: 'en'
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSubscription: (state: any, action: PayloadAction<'paid' | 'qr' | 'free'>) => {
      state.subscription = action.payload;

      if (state.subscription !== 'free') {
        state.profile = {
          photo: photoImg,
          name: 'Alexander',
          sex: 'man',
          city: 'Tallinn',
          age: 29
        };
      } else {
        state.profile = {
          photo: null,
          name: 'Anonymous #4593',
          sex: null,
          city: null,
          age: null
        };
      }
    },
    setLanguage: (state: any, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setPhoto: (state: any, action: PayloadAction<string | null>) => {
      state.profile.photo = action.payload;
    }
  }
});

export const { setSubscription, setLanguage, setPhoto } = appSlice.actions;

export const selectProfile = (state: RootState) => state.app.profile;
export const selectSubscription = (state: RootState) => state.app.subscription;
export const selectLanguage = (state: RootState) => state.app.language;

export default appSlice.reducer;
