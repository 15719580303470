import React, {useEffect, useState, useRef} from 'react';
import {Link, useNavigate, useLocation, useSearchParams} from 'react-router-dom';
import {ReactComponent as UnreadIcon} from '../../../assets/icons/sent.svg';
import {ReactComponent as SendBtnIcon} from '../../../assets/icons/send-btn.svg';
import {ReactComponent as ReadIcon} from '../../../assets/icons/read.svg';
import {ReactComponent as ArrowDown} from '../../../assets/icons/arrow_down.svg';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow.svg';
import {ReactComponent as MessageIcon} from '../../../assets/icons/message-2.svg';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import useWebSocket from 'react-use-websocket';
import {getSingleRoom, selectRooms, loadNewMessages, addLastMessage} from '../../../features/Chats/chatSlice';

import {selectProfileType, setBottomBoxForIphone} from '../../../app/profileSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './Chat.module.scss';
import {Path} from '../../../app/const';
import {API_BASE_URL_WS} from '../../../app/const';
import {format} from 'date-fns';
import anonymous from '../../../assets/images/anonym.png';
import SkeletonChatContent from '../../../shared/SkeletonChatContent';
import {useTranslation} from 'react-i18next';
import {ReactComponent as CheckIcon} from '../../../assets/icons/check-2.svg';
import ScrollToTop from 'react-scroll-up';
import {ReactComponent as ArrowUp} from './img/up.svg';
import PageHeader from '../../../layout/PageHeader';

import useSound from 'use-sound';
const alarm = require('./audio/notification.mp3');

export default function Chat() {
  const [play] = useSound(alarm);
  const dispatch = useAppDispatch();
  const {
    singleRoom: {user1, user2, results, next, previous},
    loading,
    preloadChat
  } = useAppSelector(selectRooms);
  const {t} = useTranslation();

  const [recent_messages, setRecentMessages] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const isFromSearch = searchParams.get('isFromSearch');

  const navigate = useNavigate();
  const {pathname} = useLocation();
  const hash = localStorage.getItem('hash');
  const profile_type = useAppSelector(selectProfileType);
  const input = useRef<HTMLInputElement>(null);
  const [allMessageWasRead, setAllMessageWasRead] = useState(false);
  const [lastMSG, setLastMSG] = useState<any>();

  // const state = useAppSelector((state) => state);

  const id = pathname.split('/')[2];

  const socketUrl = `${API_BASE_URL_WS}/chat/private/`;

  const [messageBody, setMessage] = useState({
    type: 'private_message',
    data: {
      to_room: id,
      message: ''
    }
  });

  //CHECK WHO IS THE RESPONDENT

  let respondent = user1?.is_me ? user2 : user1;

  const scrollToBottom = () => {
    const scrollableDiv = document.getElementById('scrollableDiv');

    scrollableDiv?.scrollTo(0, scrollableDiv.scrollHeight);
  };

  //Function Just for Iphones
  const scrollToLastMessage = () => {
    setRecentMessages(0);
    scrollToBottom();
  };

  const {lastJsonMessage, sendJsonMessage} = useWebSocket(socketUrl, {
    onOpen: () => console.log('connected chat'),
    onMessage: (e) => {
      //console.log(JSON.parse(e.data));
    },
    shouldReconnect: (closeEvent) => true,
    share: true,
    queryParams: {
      hash: hash ? hash : ''
    }
  });

  useEffect(() => {
    profile_type === 'default' && dispatch(getSingleRoom(+id));
  }, [profile_type]);

  //scroll to bottom Chat function

  useEffect(() => {
    dispatch(addLastMessage(lastJsonMessage));

    if (lastJsonMessage !== null) {
      // last message type
      let type = Object.values(lastJsonMessage)[0];
      let list = Object.values(lastJsonMessage)[0];
      type === 'private_message' && play();
      //Don't count your messages and default chat_read
      type !== 'chat_read' && type !== 'echo' && setRecentMessages((previous) => previous + 1);

      //Якщо людина пише тобі повідомлення, значить можна припустити, що вана прочитала це повідомлення
      type === 'private_message' && setAllMessageWasRead(true);
      type === 'private_message' && setLastMSG(Object.values(lastJsonMessage)[1]);

      //last message data
      let lastMessage: any = Object.values(lastJsonMessage)[1];
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    results.length > 0 &&
      !results[0].is_mine &&
      sendJsonMessage({
        type: 'mark_read',
        data: {
          message_id: results[0].id
        }
      });
  }, [results]);

  useEffect(() => {
    scrollToLastMessage();
  }, []);

  return (
    <>
      <PageHeader />
      <div className='app'>
        {respondent && (
          <header className={styles.header}>
            <button
              className={`${styles.back} hover`}
              type='button'
              aria-label='Back button'
              onClick={() => navigate(-1)}
            >
              <ArrowIcon className={styles.arrow} />
            </button>
            <Link className={styles.user} to={`${Path.UserProfile}/${respondent.id}`}>
              <img
                src={respondent.picture || anonymous}
                width='32px'
                height='32px'
                alt={respondent.first_name || undefined}
              />
              <h1 className='truncated'>{respondent.first_name ? respondent.first_name : t('CHAT.anonymous')}</h1>
              {respondent?.is_verified && <CheckIcon />}
            </Link>
          </header>
        )}

        <div id='scrollableDiv' className={results?.length === 0 ? styles.main_empty : styles.main}>
          {
            <InfiniteScroll
              dataLength={results?.length}
              next={() => {
                dispatch(loadNewMessages(next));
              }}
              endMessage={
                previous && (
                  <p style={{textAlign: 'center'}}>
                    <b>There are no messages more...</b>
                  </p>
                )
              }
              style={{display: 'flex', flexDirection: 'column-reverse'}}
              className={styles.scroll__block}
              inverse={true}
              hasMore={!!next}
              loader={preloadChat !== 'succeeded' && <h4>{t('CHAT.loading')}</h4>}
              scrollableTarget='scrollableDiv'
              onScroll={(e) => {
                //Clear state recent messages when scrolled to bottom
                const target = e.target as Element;
                target.scrollTop <= 20 && setRecentMessages(0);
              }}
            >
              {results?.length === 0 && loading === 'succeeded' && (
                <div className={styles.noMessages}>
                  <MessageIcon />
                  <p>{t('CHAT.send')}</p>
                </div>
              )}

              {loading !== 'succeeded' && results?.length === 0 && <SkeletonChatContent />}

              {results?.length !== 0 && (
                <>
                  {results.map((message: any, index: any) => (
                    <React.Fragment key={index}>
                      {index !== 0
                        ? format(new Date(message.create_date), 'dd') !==
                            format(new Date(results[index - 1]?.create_date), 'dd') && (
                            <time className={styles.time}>
                              {format(new Date(results[index - 1]?.create_date), 'MMMM dd')}
                            </time>
                          )
                        : ''}
                      <p className={`${styles.message} ${!message.is_mine && styles.incoming}`}>
                        {message.text}
                        <span className={styles.messageTime}>
                          <time> {format(new Date(message.create_date), 'HH:mm')}</time>
                          {/* Тут зроблено так, що якщо юзер написав повідомлення, це означає що всі решту прочитав. Бо в сокетах кліженту не видно, чи прочитав юзер повідомлення чи ні */}
                          {message.is_mine &&
                            message.unread &&
                            (message.id < lastMSG?.id ? <ReadIcon /> : <UnreadIcon />)}
                          {}
                          {message.is_mine && !message.unread && <ReadIcon />}

                          {/* {message.is_mine && (message.unread && !allMessageWasRead ? <UnreadIcon /> : <ReadIcon />)} */}
                        </span>
                      </p>
                    </React.Fragment>
                  ))}
                </>
              )}

              {results?.length !== 0 && (
                <time className={styles.time}>{format(new Date(results[0]?.create_date), 'MMMM dd')}</time>
              )}
            </InfiniteScroll>
          }
          {recent_messages > 0 && navigator.platform === 'iPhone' && (
            <button
              type='button'
              onClick={(e) => {
                e.preventDefault();
                scrollToLastMessage();
              }}
              className={styles.down__btn}
            >
              <span className={styles.down__quantity}>{recent_messages < 10 ? recent_messages : '9+'}</span>
              <ArrowDown />
            </button>
          )}
        </div>

        <form className={styles.footer}>
          <input
            onChange={(e) => {
              setMessage({...messageBody, data: {...messageBody.data, message: e.target.value}});
              dispatch(setBottomBoxForIphone(true));
            }}
            ref={input}
            onFocus={() => {
              dispatch(setBottomBoxForIphone(true));
            }}
            onBlur={() => {
              dispatch(setBottomBoxForIphone(false));
            }}
            autoFocus
            type='text'
            placeholder={'Type a message...'}
          />
          <button
            aria-label='Send message'
            disabled={messageBody.data.message === ''}
            type='submit'
            onClick={(e) => {
              sendJsonMessage(messageBody);
              setMessage({
                type: 'private_message',
                data: {
                  to_room: id,
                  message: ''
                }
              });
              //Reset message
              Object.values(input)[0].value = '';
            }}
          >
            <SendBtnIcon />
          </button>
        </form>
        <ScrollToTop style={{top: 25, left: '47%', right: '53%', transform: 'translate(-50%, 0)'}} showUnder={60}>
          <span>
            <ArrowUp />
          </span>
        </ScrollToTop>
      </div>
    </>
  );
}
