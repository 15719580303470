import {useState} from 'react';
import {searchInterests} from '../../../app/const';
import PageWrap from '../../../layout/PageWrap';
import TagGroup from '../../../shared/TagGroup';
import EventSlider from '../../../shared/EventSlider';
import 'swiper/css';
import styles from './OtherResults.module.scss';
import {useTranslation} from 'react-i18next';

export default function OtherResults() {
  const [inner, setInner] = useState('');
  const backToResults = () => setInner('');
  const {t} = useTranslation();

  return (
    <PageWrap mainClass={styles.root} title={!inner ? t('OTHERRESULTS.other') : inner} onClick={inner && backToResults}>
      {!inner ? (
        <div className='p16'>
          {searchInterests?.others && (
            <TagGroup data={searchInterests.others} title={t('OTHERRESULTS.otherinterest')} onTagClick={setInner} />
          )}
        </div>
      ) : (
        <EventSlider />
      )}
    </PageWrap>
  );
}
