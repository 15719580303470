import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectProfileUser, changeLocation, buySubscription, selectLoading} from '../../app/profileSlice';
import PageWrap from '../../layout/PageWrap';
import Button from '../../shared/Button';
import styles from './SubscriptionDetails.module.scss';
import {useTranslation} from 'react-i18next';
import SelectPosition from '../SelectPosition';
import {getLastSearchCoordinates} from '../MeetSomeone/searchSlice';

export default function SubscriptionDetails() {
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState({
    latitude: 0,
    longitude: 0
  });

  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {subscription} = useAppSelector(selectProfileUser);

  const loading = useAppSelector(selectLoading);

  useEffect(() => {
    subscription?.latitude && setCoordinates({latitude: +subscription.latitude, longitude: subscription.longitude});

    // if you do not have any subscription, make request to get last search coordinates and set
    if (!subscription) {
      dispatch(getLastSearchCoordinates()).then((res) => {
        if (res.type && res.type.includes('fulfilled')) {
          setCoordinates(res.payload);
        }
      });
    }
  }, [subscription]);

  let basicPosition = {lat: 48.7620186, lng: 15.2012252};
  basicPosition = {
    lat: subscription ? subscription.latitude : coordinates.latitude,
    lng: subscription ? subscription.longitude : coordinates.longitude
  };

  return (
    <PageWrap mainClass={styles.root} title={t('SUBSCRIPTIONDETAILS.subscription')}>
      {subscription?.type === 'buy' && (
        <p>
          {t('SUBSCRIPTIONDETAILS.purchased')} <b>{subscription?.address}.</b> {t('SUBSCRIPTIONDETAILS.visible')}
        </p>
      )}
      {/* {subscription?.type === 'qr' && (
        <p>
          You have scanned QR-code at <b>{profile?.city}.</b> You will be visible for others only in this location.
        </p>
      )} */}

      {/* <p>{t('SUBSCRIPTIONDETAILS.purchased')}</p> */}

      {/* {subscription?.latitude && subscription?.longitude ? (
        <a href={`https://www.google.com.ua/maps/@${subscription?.latitude},${subscription?.longitude},18z`}>
          <img
            className={styles.map}
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${subscription?.latitude},${subscription?.longitude}&zoom=18&size=686x518&key=${GOOGLE_MAP_KEY}`}
            alt='Map'
          />
        </a>
      ) : null} */}

      <p className='mt16 mb16'>{t('SUBSCRIPTIONDETAILS.select')}</p>

      <SelectPosition basicPosition={basicPosition} setPosition={setCoordinates} position={coordinates} />

      <Button
        className={styles.btn}
        disabled={!coordinates || loading === 'loading'}
        onClick={() =>
          dispatch(subscription?.type === 'buy' ? changeLocation(coordinates) : buySubscription(coordinates)).then(
            (res) => {
              if (res.type && res.type.includes('fulfilled')) {
                navigate('/');
              }
            }
          )
        }
      >
        {subscription?.type === 'buy' ? t('SUBSCRIPTIONDETAILS.change') : t('MAINMENU.buy')}

        {subscription?.type === 'qr' && ' for 1€'}
      </Button>
    </PageWrap>
  );
}
