import {useEffect, useState} from 'react';
import styles from './CreateEvent.module.scss';
import PageWrap from '../../../layout/PageWrap';
import {Path} from '../../../app/const';
import {useTranslation} from 'react-i18next';
import {utcToZonedTime} from 'date-fns-tz';
import {format, isBefore} from 'date-fns';
import Button from '../../../shared/Button';
import {addEvent} from '../../AttendTheEvent/eventsSlice';
import {useAppDispatch} from '../../../app/hooks';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

const CreateEvent = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({
    name: '',
    description: '',
    interest: '',
    end_date: '',
    start_date: '',
    max_attendees: 0
  });
  const [inputStartDate, setStartDate] = useState(null);
  const [inputEndDate, setEndDate] = useState(null);
  const [isWrong, setIsWrong] = useState(false);

  const currentEvent = {
    start_date: new Date(),
    end_date: new Date()
  };

  // set the date to UTC
  const start_date_utc = currentEvent && currentEvent.start_date && utcToZonedTime(currentEvent.start_date, 'UTC');
  const start_date = start_date_utc && format(new Date(start_date_utc), 'yyyy-MM-dd HH:mm');
  const start_date_changed = inputStartDate && format(new Date(inputStartDate), 'dd/MM/yyyy HH:mm');
  const start_date_started = start_date_utc && format(new Date(start_date_utc), 'dd/MM/yyyy');

  const end_date_utc = currentEvent && currentEvent.end_date && utcToZonedTime(currentEvent.end_date, 'UTC');
  const end_date = end_date_utc && format(new Date(end_date_utc), 'yyyy-MM-dd HH:mm');
  const end_date_changed = inputEndDate && format(new Date(inputEndDate), 'dd/MM/yyyy HH:mm');
  const end_date_started = end_date_utc && format(new Date(end_date_utc), 'dd/MM/yyyy');

  const toastText = t('EVENTCHANGENUMBER.toast');

  useEffect(() => {
    if (isBefore(new Date(formInfo.end_date), new Date(formInfo.start_date))) {
      toast.error(toastText, {
        toastId: 'wrongdate'
      });
      setIsWrong(true);
    } else {
      setIsWrong(false);
    }
  }, [formInfo]);

  const createEvent = () => {
    dispatch(addEvent(formInfo)).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        const id = res.payload.id;
        navigate(`${Path.EventCreateLocation}/${id}`);
        //navigate(`${Path.EventsChangeNumber}/${id}?type=create`);
      }
    });
  };

  return (
    <PageWrap mainClass={styles.root} title={t('EVENTCREATE.create')} goBack={Path.EventCreator}>
      <label className='inputWrap mb-16'>
        <span className='inputLabel'>{t('EVENTEDITCARD.name')}</span>

        <input
          onChange={(e: any) => {
            setFormInfo({...formInfo, name: e.target.value});
          }}
          type='text'
          placeholder={t('EVENTCHANGENUMBER.type')}
        />
      </label>
      <label className={`inputWrap mb-16 `}>
        <span className='inputLabel'>{t('EVENTEDITCARD.description')}</span>

        <input
          onChange={(e: any) => {
            setFormInfo({...formInfo, description: e.target.value});
          }}
          type='text'
          placeholder={t('EVENTCHANGENUMBER.type')}
        />
      </label>
      <label className='inputWrap mb-16'>
        <span className='inputLabel'>{t('EVENTEDITCARD.interests')}</span>

        <input
          onChange={(e: any) => {
            setFormInfo({...formInfo, interest: e.target.value.split(', ')});
          }}
          type='text'
          placeholder={t('EVENTCHANGENUMBER.type')}
        />
      </label>

      <label className={`inputWrap  label-wrapper birth mb-16 ${styles.date}`}>
        <span className='innerLabel'>{t('EVENTEDITCARD.start')}</span>
        <input
          type='datetime-local'
          onChange={(e: any) => {
            setFormInfo({...formInfo, start_date: e.target.value});
            setStartDate(e.target.value);
          }}
          defaultValue={start_date || ''}
        />

        <span className='datetime-local'>{start_date_changed ? start_date_changed : t('EVENTCREATE.format')}</span>
      </label>

      <label className={`inputWrap  label-wrapper birth mb-16 ${styles.date}`}>
        <span className='innerLabel'>{t('EVENTEDITCARD.end')}</span>

        <input
          type='datetime-local'
          onChange={(e: any) => {
            setFormInfo({...formInfo, end_date: e.target.value});
            setEndDate(e.target.value);
          }}
          defaultValue={end_date || ''}
        />

        <span className='datetime-local'>{end_date_changed ? end_date_changed : t('EVENTCREATE.format')}</span>
      </label>

      <label className='inputWrap mb-16'>
        <span className='inputLabel'>{t('EVENTEDITCARD.maximum')}</span>

        <input
          onChange={(e: any) => {
            setFormInfo({...formInfo, max_attendees: +e.target.value === 0 ? 1 : e.target.value});
          }}
          type='number'
          min={1}
          onKeyUp={(e) => {
            if (parseInt(e.currentTarget.value) < 1) {
              e.currentTarget.value = '1';
            }
          }}
          placeholder={'0'}
        />
      </label>
      <Button disabled={isWrong} onClick={createEvent} className={styles.btn}>
        {t('SIGNIN.continue')}
      </Button>
    </PageWrap>
  );
};

export default CreateEvent;
