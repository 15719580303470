import {useState, useEffect} from 'react';
import PageWrap from '../../layout/PageWrap';
import styles from './MeetSomeoneLocation.module.scss';
import SelectPosition from '../SelectPosition';
import {Path} from '../../app/const';
import {useTranslation} from 'react-i18next';
import Button from '../../shared/Button';
import {getLastSearchCoordinates, getCandidatesArr} from '../MeetSomeone/searchSlice';
import {selectProfileUser} from '../../app/profileSlice';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {selectCandidates} from '../BrowseInterests/interestsSlice';
import {useNavigate} from 'react-router-dom';

const MeetSomeoneLocation = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {search_id, last_coordinates} = useAppSelector(selectCandidates);
  const {subscription} = useAppSelector(selectProfileUser);
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0
  });

  useEffect(() => {
    dispatch(getLastSearchCoordinates());
  }, []);

  const getSearchResults = () => {
    dispatch(
      getCandidatesArr({
        coordiantes: {
          latitude: position.latitude,
          longitude: position.longitude
        },
        id: search_id
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(Path.SearchResultsEmpty);
      }
    });
  };

  let basicPosition = {lat: 48.7620186, lng: 15.2012252};
  basicPosition = {
    lat: last_coordinates?.latitude ? last_coordinates?.latitude : 0,
    lng: last_coordinates?.longitude ? last_coordinates?.longitude : 0
  };

  useEffect(
    () => setPosition({latitude: subscription.latitude, longitude: subscription.longitude}),
    [subscription.latitude]
  );

  return (
    <PageWrap title={t('MEETSOMEONE.meet')} goBack={Path.MeetSomeone} mainClass={styles.root}>
      <p className='mb16'>{t('MEETSOMEONE.specify')}</p>
      <SelectPosition basicPosition={basicPosition} setPosition={setPosition} position={position} />
      <Button onClick={getSearchResults} disabled={!search_id}>
        {t('MEETSOMEONE.search2')}
      </Button>
    </PageWrap>
  );
};

export default MeetSomeoneLocation;
