import {useState, useRef, useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './CreateProfile.module.scss';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {scrollToElement, API_BASE_URL} from '../../../app/const';
import {
  selectProfileUser,
  patchPersonalData,
  patchPersonalDataCreator,
  selectProfileType,
  patchAvatar
} from '../../../app/profileSlice';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import anonymous from '../../../assets/images/anonym.png';
import {Path} from '../../../app/const';
import {useNavigate} from 'react-router-dom';
import {fetchPersonalData} from '../../MeetSomeone/searchSlice';

export default function CreateProfile() {
  const dispatch = useAppDispatch();
  const [touched, setTouched] = useState(false);
  const {profile, interests} = useAppSelector(selectProfileUser);
  const hash = localStorage.getItem('hash');
  const profile_type = useAppSelector(selectProfileType);
  const navigate = useNavigate();

  const [profileInfo, setProfileInfo] = useState<any>({gender: 'male'});
  let [interestsInfo, setInterestsInfo] = useState<any>([]);
  const {t} = useTranslation();
  const toastText = t('EDITINFO.yourdata');
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

  const sendFile = (file: any) => {
    let fileItem = file[0];
    if (fileItem) {
      if (validImageTypes.includes(fileItem.type)) {
        formData.append('image', fileItem);
      } else {
        toast.error(toastText);
      }
    }
    dispatch(patchAvatar(formData)).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        dispatch(fetchPersonalData());
      }
    });
  };
  const isBioInvalid = profileInfo?.bio?.length > 140;

  // split string into array of interests

  let arrayOfInterests = interestsInfo.toString().split(',');
  let interestsArr: any = [];

  interests &&
    interests.map((el: any) => {
      interestsArr.push(el.name);
    });

  const mainPatchFunction =
    profile_type === 'unset' || profile_type === 'default' ? patchPersonalData : patchPersonalDataCreator;

  //Input refs
  const nameRef = useRef<HTMLInputElement>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);
  const interestsRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let interestsArr: any = [];
    interests && interests.map((el: any) => interestsArr.push(el.name));
    setInterestsInfo(interestsArr.toString().split(','));
  }, [interests]);

  const formData = new FormData();

  return (
    <PageWrap
      mainClass={styles.root}
      title={t('EDITINFO.create')}
      isProtected={true}
      goBack={Path.SignUp}
      stateClear={() => {
        setProfileInfo('');
        setInterestsInfo('');
      }}
    >
      <p>{t('EDITINFO.personal')}</p>
      <div className={styles['avatar_block']}>
        <img
          className={styles['avatar_img']}
          src={profile?.picture ? `${API_BASE_URL}${profile?.picture}` : anonymous}
          alt='avatar_image'
        />
        <div className={styles['avatar_txts']}>
          <p className={styles['avatar_title']}>{t('EDITINFO.profile')}</p>
          <label className={styles['avatar_input']} aria-label='Add photo'>
            <input
              className='visuallyHidden'
              type='file'
              accept='image/*'
              onChange={(e) => sendFile(e.target?.files)}
            />
            <p>{profile?.picture ? t('EDITINFO.change') : t('EDITINFO.upload')}</p>
          </label>
        </div>
      </div>
      <label className='inputWrap'>
        <span className='inputLabel'>{t('EDITINFO.name')}</span>

        <input
          onChange={(e: any) => {
            setProfileInfo({...profileInfo, first_name: e?.target?.value});
            setTouched(true);
          }}
          ref={nameRef}
          onFocus={() => scrollToElement(nameRef)}
          defaultValue={profileInfo.first_name ? profileInfo.first_name : profile?.first_name}
          type='text'
          placeholder={t('EDITINFO.your')}
        />
      </label>
      <label className='inputWrap sex'>
        <span className='innerLabel'>{t('EDITINFO.gender')}</span>
        <select
          onChange={(e) => {
            setProfileInfo({...profileInfo, gender: e.target.value});
            setTouched(true);
          }}
          defaultValue={profileInfo.gender ? profileInfo.gender : profile?.gender}
        >
          <option value='' disabled hidden>
            {t('EDITINFO.not')}
          </option>
          <option value='male'>{t('EDITINFO.man')}</option>
          <option value='female'>{t('EDITINFO.woman')}</option>
        </select>
      </label>
      <>
        <label className={navigator.platform === 'iPhone' ? 'inputWrap birth-iphone' : 'inputWrap birth'}>
          <span className='innerLabel'>{t('EDITINFO.date')}</span>
          <input
            type='date'
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, birth_date: e?.target?.value});
              setTouched(true);
            }}
            defaultValue={profileInfo.birth_date ? profileInfo.birth_date : profile?.birth_date}
          />
        </label>
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.bio')}</span>
          <textarea
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, bio: e?.target?.value});
              setTouched(true);
            }}
            defaultValue={profileInfo.bio ? profileInfo.bio : profile?.bio}
            ref={bioRef}
            onFocus={() => scrollToElement(bioRef)}
            placeholder={t('EDITINFO.a')}
          />
          {isBioInvalid && <span className={styles.error}>{t('EDITINFO.error')}</span>}
        </label>
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.interests')}</span>
          <input
            onChange={(e: any) => {
              setInterestsInfo(e?.target?.value);
              setTouched(true);
            }}
            type='text'
            ref={interestsRef}
            onFocus={() => scrollToElement(interestsRef)}
            defaultValue={interestsArr.join(', ')}
            placeholder={t('EDITINFO.your4')}
          />
        </label>
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.where')}</span>
          <input
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, city: e?.target?.value});
              setTouched(true);
            }}
            ref={locationRef}
            onFocus={() => scrollToElement(locationRef)}
            defaultValue={profileInfo.city ? profileInfo.city : profile?.city}
            type='text'
            placeholder={t('EDITINFO.your5')}
          />
        </label>{' '}
      </>

      <Button
        className={styles.btn}
        disabled={!touched || isBioInvalid}
        onClick={() =>
          hash &&
          dispatch(
            mainPatchFunction(
              profile_type === 'unset' || profile_type === 'default'
                ? {body: {...profileInfo, interests: arrayOfInterests}, hash}
                : {profileInfo, hash}
            )
          ).then((res: any) => {
            if (res.type && res.type.includes('fulfilled')) {
              navigate('/');
            }
          })
        }
      >
        {t('EDITINFO.create')}
      </Button>
    </PageWrap>
  );
}
