import {useState, useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import styles from './AttendTheEventLocation.module.scss';
import SelectPosition from '../../SelectPosition';
import {Path} from '../../../app/const';
import {useTranslation} from 'react-i18next';
import Button from '../../../shared/Button';
import {getLastSearchCoordinates} from '../../MeetSomeone/searchSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {selectCandidates} from '../../MeetSomeone/searchSlice';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {searchEvents} from '../eventsSlice';
import {selectProfileUser} from '../../../app/profileSlice';

const AttendTheEventLocation = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const radius = searchParams.get('radius');
  const interests = searchParams.get('interests');

  const {subscription} = useAppSelector(selectProfileUser);

  const dispatch = useAppDispatch();
  const {last_coordinates} = useAppSelector(selectCandidates);
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0
  });

  useEffect(() => {
    dispatch(getLastSearchCoordinates());
  }, []);

  const getSearchResults = () => {
    dispatch(
      searchEvents({
        interests: interests ? interests?.split(',') : [],
        type: 'event',
        request_profile: 0,
        radius: radius,
        latitude: position?.latitude,
        longitude: position?.longitude
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(Path.AttendTheEventEmptyResults);
      }
    });
  };

  let basicPosition = {lat: 48.7620186, lng: 15.2012252};

  if (last_coordinates?.latitude) {
    basicPosition = {lat: last_coordinates?.latitude, lng: last_coordinates?.longitude};
  } else if (subscription?.latitude) {
    basicPosition = {lat: subscription?.latitude, lng: subscription?.longitude};
  } else {
    basicPosition = {lat: 48.7620186, lng: 15.2012252};
  }

  useEffect(() => {
    if (last_coordinates?.longitude) {
      setPosition({latitude: last_coordinates?.latitude, longitude: last_coordinates?.longitude});
    } else if (subscription?.latitude) {
      setPosition({latitude: subscription?.latitude, longitude: subscription?.longitude});
    } else if (!subscription?.latitude && !last_coordinates?.longitude) {
      setPosition({latitude: basicPosition?.lat, longitude: basicPosition?.lng});
    }
  }, [subscription]);

  return (
    <PageWrap title={t('MEETSOMEONE.select')} goBack={Path.AttendTheEventChangeParams} mainClass={styles.root}>
      <p className='mb16'>{t('MEETSOMEONE.specify')}</p>
      <SelectPosition basicPosition={basicPosition} setPosition={setPosition} position={position} />
      <Button onClick={getSearchResults} disabled={position.latitude === 0}>
        {t('MEETSOMEONE.search2')}
      </Button>
    </PageWrap>
  );
};

export default AttendTheEventLocation;
