import {useState, useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import styles from './BrowseInterestsLocation.module.scss';
import SelectPosition from '../../SelectPosition';
import {Path} from '../../../app/const';
import {useTranslation} from 'react-i18next';
import Button from '../../../shared/Button';
import {getLastSearchCoordinates} from '../../MeetSomeone/searchSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {selectCandidates} from '../../MeetSomeone/searchSlice';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getInterestsArr} from '../interestsSlice';
import {selectProfileUser} from '../../../app/profileSlice';

const BrowseInterestsLocation = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {last_coordinates} = useAppSelector(selectCandidates);
  const {subscription} = useAppSelector(selectProfileUser);
  //const search_id = useAppSelector(selectSearchId);
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0
  });

  const [searchParams] = useSearchParams();
  const search_id = searchParams.get('search_id') || '';

  useEffect(() => {
    dispatch(getLastSearchCoordinates());
  }, []);

  const getSearchResults = () => {
    dispatch(
      getInterestsArr({
        coordiantes: {
          latitude: position.latitude,
          longitude: position.longitude
        },
        id: search_id
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(Path.BrowseInterestsEmptyResults);
      }
    });
  };

  useEffect(
    () => setPosition({latitude: subscription.latitude, longitude: subscription.longitude}),
    [subscription.latitude]
  );

  let basicPosition = {lat: 48.7620186, lng: 15.2012252};
  basicPosition = {
    lat: last_coordinates?.latitude ? last_coordinates?.latitude : subscription.latitude,
    lng: last_coordinates?.longitude ? last_coordinates?.longitude : subscription.longitude
  };

  const isFulldata = !search_id || !position.latitude || !position.longitude;

  return (
    <PageWrap title={t('BROWSEINTERESTS.browse')} goBack={Path.BrowseInterests} mainClass={styles.root}>
      <p className='mb16'>{t('MEETSOMEONE.specify')}</p>
      <SelectPosition
        basicCenter={basicPosition}
        basicPosition={basicPosition}
        setPosition={setPosition}
        position={position}
      />
      <Button className={styles.btn} onClick={getSearchResults} disabled={isFulldata}>
        {t('MEETSOMEONE.search2')}
      </Button>
    </PageWrap>
  );
};

export default BrowseInterestsLocation;
