import PageWrap from '../../../layout/PageWrap';
import styles from './DeleteProfile.module.scss';
import {Path} from '../../../app/const';
import {useTranslation} from 'react-i18next';
import Button from '../../../shared/Button';
import {useAppDispatch} from '../../../app/hooks';
import {deleteAccount, resetState} from '../../../app/profileSlice';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

const DeleteProfile = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const deleteProfile = () => {
    dispatch(deleteAccount()).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        dispatch(resetState(1));
        navigate(Path.ChooseLanguage);
        localStorage.clear();
        toast.success('Profile was removed');
      }
    });
  };

  return (
    <PageWrap mainClass='py32 px16' title={t('PROFILE.delete')} goBack={Path.Profile}>
      <div>
        <b className={'mb8'}>{t('PROFILE.oh')}</b>
        <p>{t('PROFILE.shame')}</p>
        <Button className={'mt32 mb16'} onClick={deleteProfile}>
          {t('PROFILE.yes')}
        </Button>
        <Button className={styles.btn} outlined to={Path.Profile}>
          {t('PROFILE.no')}
        </Button>
      </div>
    </PageWrap>
  );
};

export default DeleteProfile;
