import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {getVenue} from '../venuesAPI';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './VenueChangeNumber.module.scss';
import {useTranslation} from 'react-i18next';
import {getSmsCode} from '../../../app/profileSlice';
import {useAppDispatch} from '../../../app/hooks';
import {verifyVenue} from '../venueSlice';
import {Path} from '../../../app/const';

export default function VenueChangeNumber() {
  const {venueId} = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [venue, setVenue] = useState({contact_number: ''});
  const [phone, setPhone] = useState('');
  const [sms, setSms] = useState('');
  const handleInput = (setter: any) => (event: any) => setter(event.target.value);
  const {t} = useTranslation();

  useEffect(() => {
    venueId && getVenue(venueId, setVenue);
  }, []);

  useEffect(() => {
    venue?.contact_number && setPhone(venue.contact_number);
  }, [venue]);

  const resendCode = () => {
    dispatch(
      getSmsCode({
        phone_number: phone,
        purpose: 'venue_creation'
      })
    );
  };

  const sendVenueNumber = () => {
    if (step === 1) {
      dispatch(
        getSmsCode({
          phone_number: phone,
          purpose: 'venue_creation'
        })
      ).then((res: any) => {
        setStep(2);
      });
    }
    if (step === 2) {
      venueId &&
        dispatch(
          verifyVenue({
            id: +venueId,
            body: {
              phone_number: phone,
              code: +sms
            }
          })
        ).then((res) => {
          if (res.type && res.type.includes('fulfilled')) {
            navigate(`${Path.VenueEdit}/${venueId}`);
          }
        });
    }
  };

  return (
    <PageWrap mainClass={styles.root} title={t('VENUECHANGENUMBER.change')} goBack={-1}>
      <p className='mb24'>{t('VENUECHANGENUMBER.enter')}</p>
      <label className='inputWrap mt24'>
        <span className='inputLabel'>{t('VENUECHANGENUMBER.contact')}</span>
        <input type='tel' placeholder={t('VENUECHANGENUMBER.type')} value={phone} onChange={handleInput(setPhone)} />
      </label>
      {step === 2 && (
        <label className='inputWrap mt16'>
          <span className='inputLabel'>{t('VENUECHANGENUMBER.sms')}</span>
          <input type='number' placeholder={t('VENUECHANGENUMBER.type')} value={sms} onChange={handleInput(setSms)} />
        </label>
      )}

      <Button
        className='my32'
        disabled={step === 1 ? phone.length <= 9 || phone === venue.contact_number : +sms <= 100000}
        onClick={sendVenueNumber}
      >
        {t('VENUECHANGENUMBER.continue')}
      </Button>

      {step === 2 && (
        <Button onClick={resendCode} className={styles.resendBtn} outlined>
          {t('VENUECHANGENUMBER.resend')}
        </Button>
      )}
    </PageWrap>
  );
}
