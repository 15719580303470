import {useState, useEffect} from 'react';
import styles from './SignUp.module.scss';
import PageWrap from '../../layout/PageWrap';
import SecondStep from './steps/SecondStep';
import EnterCode from '../SignIn/steps/EnterCode';
import {useAppSelector} from '../../app/hooks';
import {selectProfileType} from '../../app/profileSlice';
import {Path} from '../../app/const';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

const SignUp = () => {
  const [step, setStep] = useState(2);
  const navigate = useNavigate();
  const profile_type = useAppSelector(selectProfileType);
  const {t} = useTranslation();
  useEffect(() => (profile_type === 'creator' ? setStep(3) : console.log('')), [profile_type]);
  const hash = localStorage.getItem('hash');

  return (
    <PageWrap
      title={profile_type === 'creator' ? t('PROFILE.change2') : step === 3 ? t('EDITINFO.create') : null}
      onClick={() => (hash ? navigate(Path.EditPersonalInformation) : setStep(2))}
      isProtected={false}
    >
      <div className={styles['root']}>
        {step === 2 && <SecondStep nextStep={setStep} />}
        {step === 3 && <EnterCode type={'signup'} />}
      </div>
    </PageWrap>
  );
};

export default SignUp;
