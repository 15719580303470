import {Link} from 'react-router-dom';
import {Path} from '../../app/const';
import {useAppSelector} from '../../app/hooks';
import {selectProfileUser, selectProfileCreator, selectProfileType, selectLoading} from '../../app/profileSlice';
import noPhotoIcon from '../../assets/icons/no-photo.svg';
import maleIcon from '../../assets/icons/male.svg';
import {ReactComponent as Verified} from '../../assets/icons/is_approved.svg';
import femaleIcon from '../../assets/icons/female.svg';
import {ReactComponent as MessageIcon} from '../../assets/icons/message.svg';
import styles from './Header.module.scss';
import {selectRooms} from '../../features/Chats/chatSlice';
import {calculateAge} from '../../app/const';
import {API_BASE_URL} from '../../app/const';
import {useTranslation} from 'react-i18next';

export default function Header() {
  const {profile, is_anonymous} = useAppSelector(selectProfileUser);
  const loading = useAppSelector(selectLoading);
  const creator = useAppSelector(selectProfileCreator);
  //const state = useAppSelector((state) => state);
  const profile_type = useAppSelector(selectProfileType);
  const {total_unread} = useAppSelector(selectRooms);
  const age = calculateAge(profile?.birth_date);

  const {t} = useTranslation();

  return (
    <header className={styles.root}>
      <Link className={styles.link} to={Path.Profile} aria-label={t('HEADER.your')}>
        {profile_type === 'default' || profile_type === 'unset' ? (
          <img
            className={styles.photo}
            src={profile?.picture ? `${API_BASE_URL}${profile?.picture}` : noPhotoIcon}
            alt='Profile'
          />
        ) : (
          <img className={styles.photo} src={creator?.picture ? creator?.picture : noPhotoIcon} alt='Profile' />
        )}

        <div className={styles.cell}>
          {profile_type === 'default' || profile_type === 'unset' ? (
            <h1 className={styles.title}>
              <p className={`row `}>
                <span
                  title={profile && profile.first_name ? profile.first_name : t('HEADER.anonymous')}
                  className={`truncated ${styles['title__truncated']}`}
                >
                  {profile && profile.first_name ? profile.first_name : t('HEADER.anonymous')}
                </span>
                <span className='ml5 row'> {profile && profile?.is_verified && <Verified />}</span>
              </p>
            </h1>
          ) : (
            <h1 className={styles.title}>
              <span className='truncated'>{creator?.full_name}</span>
            </h1>
          )}

          {profile &&
            loading === 'succeeded' &&
            (profile_type === 'default' || profile_type === 'unset' ? (
              <p className={`details truncated row ${styles.city}`}>
                {profile?.gender === 'male' && <img src={maleIcon} width='14px' height='14px' alt='Man' />}
                {profile?.gender === 'female' && <img src={femaleIcon} width='14px' height='14px' alt='Woman' />}
                <span className='truncated'>
                  {profile.city && profile?.birth_date
                    ? `${profile.city}, ${age} y.o.`
                    : profile.city && !profile?.birth_date
                    ? `${profile.city}`
                    : !profile.city && profile?.birth_date
                    ? `${age} y.o.`
                    : t('HEADER.no')}
                </span>
              </p>
            ) : (
              <span className='profile_type truncated'>{t('HEADER.creator')}</span>
            ))}
          {is_anonymous && (profile_type === 'default' || profile_type === 'unset') && (
            <span className='details truncated'>{t('HEADER.no')} </span>
          )}
        </div>
      </Link>

      {/* <Link className={styles.link} to={Path.CreatePersonalInformation} aria-label={t('HEADER.your')}>
        <div className={styles.cell}>
          <h1 className={styles.title}>
            <span className='truncated'>DONT FORGET TO DELETE</span>
          </h1>
        </div>
      </Link> */}

      {(profile_type === 'default' || profile_type === 'unset') && (
        <Link className={`${styles.chats} hover`} to={Path.Chats}>
          <MessageIcon />
          {total_unread > 0 && <span className={styles.chatsNum}>{total_unread}</span>}
        </Link>
      )}
    </header>
  );
}
