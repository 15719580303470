import React from 'react';
import Skeleton from 'react-skeleton-loader';
import styles from './SkeletonSquare.module.scss';

function SkeletonSquare() {
  return (
    <div className={styles.skeleton__template}>
      <div className={styles.skeleton__template_img}>
        <Skeleton width='64px' height='64px' widthRandomness='0' borderRadius='4px' />
      </div>

      <div className={styles.skeleton__template_col}>
        <Skeleton width='285px' widthRandomness='0' height='64px' />
      </div>
    </div>
  );
}

export default SkeletonSquare;
