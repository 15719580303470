import {useEffect, useState} from 'react';
import styles from '../SignIn.module.scss';
import {useTranslation} from 'react-i18next';
import Button from '../../../shared/Button';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {
  getSmsCode,
  signUp,
  signIn,
  updatePhoneNumber,
  selectProfileType,
  selectProfileCreator,
  updatePhoneCreatorNumber,
  selectPersonalInfo
} from '../../../app/profileSlice';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Path} from '../../../app/const';
import {verifyVenue, selectVenues} from '../../Venues/venueSlice';

import parsePhoneNumber from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import {ReactComponent as ResendIcon} from '../icon/resend_icon.svg';
import {getSmsCodeDTO} from '../../../app/profileSliceProps';

interface EnterCodeProps {
  type:
    | 'signin'
    | 'signup'
    | 'update'
    | 'creation'
    | 'venue_creation'
    | 'event_creation'
    | 'change_phone_number'
    | 'creator_profile_creation';
}

const EnterCode = ({type}: EnterCodeProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [data, setData] = useState({
    phone_number: '',
    code: 0
  });
  const personalInfo = useAppSelector(selectPersonalInfo);
  const creatorInfo = useAppSelector(selectProfileCreator);
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const {venue} = useAppSelector(selectVenues);
  const profile_type = useAppSelector(selectProfileType);
  const clearPhoneNumner = data.phone_number.replace(/[^0-9,+]+/g, '');
  const [searchParams] = useSearchParams();
  const isQuick = searchParams.get('isQuick');
  const event_id = searchParams.get('event_id');
  const venue_id = searchParams.get('venue_id');

  //JUST FOR CREATE EVENT AND CREATE VENUE WE SET THE PHONE NUMBER IN THE INPUT FIELD
  // IF PHONE_NUMBER_VERIFCATED == TRUE

  useEffect(() => {
    personalInfo.is_phone_number_verified &&
      personalInfo.phone_number &&
      type === 'venue_creation' &&
      setData({
        ...data,
        phone_number: personalInfo.phone_number
      });

    //SHOW PHONE WHEN CREATE CREATOR PROFILE AT FIRST TIME

    type === 'update' &&
      personalInfo.is_phone_number_verified &&
      personalInfo.phone_number &&
      setData({
        ...data,
        phone_number: personalInfo.phone_number
      });

    //NEW FEATURE FOR CREATOR
    personalInfo.is_phone_number_verified &&
      creatorInfo.phone_number &&
      setData({
        ...data,
        phone_number: creatorInfo.phone_number
      });
  }, []);

  let purposeType: getSmsCodeDTO['purpose'];

  switch (type) {
    case 'signup':
      switch (profile_type) {
        case 'creator':
          purposeType = 'creator_profile_creation';
          break;
        default:
          purposeType = 'profile_creation';
          break;
      }
      break;
    case 'update':
      switch (profile_type) {
        case 'creator':
          purposeType = 'creator_profile_creation';
          break;
        default:
          purposeType = 'change_phone_number';
          break;
      }
      break;
    case 'venue_creation':
      purposeType = 'venue_creation';
      break;
    case 'creation':
      purposeType = 'change_creator_phone_number';
      break;
    default:
      purposeType = 'sign_in';
      break;
  }

  const sendCode = () => {
    dispatch(
      getSmsCode({
        phone_number: clearPhoneNumner,
        purpose: purposeType
      })
    ).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        if (res.payload.is_verified) {
          if (type === 'event_creation' || type === 'venue_creation') {
            type === 'venue_creation' && !isQuick && navigate(Path.VenueAddPhotos);
            type === 'venue_creation' &&
              isQuick &&
              navigate(`${Path.VenueAddPhotos}?isQuick=${isQuick}&event_id=${event_id}&venue_id=${venue_id}`);
          } else if (type === 'update' && profile_type === 'creator') {
            navigate(`${Path.EditPersonalInformation}?type=create`);
          } else {
            setStep(1);
          }
        } else {
          setStep(1);
        }
      }
    });
  };

  const signUpFunction = () => {
    dispatch(
      signUp({
        phone_number: clearPhoneNumner,
        code: data.code
      })
    ).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        res.payload.has_exists_account ? navigate('/') : navigate(Path.CreatePersonalInformation);
      } else {
        setError(true);
      }
    });
  };

  const signInFunction = () => {
    dispatch(
      signIn({
        phone_number: clearPhoneNumner,
        code: data.code
      })
    ).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate('/');
      } else {
        setError(true);
      }
    });
  };

  const updatePhoneNumberFunction = () => {
    dispatch(
      updatePhoneNumber({
        phone_number: clearPhoneNumner,
        code: data.code
      })
    ).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(Path.Profile);
      } else {
        setError(true);
      }
    });
  };

  const updatePhoneCreatorNumberFunction = () => {
    dispatch(
      updatePhoneCreatorNumber({
        phone_number: clearPhoneNumner,
        code: data.code
      })
    ).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(-1);
      } else {
        setError(true);
      }
    });
  };

  const verifyVenueFunc = () => {
    dispatch(
      verifyVenue({
        id: venue?.id,
        body: {
          phone_number: clearPhoneNumner,
          code: data.code
        }
      })
    ).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(Path.VenueAddPhotos);
      }
    });
  };

  const phoneNumberData = parsePhoneNumber(`+${data.phone_number}`, 'US');

  const isValid = phoneNumberData && phoneNumberData.isValid();

  const onPhoneChange = (phone: any) => {
    setStep(0);
    setData({...data, phone_number: phone});
  };

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  let language;

  switch (localStorage.getItem('i18nextLng')) {
    case 'ee':
      language = 'ee';
      break;
    case 'de':
      language = 'de';
      break;
    case 'ua':
      language = 'ua';
      break;
    case 'ru':
      language = 'ua';
      break;
    default:
      language = 'us';
  }

  return (
    <div className={styles['root']}>
      <p className={styles['signin__text']}>{t('SIGNIN.enter')}</p>
      <div>
        <label className='inputWrap mt24'>
          <span className='inputLabel'>{t('SIGNIN.phone')}</span>

          <PhoneInput
            containerClass={styles.contact_form__phone}
            inputClass={styles.phone__input}
            buttonClass={styles.phone__btn}
            dropdownClass={styles.phone__dropdown}
            country={language}
            value={clearPhoneNumner}
            onChange={onPhoneChange}
          />
        </label>

        {step === 1 && (
          <label className='inputWrap mt24'>
            <span className='inputLabel'>{t('SIGNIN.sms')}</span>

            <input
              onChange={(e: any) => {
                setData({...data, code: e?.target?.value});
              }}
              type='number'
              placeholder={t('SIGNIN.type')}
            />
          </label>
        )}

        <Button
          disabled={
            step === 0
              ? personalInfo.phone_number ===
                `${data.phone_number.includes('+') ? data.phone_number : `+${data.phone_number}`}`
                ? false
                : typeof isValid === 'undefined' || !isValid
              : !!!data.code
          }
          onClick={() => {
            dispatch(
              step === 0
                ? sendCode
                : type === 'signup'
                ? signUpFunction
                : type === 'update'
                ? updatePhoneNumberFunction
                : type === 'venue_creation'
                ? verifyVenueFunc
                : type === 'creation'
                ? updatePhoneCreatorNumberFunction
                : signInFunction
            );
          }}
          className={'mt32'}
        >
          {t('SIGNIN.continue')}
        </Button>
      </div>

      {step === 1 && (
        <Button
          onClick={() => {
            sendCode();
            setCounter(59);
          }}
          disabled={counter > 0}
          className={`${styles['signin__resend']} mt16`}
          outlined
        >
          {t('SIGNIN.resend')}
        </Button>
      )}

      {step === 1 && counter > 0 && (
        <div className={styles.resend}>
          <ResendIcon />
          <p>{t('SIGNIN.dont')} </p>
          <b>{t('SIGNIN.try')}</b>
          <b>
            0:{counter < 10 && 0}
            {counter}
          </b>
        </div>
      )}
    </div>
  );
};

export default EnterCode;
