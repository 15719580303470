import PageWrap from '../../../layout/PageWrap';
import {useEffect} from 'react';
import styles from './AttendeesAndInterested.module.scss';
import Button from '../../../shared/Button';
import image from './image/Image.png';
import {Path} from '../../../app/const';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {selectEventsAllInfo, getAttendedSingleEvent} from '../eventsSlice';

const AttendeesAndInterested = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const {attendedSingleEvent} = useAppSelector(selectEventsAllInfo);
  const id = pathname.split('/')[3];
  useEffect(() => {
    dispatch(getAttendedSingleEvent(id));
  }, []);

  return (
    <PageWrap goBack={-1} mainClass={styles.root} title={t('ATTENDEESANDINTERESTED.title')}>
      <img src={image} alt='empty events' />
      <b className='mt16'>{t('ATTENDEESANDINTERESTED.okay')}</b>
      <p>
        {t('ATTENDEESANDINTERESTED.there')}{' '}
        <b>
          {attendedSingleEvent?.total_attendees} {t('ATTENDEESANDINTERESTED.attendees')}
        </b>
        {t('ATTENDEESANDINTERESTED.and')}{' '}
        <b>
          {attendedSingleEvent?.interesed_people} {t('ATTENDEESANDINTERESTED.interested')}
        </b>
        {t('ATTENDEESANDINTERESTED.in')}
      </p>

      <Button to={`${Path.AttendingPeople}?event_id=${id}&type=attended`} className={styles.btn}>
        {t('ATTENDEESANDINTERESTED.see1')}
      </Button>
      <Button to={`${Path.AttendingPeople}?event_id=${id}&type=interested`} outlined className='mt16'>
        {t('ATTENDEESANDINTERESTED.see2')}
      </Button>
    </PageWrap>
  );
};

export default AttendeesAndInterested;
