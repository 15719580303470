import React from 'react';
import {Link} from 'react-router-dom';
import PageWrap from '../../layout/PageWrap';
import {Path} from '../../app/const';
import {useAppSelector} from '../../app/hooks';
import {selectProfileUser, selectProfileType, getFreeSubscription} from '../../app/profileSlice';

import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg';
import {ReactComponent as QRCodeIcon} from '../../assets/icons/qr-code.svg';
import {ReactComponent as LocationIcon} from '../../assets/icons/location.svg';
import balloonsIcon from '../../assets/icons/balloons.svg';
import ribbonsIcon from '../../assets/icons/ribbons.svg';
import starsIcon from '../../assets/icons/stars.svg';
import styles from './MainMenu.module.scss';
import venuesIcon from '../../assets/icons/venues.svg';

import Countdown, {zeroPad} from 'react-countdown';
import {selectLanguage} from '../../app/profileSlice';
import {useNavigate} from 'react-router-dom';

import {useTranslation} from 'react-i18next';

export default function MainMenu() {
  const {subscription} = useAppSelector(selectProfileUser);
  const navigate = useNavigate();
  const profile_type = useAppSelector(selectProfileType);
  let date = subscription?.buy_date ? new Date(subscription?.buy_date) : new Date();
  const {t} = useTranslation();
  const language = useAppSelector(selectLanguage);

  let milliseconds = date.getTime(); // Дата покупки в мілісекундах
  let date2 = milliseconds + 1000 * 60 * 60 * 24; //Дата закічнення підписки в мілісекундах - +24 години

  const mainMenuNav = [
    {
      link: Path.MeetSomeone,
      icon: balloonsIcon,
      label: t('MAINMENU.meet'),
      caption: t('MAINMENU.meet1')
    },
    {
      link: Path.AttendTheEvent,
      icon: ribbonsIcon,
      label: t('MAINMENU.attend'),
      caption: t('MAINMENU.attend1')
    },

    {
      link: Path.BrowseInterests,
      icon: starsIcon,
      label: t('MAINMENU.browse'),
      caption: t('MAINMENU.browse1')
    }
  ];

  const mainMenuNavCreator = [
    {
      link: Path.EventCreator,
      icon: ribbonsIcon,
      label: t('MAINMENU.events'),
      caption: t('MAINMENU.events1')
    },
    {
      link: Path.VenuesCreator,
      icon: venuesIcon,
      label: t('MAINMENU.venues'),
      caption: t('MAINMENU.venues1')
    }
  ];

  const renderMenu = profile_type === 'default' || profile_type === 'unset' ? mainMenuNav : mainMenuNavCreator;

  return (
    <PageWrap header>
      {(profile_type === 'default' || profile_type === 'unset') && (
        <div className={styles.subs}>
          {!subscription && (
            <>
              <span className={styles.title}>{t('MAINMENU.hey')}</span>{' '}
              <button
                className={language === 'en' ? 'navBtn' : 'navBtn lower'}
                type='button'
                onClick={() => navigate(Path.SubscriptionDetails)}
              >
                <span className='label'> {t('MAINMENU.click')}</span>
                <ArrowIcon />
              </button>
            </>
          )}
          <Link to={Path.SubscriptionDetails}>
            <div className='caption mb8'>{t('MAINMENU.click_here')}</div>
          </Link>

          {(subscription?.type === 'buy' || subscription?.type === 'qr' || subscription?.type === 'beta') && (
            <Link className={`${styles.subsBtn} navBtn`} to={Path.SubscriptionDetails}>
              {subscription?.type === 'buy' && <CheckIcon />}
              {subscription?.type === 'qr' && <QRCodeIcon />}
              <span className={'label lower'}>
                {subscription?.type === 'buy' && t('MAINMENU.paid')}
                {/* {subscription?.type === 'qr' && 'QR-code'} {t('MAINMENU.subscription')}  */}
              </span>
              <Countdown
                renderer={({hours, minutes}) => (
                  <span className={styles.subsTime}>
                    {zeroPad(hours)}:{zeroPad(minutes)} {t('MAINMENU.left')}
                  </span>
                )}
                date={date2}
              ></Countdown>

              <ArrowIcon />
            </Link>
          )}
          {(subscription?.type === 'buy' || subscription?.type === 'qr') && (
            <Link to={Path.SubscriptionDetails}>
              <span className='caption'>
                <>
                  <LocationIcon />
                  {subscription?.address ? (
                    <div className={styles.address_row}>
                      {t('MAINMENU.visible')} <p className={styles.address}> {subscription?.address}</p>
                    </div>
                  ) : (
                    t('MAINMENU.enter')
                  )}
                </>
                {!subscription && t('MAINMENU.to')}
              </span>
            </Link>
          )}
        </div>
      )}

      <div className={styles.nav}>
        <span className={styles.title}>{profile_type === 'default' ? t('MAINMENU.what') : t('MAINMENU.lets')}</span>

        {renderMenu.map(({link, icon, label, caption}, index) => (
          <div key={index} className={styles.navItem}>
            <Link className={language === 'en' ? 'navBtn' : 'navBtn lower'} to={link}>
              {icon && <img src={icon} width='32px' height='32px' alt='Link icon' />}
              {label && <span className='label'>{label}</span>}
              <ArrowIcon />
            </Link>
            {caption && <span className='caption'>{caption}</span>}
          </div>
        ))}
      </div>
    </PageWrap>
  );
}
