import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import styles from './TagGroup.module.scss';
import {selectLanguage} from '../../app/profileSlice';
import {useAppSelector} from '../../app/hooks';
interface TagGroupProps {
  data: any[];
  title?: string;
  onTagClick: any;
}

export default function TagGroup({data, title, onTagClick}: TagGroupProps) {
  const lang = useAppSelector(selectLanguage);
  return (
    <div className='btnGroup mt8 mb24'>
      {title && <span className='details mb8 semiBold'>{title}</span>}
      {data.map(({interest, total}, idx) => (
        <button
          key={idx}
          className={lang === 'en' ? 'navBtn' : 'navBtn lower'}
          type='button'
          onClick={() => onTagClick(interest)}
        >
          <span className='label'>{interest}</span>
          <span className={styles.number}>{total}</span>
          <ArrowIcon />
        </button>
      ))}
    </div>
  );
}
