// Settings for the map goes inside this file
import mapStyles from './mapStyles';

export const containerStyle = {
    width: '100%',
    height: '504px'
};

// Center on Tallin
export const center = {
    lat: 48.7620186,
    lng: 15.2012252
};


// Disable default UI
export const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true
};