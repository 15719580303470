import React, {ChangeEvent} from 'react';
import {Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption} from '@reach/combobox';

import usePlacesAutocomplete, {getGeocode, getLatLng} from 'use-places-autocomplete';
import styles from './SearchPanel.module.scss';
import {ReactComponent as Search} from '../icons/find.svg';

interface SearchPanelProps {
  smallMap?: Boolean;
  panTo: any;
}

function SearchPanel({panTo, smallMap}: SearchPanelProps) {
  const {
    ready,
    value,
    suggestions: {status, data},
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      location: new window.google.maps.LatLng(40.7127753, -74.0059728),
      radius: 100 * 1000
    }
  });

  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  const handleSelect = async (address: any) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({address});
      const {lat, lng} = await getLatLng(results[0]);
      panTo({lat, lng});
    } catch (error) {
      console.log('😱 Error: ', error);
    }
  };

  return (
    <div className={smallMap ? styles.search__block_s : styles.search__block}>
      <Combobox onSelect={handleSelect}>
        <Search />
        <ComboboxInput value={value} disabled={!ready} onChange={handleInput} placeholder='Search on the map' />

        <ComboboxPopover portal={false} className={styles.search__container_s}>
          <ComboboxList>
            {status === 'OK' && data.map(({id, description}) => <ComboboxOption key={id} value={description} />)}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}

export default SearchPanel;
