import PageWrap from '../../../layout/PageWrap';
import EventSlider from '../../../shared/EventSlider';
// import styles from './MatchingResults.module.scss';
import {useTranslation} from 'react-i18next';

export default function MatchingResults() {
  const {t} = useTranslation();
  return (
    <PageWrap goBack={-1} title={t('MATCHINGRESULTS.matching')}>
      <EventSlider />
    </PageWrap>
  );
}
