import {useEffect} from 'react';
import {useState} from 'react';
import Button from '../Button';
import styles from './EventEditCard.module.scss';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {getSingleEvent, singleEvent, updateEventData} from '../../features/AttendTheEvent/eventsSlice';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {useLocation} from 'react-router-dom';
import {format, isBefore} from 'date-fns';
import {Path} from '../../app/const';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {selectLanguage} from '../../app/profileSlice';
import {utcToZonedTime} from 'date-fns-tz';

interface EventCardProps {
  id?: string;
  className?: string;
  img: string;
  name: string;
  people: number;
  venue: string;
  time: string;
  desc: string;
  tagList?: string[];
  marked?: boolean;
  creator?: boolean;
}

export default function EventEditCard({
  id,
  className = '',
  img,
  name,
  people,
  venue,
  time,
  desc,
  tagList,
  creator = true,
  marked
}: EventCardProps) {
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const currentEvent = useAppSelector(singleEvent);
  const eventId = pathname.split('/')[3];
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [inputStartDate, setStartDate] = useState(null);
  const [inputEndDate, setEndDate] = useState(null);
  const [touched, setTouched] = useState(false);

  // set the date to UTC
  const start_date_utc = currentEvent && currentEvent.start_date && utcToZonedTime(currentEvent.start_date, 'UTC');
  const start_date = start_date_utc && format(new Date(start_date_utc), 'yyyy-MM-dd HH:mm');
  const start_date_changed = inputStartDate && format(new Date(inputStartDate), 'dd/MM/yyyy');
  const start_date_started = start_date_utc && format(new Date(start_date_utc), 'dd/MM/yyyy HH:mm');

  const end_date_utc = currentEvent && currentEvent.end_date && utcToZonedTime(currentEvent.end_date, 'UTC');
  const end_date = end_date_utc && format(new Date(end_date_utc), 'yyyy-MM-dd HH:mm');
  const end_date_changed = inputEndDate && format(new Date(inputEndDate), 'dd/MM/yyyy');
  const end_date_started = end_date_utc && format(new Date(end_date_utc), 'dd/MM/yyyy HH:mm');

  const [isWrong, setIsWrong] = useState(false);

  const toastText = t('EVENTCHANGENUMBER.toast');

  const [formInfo, setFormInfo] = useState({
    end_date: '',
    start_date: ''
  });

  useEffect(() => {
    if (isBefore(new Date(formInfo.end_date), new Date(formInfo.start_date))) {
      toast.error(toastText, {
        toastId: 'wrongdate'
      });
      setIsWrong(true);
    } else {
      setIsWrong(false);
    }
  }, [formInfo]);

  const changeForm = (keyName: any, value: any) => {
    setFormInfo({...formInfo, [keyName]: value});
    !touched && setTouched(true);
  };

  useEffect(() => {
    !currentEvent && dispatch(getSingleEvent(eventId));
  }, []);

  useEffect(() => {
    currentEvent?.start_date &&
      setFormInfo({...formInfo, start_date: currentEvent?.start_date, end_date: currentEvent?.end_date});
  }, [currentEvent]);

  //Return array of interest from currentEvent.interest and turned into a string
  const interest = currentEvent?.interest?.map((item: any) => item.name);

  //Turned array of interest into string
  const interestString = interest && interest?.join(', ');

  const lang = useAppSelector(selectLanguage);

  return (
    <div className={`${styles.root}${className && ` ${className}`}`}>
      <label className='inputWrap mb-16'>
        <span className='inputLabel'>{t('EVENTEDITCARD.name')}</span>

        <input
          onChange={(e: any) => {
            changeForm('name', e.target.value);
          }}
          defaultValue={currentEvent?.name}
          type='text'
          placeholder={t('EVENTEDITCARD.event')}
        />
      </label>
      <label className='inputWrap mb-16'>
        <span className='inputLabel'>{t('EVENTEDITCARD.description')}</span>

        <textarea
          onChange={(e: any) => {
            changeForm('description', e.target.value);
          }}
          defaultValue={currentEvent?.description}
          rows={4}
          placeholder={t('EVENTEDITCARD.event2')}
        />
      </label>
      <label className='inputWrap mb-16'>
        <span className='inputLabel'>{t('EVENTEDITCARD.interests')}</span>

        <input
          onChange={(e: any) => {
            changeForm('interest', e.target.value.split(', '));
          }}
          defaultValue={interestString}
          type='text'
          placeholder={t('EVENTEDITCARD.event1')}
        />
      </label>

      <label
        className={
          navigator.platform === 'iPhone'
            ? 'inputWrap  label-wrapper birth-iphone mb-16'
            : 'inputWrap  label-wrapper birth mb-16'
        }
      >
        <span className='innerLabel'>{t('EVENTEDITCARD.start')}</span>
        <input
          type='datetime-local'
          onChange={(e: any) => {
            changeForm('start_date', e.target.value);
            setStartDate(e.target.value);
          }}
          defaultValue={start_date || ''}
        />

        <span className='datetime-local'>{start_date_changed ? start_date_changed : start_date_started}</span>
      </label>

      <label
        className={
          navigator.platform === 'iPhone'
            ? 'inputWrap label-wrapper birth-iphone mb-32'
            : 'inputWrap label-wrapper birth mb-32'
        }
      >
        <span className='innerLabel'>{t('EVENTEDITCARD.end')}</span>

        <input
          type='datetime-local'
          onChange={(e: any) => {
            changeForm('end_date', e.target.value);
            setEndDate(e.target.value);
          }}
          defaultValue={end_date || ''}
        />

        <span className='datetime-local'>{end_date_changed ? end_date_changed : end_date_started}</span>
      </label>

      <label className='inputWrap mb-16'>
        <span className='inputLabel'>{t('EVENTEDITCARD.maximum')}</span>

        <input
          onChange={(e: any) => {
            changeForm('max_attendees', +e.target.value === 0 ? 1 : e.target.value);
          }}
          defaultValue={currentEvent?.max_attendees}
          type='number'
          min={1}
          onKeyUp={(e) => {
            if (parseInt(e.currentTarget.value) < 1) {
              e.currentTarget.value = '1';
            }
          }}
          placeholder={'0'}
        />
      </label>

      <Button
        className={styles.btn}
        disabled={isWrong || !touched}
        onClick={() =>
          eventId &&
          dispatch(updateEventData({id: eventId, data: formInfo})).then((res) => {
            if (res.type && res.type.includes('fulfilled')) {
              toast.success('Event data updated successfully');
            }
          })
        }
      >
        {t('EVENTEDITCARD.save')}
      </Button>

      <div className={styles.separator} />

      <span className={styles.label}>{t('EVENTEDITCARD.more')}</span>
      <div className='btnGroup mb-32'>
        <button
          className={lang === 'en' ? 'navBtn ' : 'navBtn lower '}
          type='button'
          onClick={() => navigate(`${Path.EventsChangeNumber}/${eventId}`)}
        >
          <span className='label'>{t('EVENTEDITCARD.change')}</span>
          <ArrowIcon />
        </button>
        <button
          className={lang === 'en' ? 'navBtn ' : 'navBtn lower '}
          type='button'
          onClick={() => navigate(`${Path.EventEditLocation}/${eventId}`)}
        >
          <span className='label'>{t('EVENTEDITCARD.change1')}</span>
          <ArrowIcon />
        </button>
        <button
          className={lang === 'en' ? 'navBtn ' : 'navBtn lower '}
          type='button'
          onClick={() => navigate(`${Path.EventsPhotos}/${eventId}`)}
        >
          <span className='label'>{t('EVENTEDITCARD.events')}</span>
          <ArrowIcon />
        </button>
      </div>
    </div>
  );
}
