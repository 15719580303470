import React from 'react';
import {Link} from 'react-router-dom';
import type {LinkProps} from 'react-router-dom';
import styles from './Button.module.scss';

type BaseProps = {
  outlined?: boolean | string;
};

type ButtonAsButtonProps = BaseProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    to?: undefined;
  };

type ButtonAsLinkProps = BaseProps &
  Omit<LinkProps, keyof BaseProps> & {
    to?: string;
  };

type ButtonProps = ButtonAsButtonProps | ButtonAsLinkProps;

const hasTo = (props: ButtonProps): props is ButtonAsLinkProps => 'to' in props;

export default function Button(props: ButtonProps): JSX.Element {
  let {className, outlined} = props;
  const classes = [styles.root];
  if (className) classes.push(className);
  if (outlined) {
    classes.push(styles.outlined);
    outlined = "true";
  } else {
    outlined = "false";
  }

  if (hasTo(props)) return <Link {...{...props, outlined}} className={classes.join(' ')} />;
  return <button type='button' {...{...props, outlined}} className={classes.join(' ')} />;
}
