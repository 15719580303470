import React from 'react';
import styles from './SlideCounter.module.scss';

interface CounterProps {
  className?: string;
  length: number;
  index: number;
  isActive: boolean;
}

function SlideCounter({className = '', length, index, isActive}: CounterProps) {
  return (
    <div className={isActive ? styles.fadeout : styles.fadeout__hidden}>
      <span>{`${index + 1} of ${length}`}</span>
    </div>
  );
}

export default SlideCounter;
