import ProfileCard from '../../../shared/ProfileCard';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import img from '../../../assets/images/profile.png';
import styles from './UserProfile.module.scss';
import {Path} from '../../../app/const';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {getSingleUser, singleCandidate} from '../../MeetSomeone/searchSlice';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export default function UserProfile() {
  const dispatch = useAppDispatch();
  const candidate = useAppSelector(singleCandidate);

  const {pathname} = useLocation();
  const id = pathname.split('/')[3];
  useEffect(() => {
    id && dispatch(getSingleUser(+id));
  }, [id]);
  const {t} = useTranslation();


  return (
    <PageWrap mainClass={styles.root} title='User profile'>
      {candidate && <ProfileCard
        picture={candidate.picture }
        first_name={candidate.first_name }
        gender={candidate.gender }
        city={candidate.city }
        birth_date={candidate.birth_date }
        interests={candidate.interests}
        bio={candidate.bio }
      />}
      <Button className={styles.btn} to={`${Path.Chats}/0`}>
        {t("USERPROFILE.send")}
      </Button>
    </PageWrap>
  );
}
