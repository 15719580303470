import {useEffect} from 'react';
import styles from './PageHeader.module.scss';
import {ReactComponent as Logo} from './image/logo.svg';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {fetchPersonalData} from '../../features/BrowseInterests/interestsSlice';
import {getPersonalInfo} from '../../app/profileSlice';
import {fetchPersonalDataCreator} from '../../app/profileSlice';
import {selectRooms, getRooms, setStartedPolling} from '../../features/Chats/chatSlice';
import {selectProfileUser, changeLanguage} from '../../app/profileSlice';
import i18n from 'i18next';

import {useNavigate} from 'react-router-dom';

const PageHeader = () => {
  const dispatch = useAppDispatch();
  const hash = localStorage.getItem('hash');
  const navigate = useNavigate();
  const {total_unread, isPollingStarted} = useAppSelector(selectRooms);
  const {is_anonymous} = useAppSelector(selectProfileUser);

  const location = window.location.pathname;

  const polling = () => {
    const timer = setInterval(() => {
      dispatch(getRooms());
      dispatch(setStartedPolling(true));
      document.title = total_unread > 0 ? `CherryDate —  you have new messages` : 'CherryDate';
    }, 3000);
    return () => clearInterval(timer);
  };

  useEffect(() => {
    hash &&
      dispatch(getPersonalInfo()).then((res) => {
        if (res.type && res.type.includes('fulfilled')) {
          res.payload.type !== 'creator' ? dispatch(fetchPersonalData()) : dispatch(fetchPersonalDataCreator());

          /*In this part we check if there is a difference between localLanguage(set by in18 at the 1st step (without changing in DB and might be changed after refresh) and language from DB. If tehre is a difference we set language from localStorage. */

          const {language} = res.payload;
          const localLanguage = localStorage.getItem('i18nextLng');

          if (language !== localLanguage) {
            language && i18n.changeLanguage(language);
          }
          if (!language) {
            dispatch(changeLanguage({language: localLanguage}));
          }
        }
      });
  }, []);

  useEffect(() => {
    !is_anonymous && !isPollingStarted && (location === '/' || location.includes('/chats')) && polling();
  }, [is_anonymous]);

  return (
    <header className={styles['root']}>
      <button aria-label='logo' onClick={() => navigate('/')}>
        <Logo />
      </button>
    </header>
  );
};

export default PageHeader;
