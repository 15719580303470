import {useState, useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import styles from './VenueLocation.module.scss';
import Button from '../../../shared/Button';
import SelectPosition from '../../SelectPosition';
import {useNavigate, useLocation, useParams, useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {Path} from '../../../app/const';
import {createVenue, updateVenueLocation, selectVenues, getVenue} from '../venueSlice';

interface pageProps {
  type: 'edit' | 'create';
}

const VenueLocation = ({type}: pageProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {venueId} = useParams();
  const {venue} = useAppSelector(selectVenues);
  const [searchParams] = useSearchParams();
  const isQuick = searchParams.get('isQuick');
  const event_id = searchParams.get('event_id');

  const id = venueId ? venueId : 0;

  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0
  });

  const isValid = position.latitude !== 0 || position.longitude !== 0;

  useEffect(() => {
    type === 'edit' && dispatch(getVenue(+id));
  }, []);

  const createNewVenue = () => {
    dispatch(
      createVenue({
        name: location.state.name,
        description: location.state.description,
        latitude: position.latitude,
        longitude: position.longitude
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(`${Path.VenueEnterNumber}?isQuick=${isQuick}&event_id=${event_id}&venue_id=${res.payload.id}`);
      }
    });
  };

  const editLocation = () => {
    dispatch(
      updateVenueLocation({
        id: +id,
        location: {
          latitude: position.latitude,
          longitude: position.longitude
        }
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(`${Path.VenueEdit}/${venueId}`);
      }
    });
  };

  return (
    <PageWrap
      title={type === 'create' ? t('VENUECHANGENUMBER.select') : t('VENUEEDIT.change2')}
      goBack={type === 'create' ? Path.CreateVenue : `${Path.VenueEdit}/${venueId}`}
      mainClass={styles.root}
    >
      <p className='mb16'>{t('MEETSOMEONE.specify')}</p>
      <SelectPosition
        basicCenter={{
          lat: position.latitude,
          lng: position.longitude
        }}
        basicPosition={{
          lat: venue.latitude,
          lng: venue.longitude
        }}
        setPosition={setPosition}
      />
      <Button onClick={type === 'create' ? createNewVenue : editLocation} disabled={!isValid} className={styles.btn}>
        {type === 'create' ? t('VENUECHANGENUMBER.continue') : t('VENUEEDIT.confirm')}
      </Button>
    </PageWrap>
  );
};

export default VenueLocation;
