import PageWrap from '../../layout/PageWrap';
import {useTranslation} from 'react-i18next';

import {Path} from '../../app/const';
import EnterCode from './steps/EnterCode';

const SignIn = () => {
  const {t} = useTranslation();

  return (
    <PageWrap isProtected={false} title={t('SIGNIN.login')} goBack={Path.SignUp}>
      <EnterCode type={'signin'} />
    </PageWrap>
  );
};

export default SignIn;
