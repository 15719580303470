import {useState, useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import styles from './EventLocation.module.scss';
import SelectPosition from '../../SelectPosition';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {Path} from '../../../app/const';
import {
  getSingleEvent,
  singleEvent,
  closestVenues,
  getClosestVenuesList,
  updateEventVenue,
  getVenueByPs
} from '../../AttendTheEvent/eventsSlice';
import {ReactComponent as Arrow} from './images/arrow.svg';
import {ReactComponent as Location} from './images/location.svg';

interface pageProps {
  type: 'edit' | 'create';
}

const EventLocation = ({type}: pageProps) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {eventId} = useParams();
  const event = useAppSelector(singleEvent);
  const [showFull, setShowFull] = useState(false);
  const {our_venues, positionstack_venues, positionstack_request_id} = useAppSelector(getClosestVenuesList);

  //const type = searchParams.get("type")

  const id = eventId ? eventId : 0;

  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0
  });

  useEffect(() => {
    type === 'edit' && dispatch(getSingleEvent(+id));
  }, []);

  useEffect(() => {
    position.latitude !== 0 &&
      dispatch(
        closestVenues({
          latitude: position.latitude,
          longitude: position.longitude
        })
      );
  }, [position]);

  const changeVenue = (venue_id: number) => {
    dispatch(
      updateEventVenue({
        event_id: +id,
        id: venue_id
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        type === 'edit'
          ? navigate(`${Path.EventCreatorEdit}/${eventId}`)
          : navigate(`${Path.EventsChangeNumber}/${eventId}?type=create`);
      }
    });
  };

  const changeVenuePS = (name: string) => {
    dispatch(
      getVenueByPs({
        id: +positionstack_request_id,
        name: name
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        changeVenue(res.payload.id);
      }
    });
  };

  return (
    <PageWrap
      mainClass={styles.relative}
      headerButtonTitle={type === 'create' ? 'New venue +' : ''}
      headerButtonFunction={() => navigate(`${Path.CreateVenue}?isQuick=true&event_id=${eventId}`)}
      title={type === 'create' ? t('VENUECHANGENUMBER.select') : t('VENUEEDIT.change2')}
      goBack={type === 'create' ? Path.CreateEvent : `${Path.EventCreatorEdit}/${eventId}`}
    >
      <div className={styles.root}>
        <p className='mb16'>{t('MEETSOMEONE.specify')}</p>

        <SelectPosition
          mapHeigh={580}
          basicPosition={
            event
              ? {
                  lat: event.latitude,
                  lng: event.longitude
                }
              : null
          }
          setPosition={setPosition}
        />
      </div>

      {our_venues.length === 0 && positionstack_venues.length === 0 && positionstack_request_id !== '' && (
        <div className={styles.container2}>
          <p className={styles.sorry}>{t('VENUECHANGENUMBER.sorry')}</p>
        </div>
      )}

      {(our_venues.length !== 0 || positionstack_venues.length !== 0) && (
        <div
          style={{
            height: showFull ? '80vh' : 'auto'
          }}
          className={styles.container}
        >
          <div className={`${styles.list} ${showFull ? styles.full : styles.short}`}>
            <button onClick={() => setShowFull(!showFull)} className={styles.change}></button>
            {our_venues.length > 0 &&
              our_venues.map((elem: {id: number; name: string}, idx: number) => {
                return (
                  <button
                    className={`${styles.item} ${idx === 3 && !showFull && styles.opacity}`}
                    onClick={() => (idx === 3 && !showFull ? setShowFull(!showFull) : changeVenue(+elem.id))}
                    key={idx}
                  >
                    <Location />
                    <p>{elem.name}</p>
                    <div>
                      <Arrow />
                    </div>
                  </button>
                );
              })}
            {positionstack_venues.length > 0 &&
              positionstack_venues.map((elem: {id: number; name: string}, idx: number) => {
                return (
                  <button
                    key={idx}
                    className={`${styles.item} ${our_venues.length + idx === 3 && !showFull && styles.opacity}`}
                    onClick={() =>
                      our_venues.length + idx === 3 && !showFull ? setShowFull(!showFull) : changeVenuePS(elem.name)
                    }
                  >
                    <Location />
                    <p>{elem.name}</p>
                    <div>
                      <Arrow />
                    </div>
                  </button>
                );
              })}

            {/* <div className={styles.blur}></div> */}
          </div>
        </div>
      )}
    </PageWrap>
  );
};

export default EventLocation;
