import axios from 'axios';
import {toast} from 'react-toastify';

const toastError = () => toast.error('Something went wrong');

export const updateEvent = (
  id: string | number,
  data: object,
  setLoading: any,
  setUpdatedData: any,
  redirect?: any
) => {
  setLoading(true);
  return axios
    .patch(`/creator/my/events/${id}/`, data)
    .then((res) => {
      if (res) {
        setUpdatedData(res?.data);
        redirect && redirect();
        toast.success('Successfully saved');
      } else {
        toastError();
      }
    })
    .catch((error) => toast.error(error?.message))
    .finally(() => setLoading(false));
};

export const getEventImages = (id: string | number, setter: any) => {
  return axios
    .get(`/creator/my/events/${id}/images/`)
    .then((res) => (res ? setter(res?.data) : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const postEventImage = (id: string | number, data: object) => {
  return axios
    .post(`/creator/my/events/${id}/images/`, data)
    .then((res) => (res ? toast.success(res?.data?.detail) : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const deleteEventImage = (id: string | number, imageId: string) => {
  return axios
    .delete(`/creator/my/events/${id}/images/${imageId}/`)
    .then((res) => (res ? toast.success('Successfully deleted') : toastError()))
    .catch((error) => toast.error(error?.message));
};

export const updateEventVenue = (id: string | undefined) => {
  if (id) {
    return axios
      .post(`/creator/my/events/${id}/venue/`, {})
      .then((res) => (res?.data?.detail === 'success' ? window.Telegram.WebApp.close() : toastError()))
      .catch((error) => toast.error(error?.message));
  }
};

