import {useEffect, useState} from 'react';
import {useNavigate, useParams, useLocation} from 'react-router-dom';
import {useAppSelector} from '../../../app/hooks';
import {A11y} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {API_BASE_URL, Path} from '../../../app/const';
import {selectProfileType} from '../../../app/profileSlice';
import {getVenue, getVenueByUser} from '../venuesAPI';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import placeholderImg from '../../../assets/icons/placeholder.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as LocationIcon} from '../../../assets/icons/location.svg';
import 'swiper/css';
import styles from './VenueDetails.module.scss';
import {useTranslation} from 'react-i18next';

function VenueDetailsSlider({slides}: any) {
  return (
    <Swiper className={styles.slider} modules={[A11y]} spaceBetween={8}>
      {slides?.length ? (
        slides.map((image: any, index: number) => (
          <SwiperSlide key={index}>
            <img
              className={styles.img}
              src={image ? `${API_BASE_URL}${image?.image ? image?.image : image}` : ''}
              alt='Venue'
            />
          </SwiperSlide>
        ))
      ) : (
        <img className={styles.img} src={placeholderImg} alt='Placeholder' />
      )}
    </Swiper>
  );
}

export default function VenueDetails() {
  const {venueId} = useParams();
  const profile_type = useAppSelector(selectProfileType);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [venue, setVenue] = useState({
    pictures: [],
    images: [],
    name: '',
    address: '',
    description: '',
    qr_code_amount: 0
  });
  const {pictures, images, name, address, description} = venue;
  const {state} = useLocation();

  useEffect(() => {
    if (!state && venueId) {
      if (profile_type === 'default') getVenueByUser(venueId, setVenue);
      if (profile_type === 'creator') getVenue(venueId, setVenue);
    }
  }, [profile_type]);

  return (
    <PageWrap
      mainClass={styles.root}
      title={t('VENUEDETAILS.venue')}
      goBack={profile_type === 'default' ? -1 : -1}
      icon={profile_type === 'creator' && <EditIcon />}
      headerButtonFunction={() => navigate(`${Path.VenueEdit}/${venueId}`)}
    >
      {!state && <VenueDetailsSlider slides={pictures || images} />}
      {state && <VenueDetailsSlider slides={[state.picture]} />}

      <span className='title truncated mb4'>{name ? name : state?.name}</span>
      <div className={styles.location}>
        <LocationIcon /> <span className='truncated details'>{address ? address : state?.address}</span>
      </div>
      <p>{description ? description : state?.description}</p>

      {profile_type === 'creator' && (
        <Button className={styles.removeBtn} outlined to={`${Path.VenueRemove}/${venueId ? venueId : state?.id}`}>
          {t('VENUEDETAILS.remove')}
        </Button>
      )}
    </PageWrap>
  );
}
