import {useNavigate} from 'react-router-dom';
import Header from '../Header';
import PageHeader from '../PageHeader';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import styles from './PageWrap.module.scss';
import {useEffect} from 'react';
import {Path} from '../../app/const';

interface PageWrapProps {
  children: any;
  header?: boolean;
  mainClass?: string;
  isProtected?: boolean;
  title?: string | null;
  onClick?: (() => any) | any;
  stateClear?: (() => any) | any;
  headerButtonTitle?: string | boolean;
  icon?: any;
  headerButtonFunction?: (() => any) | any;
  goBack?: any;
  showArrow?: boolean;
}

export default function PageWrap({
  children,
  header,
  mainClass = '',
  title,
  stateClear,
  headerButtonTitle,
  headerButtonFunction,
  icon,
  onClick,
  isProtected = true,
  showArrow = true,
  goBack
}: PageWrapProps) {
  const navigate = useNavigate();
  //Protected Route Action. Chech if user has token and Route protected
  useEffect(() => {
    !localStorage.hash && isProtected && navigate(Path.ChooseLanguage);
    localStorage.hash && !isProtected && navigate(Path.Root);
  }, []);

  return (
    <>
      <PageHeader />
      <div className='app'>
        {header && <Header />}
        {title && (
          <header className={styles.header}>
            {showArrow && (
              <button
                className={`${styles.back} hover`}
                type='button'
                aria-label='Back button'
                onClick={
                  onClick
                    ? onClick
                    : () => {
                        navigate(goBack ? goBack : '/');
                        stateClear && stateClear();
                      }
                }
              >
                <ArrowIcon className={styles.arrow} />
              </button>
            )}
            <h1 className='truncated'>{title}</h1>
            {(headerButtonTitle || icon) && (
              <button className={styles.headerBtn} type='button' onClick={headerButtonFunction}>
                {headerButtonTitle && <span>{headerButtonTitle}</span>}
                {icon}
              </button>
            )}
          </header>
        )}
        <main className={`${styles.main}${mainClass && ` ${mainClass}`}`}>
          {children}
          <div className='bottom-box'></div>
        </main>
      </div>
    </>
  );
}
