import {useParams} from 'react-router-dom';
import {interestedEvents} from '../../../app/const';
import PageWrap from '../../../layout/PageWrap';
import EventCard from '../../../shared/EventCard';
import styles from './EventDetails.module.scss';
import { useTranslation } from 'react-i18next';

export default function EventDetails() {
  const {eventId} = useParams();
  const {t} = useTranslation();
  return (
    <PageWrap goBack={'/attend-the-event'} mainClass={styles.root} title={t("EVENTDETAILS.event")}>
      <EventCard id={eventId} {...interestedEvents[Number(eventId)]} />
    </PageWrap>
  );
}
