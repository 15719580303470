import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { API_BASE_URL } from '../../app/const';



export interface venueProps {
    id: number,
    name: string,
    description: string,
    longitude: number,
    latitude: number,

}

export interface venueImageProps {
    id: number,
    image: string
}

export interface venueInterface {
    loading: string;
    error: string | undefined;
    venue: venueProps,
    venueImages: venueImageProps[] | [],
    createForm: { name: string, description: string }

}



export const initialState: venueInterface = {
    loading: '',
    error: '',
    venue: {
        id: 0,
        name: "",
        description: "",
        longitude: 0,
        latitude: 0
    },
    createForm: { name: "", description: "" },
    venueImages: []

};

export const createVenue = createAsyncThunk('CREATE_VENUE', async (body: {
    name: string | null,
    description: string | null,
    longitude: number,
    latitude: number
}) => {
    const response = await axios.post(`${API_BASE_URL}/creator/my/create-venue/`, body);
    return response.data;
});


export const updateVenueLocation = createAsyncThunk('UPDATE_VENUE_LOCATION', async (body: {
    id: number,
    location: {
        longitude: number,
        latitude: number
    }
}) => {
    const response = await axios.post(`${API_BASE_URL}/creator/my/venues/${body.id}/location/`, body.location);
    return response.data;
});


export const verifyVenue = createAsyncThunk('VERIFY_VENUE', async (data: {
    id: number,
    body: {
        code: number,
        phone_number: string
    }
}) => {
    const response = await axios.post(`${API_BASE_URL}/creator/my/venues/${data.id}/verify-code/`, data.body);
    return response.data;
});

export const postVenueImage = createAsyncThunk('VENUE_ADD_PHOTO', async (data: {
    id: number,
    body: any
}
) => {
    const response = await axios.post(`${API_BASE_URL}/creator/my/venues/${data.id}/images/`, data.body);
    return response.data;
});


export const getVenueImage = createAsyncThunk('VENUE_GET_PHOTO', async (data: {
    id: number,
}
) => {
    const response = await axios.get(`${API_BASE_URL}/creator/my/venues/${data.id}/images/`,);
    return response.data;
});

export const getVenue = createAsyncThunk('GET_VENUE', async (
    id: number,

) => {
    const response = await axios.get(`${API_BASE_URL}/creator/my/venues/${id}/`,);
    return response.data;
});


export const deleteVenueImage = createAsyncThunk('VENUE_DELETE_PHOTO', async (data: {
    id: number,
    image_id: number
}
) => {
    const response = await axios.delete(`${API_BASE_URL}/creator/my/venues/${data.id}/images/${data.image_id}`,);
    return response.data;
});







const venueSlice = createSlice({
    name: 'venue',
    initialState,
    reducers: {
        createVenueForm(state: any, action: PayloadAction<{ name: string, description: string }>) {
            state.createForm = action.payload;
        },
    },
    extraReducers(builder) {
        builder

            //CREATE VENUE
            .addCase(createVenue.pending, (state, action) => {
                state.loading = 'loading';
            })
            .addCase(createVenue.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.venue = action.payload
            })
            .addCase(createVenue.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })

            //GET VENUE

            .addCase(getVenue.pending, (state, action) => {
                state.loading = 'loading';
            })
            .addCase(getVenue.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.venue = action.payload
            })
            .addCase(getVenue.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })

            //GET IMAGES

            .addCase(getVenueImage.pending, (state, action) => {
                state.loading = 'loading';
            })
            .addCase(getVenueImage.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.venueImages = action.payload
            })
            .addCase(getVenueImage.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })





    }
});

export const selectVenues = (state: RootState) => state.venue;
export const { createVenueForm } = venueSlice.actions

export default venueSlice.reducer;