import balloonsIcon from '../assets/icons/balloons.svg';
import ribbonsIcon from '../assets/icons/ribbons.svg';
import starsIcon from '../assets/icons/stars.svg';
import venuesIcon from '../assets/icons/venues.svg';
import photoImg from '../assets/images/profile-preview.jpg';
import enIcon from '../assets/icons/en.png';
import uaIcon from '../assets/icons/ua.png';
import ruIcon from '../assets/icons/ru.png';
import eeIcon from '../assets/icons/ee.png';
import deIcon from '../assets/icons/de.png';
import profileImg from '../assets/images/profile.png';
import profileImg2 from '../assets/images/profile-2.png';
import profileImg3 from '../assets/images/profile-3.png';
import eventImg from '../assets/images/event.jpg';
import eventImg2 from '../assets/images/event-2.jpg';
import eventImg3 from '../assets/images/event-3.jpg';
import { differenceInYears, parse } from 'date-fns';
import React, { useState, useEffect, CSSProperties } from 'react';
import { createBrowserHistory } from 'history'

declare global {
  interface Window {
    google: {
      maps: any
    },
    Telegram: {
      WebApp: {
        close: () => void;
        expand: () => void;
      };
    };
  }
}



let BASE_URL, BASE_URL_WS, GOOGLEMAPS_API_KEY;

if (
  window.location.host !== "cherrybot.app"
) {
  BASE_URL = "https://api.cherry.4-com.pro";
  BASE_URL_WS = "wss://api.cherry.4-com.pro/ws";
}
else {
  BASE_URL = "https://api.cherrybot.app";
  BASE_URL_WS = "wss://api.cherrybot.app/ws";
}

export const GOOGLE_MAP_KEY = 'AIzaSyB-muOIQoIRKh623o63yCbnsPplVtjRGuA'



export const API_BASE_URL = BASE_URL;
export const API_BASE_URL_WS = BASE_URL_WS;



export enum Path {
  Root = '/',
  ChooseLanguage = "/choose-language",
  SignUp = "/sign-up",
  SignIn = "/sign-in",

  //PROFILE LINKS//

  Profile = '/profile',
  ChangeProfilePhoto = '/profile/change-profile-photo',
  EditPersonalInformation = '/profile/edit-personal-information',
  EditPhoneNumber = '/profile/edit-phone-number',
  CreatePersonalInformation = '/profile/create-profile',
  DeleteAccount = "/profile/delete-profile",
  ProfilePreview = '/profile/profile-preview',
  BotLanguage = '/profile/bot-language',
  SubscriptionDetails = '/subscription-details',


  //MEET SOMEONE//

  MeetSomeone = '/meet-someone',
  MeetSomeoneLocation = '/meet-someone/location',
  SearchResults = '/meet-someone/search-results',
  SearchResultsEmpty = '/meet-someone/empty-results',

  //ATTEND THE EVENT//
  AttendTheEvent = '/attend-the-event',
  AttendTheEventChangeParams = '/attend-the-event/change-params',
  AttendeesInterestedEvents = '/attend-the-event/attendees-and-interested',
  EventDetails = '/attend-the-event/event-details',
  AttendingPeople = '/attend-the-event/attending-people',
  AttendingPeopleLocation = '/attend-the-event/location',
  AttendingPeopleResults = '/attend-the-event/attending-people-results',
  MatchingResultsEvent = '/attend-the-event/matching-results',
  OtherResults = '/attend-the-event/other-results',
  VenueProfile = '/attend-the-event/venue-profile',
  InterestedPeople = '/attend-the-event/interested-people',
  InterestedPeopleResults = '/attend-the-event/interested-people-results',
  InterestedPeopleResultsCreator = '/attend-the-event/interested-people-results',
  AttendTheEventEmptyResults = "/attend-the-event/search-results",


  //VENUE CREATOR //
  VenuesCreator = '/venues-creator',
  VenueDetails = '/venues-creator/venue-details',
  VenueEdit = '/venues-creator/venue-edit',
  VenueEditLocation = '/venues-creator/edit-location',
  VenueSetLocation = '/venues-creator/set-location',
  VenueEnterNumber = '/venues-creator/venue-enter-number',
  VenueAddPhotos = '/venues-creator/add-photos',
  VenueChangeNumber = '/venues-creator/venue-change-number',
  CreateVenue = '/venues-creator/create-venue',
  VenuePhotos = '/venues-creator/venue-photos',
  VenueRemove = '/venues-creator/venue-remove',

  //EVENT CREATOR//

  EventCreator = '/event-creator',
  CreateEvent = '/event-creator/create-event',
  EventCreatorDetails = '/event-creator/event-details',
  EventAttendedPeople = "/event-creator/attended-and-interested",
  EventCreatorEdit = '/event-creator/event-edit',
  EventCreatorVenueProfile = '/event-creator/venue-profile',
  EventsChangeNumber = '/event-creator/event-change-number',
  EventsPhotos = '/event-creator/event-photos',
  EventClose = '/event-creator/cancel-event',
  DeleteClose = '/event-creator/delete-event',
  EventsRemove = '/event-creator/event-remove',
  EventEditLocation = '/event-creator/edit-location',
  EventCreateLocation = "/event-creator/set-location",

  //BROWSE INTERESTS//
  BrowseInterests = '/browse-interests',
  InterestSearchResults = '/browse-interests/search-results',
  BrowseInterestsLocation = '/browse-interests/location',
  BrowseInterestsEmptyResults = '/browse-interests/search-results',
  MatchingResultsInterest = '/browse-interests/matching-results',


  //CHAT//
  Chats = '/chats',
  UserProfile = '/chats/user-profile',


  CreatorMode = '/creator-mode',




}

export const Headers = {
  'Content-Type': 'application/json',
  Authorization: `Hash ${localStorage.getItem('hash')}`
};
export const HeadersFormData = {
  'Content-Type': 'multipart/form-data; boundary=something',
  Authorization: `Hash ${localStorage.getItem('hash')}`
};

export const calculateAge = (dob: any): number => {
  const date = parse(dob, 'yyyy-mm-dd', new Date());
  const age = differenceInYears(new Date(), date);
  return age;
};


//LIST OF LINKS WHHERE LOADER SHOULDN'T BE SHOWN

export const exceptLinks = [
  `${API_BASE_URL}/subscriptions/buy/`,
  `${API_BASE_URL}/subscriptions/change-location/`,
  `${API_BASE_URL}/creator/public/closest-venues/`,
  `${API_BASE_URL}/searches/browse/`,
  `${API_BASE_URL}/searches/default/`,
  `${API_BASE_URL}/creator/my/venues/`,
  `${API_BASE_URL}/profiles/my/`,
  `${API_BASE_URL}/users/send-code/`,

  // `${API_BASE_URL}/chat/room/`,
  // `${API_BASE_URL}/profiles/interests/?search`,
];




export function useDebounce(value: any, delay = 300) {
  const [debaunced, setDebounced] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounced(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debaunced;
}

export const scrollToElement = (elementRef: any) => {
  elementRef.current?.scrollIntoView({ behavior: 'smooth' });

};

export const history = createBrowserHistory()


export const API_WS_URL = 'wss://api.cherry.4-com.pro/ws';

export const userLocation = 'Viru väljak 4, Tallinn, Estonia.';

export const mainMenuNav = [
  {
    link: Path.MeetSomeone,
    icon: balloonsIcon,
    label: 'Meet someone',
    caption: 'You can find users and be visible for others in specified location, while your subscription is active.'
  },
  {
    link: Path.AttendTheEvent,
    icon: ribbonsIcon,
    label: 'Attend the event',
    caption:
      "See what's exciting today in your area. Don't waste the time of your subscription, if you want to meet someone."
  },

  {
    link: Path.BrowseInterests,
    icon: starsIcon,
    label: 'Browse interests',
    caption:
      'Looking for like-minded people? See what users in your area are interested in and start chatting right now!'
  }
];

export const mainMenuNavCreator = [
  {
    link: Path.EventCreator,
    icon: ribbonsIcon,
    label: 'Events',
    caption:
      "Manage your upcoming events or create something interesting."
  },
  {
    link: Path.VenuesCreator,
    icon: venuesIcon,
    label: 'Venues',
    caption:
      'Want to make your establishment popular? We can help you attract people.'
  }

];


export const profilePreview = {
  img: photoImg,
  name: 'Alexander',
  gender: 'male',
  city: 'Tallinn',
  age: 29,
  tagList: ['Music', 'Photography', 'Traveling'],
  bio: 'I’m a single man looking for adventure and new acquaintances!'
};

export const langList = [
  { value: 'en', icon: enIcon, label: 'English' },
  { value: 'ua', icon: uaIcon, label: 'Українська' },
  { value: 'ru', icon: ruIcon, label: 'Русский' },
  { value: 'ee', icon: eeIcon, label: 'Eestlane' },
  { value: 'de', icon: deIcon, label: 'Deutsch' }
];
export const langListSignUP = [
  { value: 'en', icon: enIcon, label: 'Continue in English' },
  { value: 'ua', icon: uaIcon, label: 'Продовжити українською' },
  { value: 'ru', icon: ruIcon, label: 'Продолжить на русском' },
  { value: 'ee', icon: eeIcon, label: 'Jätkake eesti keeles' },
  { value: 'de', icon: deIcon, label: 'Weiter auf Deutsch' }
];

export const searchResults = [
  {
    img: profileImg,
    name: 'Maria',
    gender: 'female',
    city: 'Tallinn',
    age: 25,
    tagList: ['Music', 'Photography', 'Sport'],
    bio: 'Take photos, play and sing. There is nothing more I can do ... but if you ask very well ...;)'
  },
  {
    img: profileImg2,
    name: 'Sasha',
    gender: 'female',
    city: 'Tallinn',
    age: 27,
    tagList: ['Art', 'Traveling'],
    bio: 'I want to find someone with whom I will conquer this world :)'
  },
  {
    img: profileImg3,
    name: 'Kseniya',
    gender: 'female',
    city: 'Tallinn',
    age: 23,
    tagList: ['Art', 'Music', 'Sport', 'Technology']
  }
];

export const radiusOptions = [
  ['custom', 'Custom'],
  ['1', '1 km'],
  ['5', '5 km'],
  ['10', '10 km']
];

export const proposedInterests = [
  'Architecture',
  'Art',
  'Technology',
  'Sport',
  'Nature',
  'Movie',
  'Drink',
  'Business',
  'Work',
  'Animals',
  'Food',
  'Health',
  'History',
  'Books',
  'Fashion',
  'Games',
  'Camping'
];

export const searchInterests = {
  wanted: [
    { name: 'Music', number: 6 },
    { name: 'Photography', number: 4 },
    { name: 'Traveling', number: 3 }
  ],
  others: [
    { name: 'Drink', number: 10 },
    { name: 'Movie', number: 7 },
    { name: 'Games', number: 6 },
    { name: 'Books', number: 4 },
    { name: 'Food', number: 4 },
    { name: 'Sport', number: 3 },
    { name: 'Camping', number: 1 }
  ]
};

export const interestedEvents = [
  {
    img: eventImg,
    name: 'Music Disco Party',
    venue: 'Club Münt',
    time: 'Ongoing',
    people: 46,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    tagList: ['Music', 'Dancing', 'Party'],
    marked: true
  },
  {
    img: eventImg2,
    name: 'Disco 90s',
    venue: 'Club Studio',
    time: 'Ongoing',
    people: 46,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    tagList: ['Music', 'Dancing', 'Party'],
    marked: true
  },
  {
    img: eventImg3,
    name: 'Tequila Friday',
    venue: 'Club Hollywood',
    time: 'Fri, 23 Jun, 23:00',
    people: 46,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    tagList: ['Music', 'Dancing', 'Party'],
    marked: true
  }
];

export const matchingResults = [
  {
    img: eventImg,
    name: 'Music Disco Party',
    venue: 'Club Münt',
    time: 'Fri, 23 Jun, 22:00',
    people: 22,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    tagList: ['Music', 'Dancing', 'Party']
  },
  {
    img: eventImg2,
    name: 'Disco 90s',
    venue: 'Club Studio',
    time: 'Fri, 23 Jun, 22:00',
    people: 15,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    tagList: ['Music', 'Dancing', 'Party']
  },
  {
    img: eventImg3,
    name: 'Tequila Friday',
    venue: 'Club Hollywood',
    time: 'Fri, 23 Jun, 23:00',
    people: 11,
    desc:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    tagList: ['Music', 'Dancing', 'Drink']
  }
];

export const venueProfile = {
  images: [eventImg, eventImg, eventImg],
  name: 'Club Münt',
  rating: 3.3,
  desc:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
    ' dolore magna aliqua.',
  location: 'Müürivahe Tänav 22, Tallinn, Estonia'
};

export const chats = [
  {
    img: profileImg,
    name: 'Maria',
    approved: true,
    time: '9:30',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    newMessages: 2
  },
  {
    img: profileImg2,
    name: 'Sasha',
    approved: true,
    time: '7:52',
    message: 'Lorem ipsum dolor sit amet',
    newMessages: 2
  },
  {
    img: profileImg3,
    name: 'Kseniya',
    approved: false,
    time: 'Mon',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    status: 'send'
  },
  {
    img: profileImg,
    name: 'Viktoria',
    approved: false,
    time: 'Sun',
    message:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et' +
      ' dolore magna aliqua.',
    status: 'read'
  }
];



export const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red'
};