import React from 'react';
import PageWrap from '../../../layout/PageWrap';
import styles from './CancelDeleteEvent.module.scss';
import Button from '../../../shared/Button';
import {Path} from '../../../app/const';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import image from './image/cancel.png';
import {useAppDispatch} from '../../../app/hooks';
import {deleteEvent, cancelEvent} from '../../AttendTheEvent/eventsSlice';

interface CancelDeleteEventProps {
  type: 'delete' | 'cancel';
}

const CancelDeleteEvent = ({type}: CancelDeleteEventProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {eventId} = useParams();

  const deleteEventFunc = () => {
    eventId &&
      dispatch(deleteEvent(+eventId)).then((res: any) => {
        if (res.type && res.type.includes('fulfilled')) {
          navigate(`${Path.EventCreator}/`);
        }
      });
  };

  const cancelEventFunc = () => {
    eventId &&
      dispatch(cancelEvent(+eventId)).then((res: any) => {
        if (res.type && res.type.includes('fulfilled')) {
          navigate(`${Path.EventCreatorDetails}/${eventId}`);
        }
      });
  };

  return (
    <PageWrap
      goBack={`${Path.EventCreatorDetails}/${eventId}`}
      title={type === 'delete' ? t('EVENTCARD.delete') : t('EVENTCARD.cancel')}
      mainClass={styles.root}
    >
      <img className='mb16' src={image} alt='cancel/delete' />
      <p>{type !== 'delete' ? t('EVENTCARD.text') : t('EVENTCARD.text1')}</p>
      <Button onClick={type !== 'delete' ? cancelEventFunc : deleteEventFunc} className={styles.btn}>
        {type !== 'delete' ? t('EVENTCARD.yes') : t('EVENTCARD.yes1')}
      </Button>
      <Button outlined className='mt16'>
        {t('EVENTCARD.no')}
      </Button>
    </PageWrap>
  );
};

export default CancelDeleteEvent;
