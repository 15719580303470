import {useEffect, useRef, useState} from 'react';
import {proposedInterests} from '../../app/const';
import {ReactComponent as SearchIcon} from '../../assets/icons/search.svg';
import styles from './WantedInterests.module.scss';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import {getInterests, setInterestsParams} from '../../features/AttendTheEvent/eventsSlice';
import {selectHash} from '../../app/profileSlice';
import {useDebounce} from '../../app/const';
import {useTranslation} from 'react-i18next';

interface WantedInterestsProps {
  options?: string[];
  setOptions?: (strings: string[]) => any;
}

export default function WantedInterests({options, setOptions}: WantedInterestsProps) {
  const [selected, setSelected] = useState<any>(options || []);

  const [proposed, setProposed] = useState(proposedInterests as string[]);
  const [searchQuerry, setSearchQuerry] = useState('');
  const dispatch = useAppDispatch();
  const debounced = useDebounce(searchQuerry);

  const hash = useAppSelector(selectHash);
  // const interests = useAppSelector(interestsList);
  // const state = useAppSelector((state) => state);

  const removeSelected = (value: any) => () => {
    setProposed([...proposed, value]);
    setSelected(selected.filter((item: any) => item !== value));
  };

  const selectProposed = (value: any) => {
    setSelected([...selected, value]);
    setProposed(proposed.filter((item) => item !== value));
  };

  useEffect(() => {
    setProposed(proposed.filter((item) => !selected.includes(item))); // remove duplicates
  }, []);

  useEffect(() => {
    setOptions && setOptions(selected);
    dispatch(setInterestsParams(selected));
  }, [selected]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      if (event.target.value) {
        selectProposed({id: Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000, name: event.target.value});
        input.current.value = '';
      }
    }
  };

  useEffect(() => {
    if (searchQuerry.includes(',')) {
      selectProposed({id: Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000, name: searchQuerry.replace(',', '')});
      input.current.value = '';
    }
  }, [searchQuerry]);

  useEffect(() => {
    hash && dispatch(getInterests(searchQuerry));
  }, [debounced]);

  let input = useRef<any>();
  const {t} = useTranslation();

  return (
    <>
      {/* <p className='mt8 mb24'>
        Choose at least one interest for which you want to find people and for which they can find you.
      </p> */}

      {selected?.length > 0 && (
        <>
          {/* <span className={styles.label}>Selected</span> */}
          <div className={`${styles.list} mb32`}>
            {selected.map((item: any) => (
              <button key={item?.id} className={`${styles.tag} selected`} type='button' onClick={removeSelected(item)}>
                {item?.name}
              </button>
            ))}
          </div>
        </>
      )}

      <label className={`${styles.search} inputWrap mb4`}>
        <SearchIcon className='innerLabel' />

        <input
          type='search'
          onChange={(e) => {
            setSearchQuerry(e.target.value);
          }}
          ref={input}
          onKeyDown={handleKeyDown}
          placeholder={t('WANTEDINTERESTS.search')}
        />
      </label>
      {/* {interests?.length === 0 && (
        <p className='caption red mb8'>
          There is no match <b>"{searchQuerry}"</b>
        </p>
      )} */}

      {/* {interests?.length > 0 && (
        <span className='caption mb8'>      
          {t("WANTEDINTERESTS.these")}
        </span>
      )} */}

      {/* {interests && (
        <>
          <div className={styles.list}>
            {interests.map(({id, name}) => (
              <>
                <button
                  key={id}
                  className={
                    selected.findIndex((item: any) => item?.id === id) === -1 ? `${styles.tag}` : `${styles.hide}`
                  }
                  type='button'
                  onClick={() => {
                    selectProposed({id: id, name: name});
                    input.current.value = '';
                  }}
                >
                  {name}
                </button>
              </>
            ))}
          </div>
        </>
      )}
       */}
    </>
  );
}
