import {useState} from 'react';
import PageWrap from '../../../layout/PageWrap';
import styles from './CreateVenue.module.scss';
import {useTranslation} from 'react-i18next';
import Button from '../../../shared/Button';
import {Path} from '../../../app/const';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {createVenueForm, selectVenues} from '../venueSlice';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';

const CreateVenue = () => {
  const {t} = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {createForm} = useAppSelector(selectVenues);
  const [data, setData] = useState({
    name: '',
    description: ''
  });
  const [searchParams] = useSearchParams();
  const isQuick = searchParams.get('isQuick');
  const event_id = searchParams.get('event_id');

  const MoveToSetLocation = () => {
    isQuick
      ? navigate(`${Path.VenueSetLocation}?isQuick=${isQuick}&event_id=${event_id}`, {state: data})
      : navigate(`${Path.VenueSetLocation}`, {state: data});
  };

  const goToMainAndClearState = () => {
    event_id ? navigate(`${Path.EventCreateLocation}/${event_id}`) : navigate(Path.VenuesCreator);
    dispatch(createVenueForm({description: '', name: ''}));
  };

  return (
    <PageWrap onClick={goToMainAndClearState} title={t('VENUECREATE.create')} mainClass='p16'>
      <div className={styles.column}>
        <label className='inputWrap'>
          <span className='inputLabel'>{t('VENUEEDIT.name')}</span>
          <input
            onChange={(e) => {
              setData({...data, name: e?.target?.value});
              dispatch(createVenueForm({...data, name: e?.target?.value}));
            }}
            type='text'
            placeholder={t('VENUECHANGENUMBER.type')}
            defaultValue={createForm.name}
          />
        </label>
        <label className='inputWrap mt16'>
          <span className='inputLabel'>{t('VENUEEDIT.description')}</span>
          <textarea
            onChange={(e) => {
              setData({...data, description: e?.target?.value});
              dispatch(createVenueForm({...data, description: e?.target?.value}));
            }}
            type='text'
            placeholder={t('VENUECHANGENUMBER.type')}
            defaultValue={createForm.description}
          />
        </label>
      </div>
      <Button onClick={MoveToSetLocation} disabled={!data.description || !data.name} className={styles.btn}>
        {t('VENUECHANGENUMBER.continue')}
      </Button>
    </PageWrap>
  );
};

export default CreateVenue;
