import {useRef} from 'react';
import styles from './VenuePhoto.module.scss';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import {Path} from '../../../app/const';
import {useTranslation} from 'react-i18next';
import {ReactComponent as RemoveIcon} from '../../../assets/icons/remove.svg';
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import {postVenueImage, selectVenues, getVenueImage, deleteVenueImage, createVenueForm} from '../venueSlice';
import {updateEventVenue} from '../../AttendTheEvent/eventsSlice';

import {useNavigate, useSearchParams} from 'react-router-dom';

const VenuePhoto = () => {
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {venue, venueImages} = useAppSelector(selectVenues);
  const input = useRef<any>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isQuick = searchParams.get('isQuick') === 'null' ? null : searchParams.get('isQuick');
  const event_id = searchParams.get('event_id');
  const venue_id = searchParams.get('venue_id');

  const formData = new FormData();

  const addImage = (event: any) => {
    let fileItem = event?.target?.files[0];
    if (fileItem) {
      formData.append('image', fileItem);
      dispatch(
        postVenueImage({
          id: venue?.id,

          body: formData
        })
      ).then((res: any) => {
        if (res.type && res.type.includes('fulfilled')) {
          input.current.value = null;
          dispatch(
            getVenueImage({
              id: venue?.id
            })
          );
        }
      });
    }
  };

  const deleteImage = (image_id: number) => {
    dispatch(
      deleteVenueImage({
        //id: venue?.id,
        id: 102,
        image_id: image_id
      })
    ).then((res: any) => {
      if (res.type && res.type.includes('fulfilled')) {
        dispatch(
          getVenueImage({
            id: venue?.id
          })
        );
      }
    });
  };

  const finishCreation = () => {
    navigate(Path.VenuesCreator);
    dispatch(createVenueForm({name: '', description: ''}));
  };

  const continueEventCreation = () => {
    event_id &&
      venue_id &&
      dispatch(
        updateEventVenue({
          event_id: +event_id,
          id: +venue_id
        })
      ).then((res: any) => {
        if (res.type && res.type.includes('fulfilled')) {
          navigate(`${Path.EventsChangeNumber}/${event_id}?type=create`);
        }
      });
  };

  return (
    <PageWrap mainClass={styles.root} goBack={Path.VenueSetLocation} title={t('VENUEEDIT.venue3')}>
      <p className='mb24'>{t('VENUEPHOTOS.attach')}</p>

      <div className={styles.grid}>
        {venueImages?.length
          ? venueImages.map(({id, image}) => (
              <div key={id} className={styles.imgWrap}>
                <img className={styles.img} src={image ? image : ''} alt={t('VENUEPHOTOS.venue1')} />
                <button
                  className={`${styles.imgRemoveBtn} hover`}
                  type='button'
                  aria-label='Remove photo'
                  onClick={() => deleteImage(id)}
                >
                  <RemoveIcon />
                </button>
              </div>
            ))
          : null}

        {venueImages?.length < 5 && (
          <div className={styles.imgWrap}>
            <label className={`${styles.imgAddBtn} hover`} aria-label='Add photo'>
              <input className='visuallyHidden' ref={input} type='file' accept='image/*' onChange={addImage} />

              <AddIcon />
            </label>
          </div>
        )}
      </div>

      <Button onClick={isQuick ? continueEventCreation : finishCreation} className={`${styles.btn} `}>
        {t('NOVENUES.add')}
      </Button>
    </PageWrap>
  );
};

export default VenuePhoto;
