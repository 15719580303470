import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL, HeadersFormData } from './const';
import { RootState } from './store';
import { PersonalInfoInterface, getSmsCodeDTO } from './profileSliceProps';


export const initialState: PersonalInfoInterface = {
  loading: '',
  error: '',
  isBlockShown: false,
  has_exists_account: false,
  hash: null,
  profile_type: 'unset',
  language: 'en',
  profile_form: null,
  code: "",
  personal_info: {
    id: "",
    phone_number: "",
    language: "en",
    type: "unset",
    registered: "",
    web_app_url: "",
    removed: false
  },
  creator: {
    id: null,
    full_name: null,
    picture: null,
    phone_number: null,
    card_number: null,
    is_verified: null,
    ignore_setup: null,
    user: null
  },
  user: {
    is_anonymous: true,
    interests: [],
    profile: {
      id: null,
      first_name: null,
      gender: null,
      birth_date: null,
      bio: null,
      picture: null,
      user: null,
      city: null
    },
    subscription: {
      id: null,
      type: null,
      buy_date: null
    },
    location: { longitude: null, latitude: null }
  }
};


export const getSmsCode = createAsyncThunk('GET_SMS__CODE', async (body: getSmsCodeDTO) => {
  const response = await axios.post(`${API_BASE_URL}/users/send-code/`, body);
  return response.data;
});

export const signUp = createAsyncThunk('SIGN_UP', async (body: {
  code: number | string,
  phone_number: string
}) => {
  const response = await axios.post(`${API_BASE_URL}/users/sign-up/`, body);
  return response.data;
});

export const signIn = createAsyncThunk('SIGN_IN', async (body: {
  code: number | string,
  phone_number: string
}) => {
  const response = await axios.post(`${API_BASE_URL}/users/sign-in/`, body);
  return response.data;
});

export const getPersonalInfo = createAsyncThunk('GET_PERSONAL_INFORMATION', async () => {
  const response = await axios.get(`${API_BASE_URL}/users/info/`);
  return response.data;
});

export const updatePhoneNumber = createAsyncThunk('UPDATE_PHONE_NUMBER', async (body: {
  code: number | string,
  phone_number: string
}) => {
  const response = await axios.post(`${API_BASE_URL}/users/change-phone-number/`, body);
  return response.data;
});

export const updatePhoneCreatorNumber = createAsyncThunk('UPDATE_CREATOR_PHONE_NUMBER', async (body: {
  code: number | string,
  phone_number: string
}) => {
  const response = await axios.post(`${API_BASE_URL}/creator/my/change-number/`, body);
  return response.data;
});

export const fetchPersonalData = createAsyncThunk('GET_PERSONAL_INFO', async (hash?: string) => {
  const response = await axios.get(`${API_BASE_URL}/profiles/my/`);
  return response.data;
});

export const fetchPersonalDataCreator = createAsyncThunk('GET_PERSONAL_CREATOR_INFO', async (hash?: string) => {
  const response = await axios.get(`${API_BASE_URL}/creator/my/`);
  return response.data;
});

export const patchPersonalData = createAsyncThunk('PATCH_PERSONAL_INFO', async (requestData: any) => {
  const response = await axios.patch(`${API_BASE_URL}/profiles/my/`, requestData.body,);
  return response.data;
});

export const patchPersonalDataCreator = createAsyncThunk('PATCH_PERSONAL_CREATOR_INFO', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/creator/my/`, body.profileInfo);
  return response.data;
});


export const patchAvatar = createAsyncThunk('PATCH_AVATAR', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/profiles/my/image/`, body, {
    headers: HeadersFormData
  });
  return response.data;
});

export const patchAvatarCreator = createAsyncThunk('PATCH_AVATAR_CREATOR', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/creator/my/image/`, body, {
    headers: HeadersFormData
  });
  return response.data;
});

export const deleteAccount = createAsyncThunk('DELETE_ACCOUNT', async () => {
  const response = await axios.delete(`${API_BASE_URL}/users/delete/`, {},);
  return response.data;
});

export const switchToCreator = createAsyncThunk('SWITCH_TO_CREATOR', async () => {
  const response = await axios.post(`${API_BASE_URL}/users/switch-to-creator/`, {},);
  return response.data;
});

export const switchToUser = createAsyncThunk('SWITCH_TO_USER', async () => {
  const response = await axios.post(`${API_BASE_URL}/users/switch-to-default/`, {},);
  return response.data;
});

export const getFreeSubscription = createAsyncThunk('GET_FREE_SUBSCRIPTION', async () => {
  const response = await axios.post(`${API_BASE_URL}/profiles/my/subscription/`, {},);
  return response.data;
});


export const changeLocation = createAsyncThunk('CHANGE_LOCATION', async (body: {
  latitude: number,
  longitude: number
}) => {
  const response = await axios.patch(`${API_BASE_URL}/subscriptions/change-location/`, body,);
  return response.data;
});

export const buySubscription = createAsyncThunk('BUY_SUBSCRIPION', async (body: {
  latitude: number,
  longitude: number
}) => {
  const response = await axios.post(`${API_BASE_URL}/subscriptions/buy/`, body,);
  return response.data;
});

export const changeLanguage = createAsyncThunk('CHANGE_LANGUAGE', async (body: any) => {
  const response = await axios.patch(`${API_BASE_URL}/users/user/`, body,);
  return response.data;
});

export const setupPhone = createAsyncThunk('SETUP_PHONE', async () => {
  const response = await axios.post(`${API_BASE_URL}/profiles/my/setup-phone/`, {},);
  return response.data;
});



const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setBaseParams(state: any, action: PayloadAction<any>) {
      state.hash = action.payload.hash;
      state.profile_type = action.payload.profile_type;
      state.language = action.payload.language;
    },
    setBottomBoxForIphone(state: any, action: PayloadAction<boolean>) {
      state.isBlockShown = action.payload;
    },

    setProfileForm(state: any, action: PayloadAction<any>) {
      state.profile_form = action.payload;
    },

    resetState(state: any, action: PayloadAction<any>) {
      state.personal_info = {
        id: "",
        phone_number: "",
        language: "en",
        type: "unset",
        registered: "",
        web_app_url: "",
        removed: false
      };
      state.creator = {
        id: null,
        full_name: null,
        picture: null,
        phone_number: null,
        card_number: null,
        is_verified: null,
        ignore_setup: null,
        user: null
      };
      state.user = {
        is_anonymous: true,
        interests: [],
        profile: {
          id: null,
          first_name: null,
          gender: null,
          birth_date: null,
          bio: null,
          picture: null,
          user: null,
          city: null
        },
        subscription: {
          id: null,
          type: null,
          buy_date: null
        },
        location: { longitude: null, latitude: null }
      }
    }


  },
  extraReducers(builder) {
    builder


      //GET PERSONAL INFO REQUEST
      .addCase(fetchPersonalData.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchPersonalData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.user = action.payload;
      })
      .addCase(fetchPersonalData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //SEND PHONE AND PURPOSE TO GET SMS CODE
      .addCase(getSmsCode.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getSmsCode.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.code = action.payload;
      })
      .addCase(getSmsCode.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //GET PERSONAL INFORMATTION
      .addCase(getPersonalInfo.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getPersonalInfo.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.personal_info = action.payload;
      })
      .addCase(getPersonalInfo.rejected, (state, action) => {
        state.loading = 'failed';
      })

      //SIGN UP WITH CODE AND PHONE NUMBER
      .addCase(signUp.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.hash = action.payload.hash;
        localStorage.setItem("hash", action.payload.hash)
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //SIGN IN WITH CODE AND PHONE NUMBER
      .addCase(signIn.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.hash = action.payload.hash;
        localStorage.setItem("hash", action.payload.hash)
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //SIGN IN WITH CODE AND PHONE NUMBER
      .addCase(updatePhoneNumber.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(updatePhoneNumber.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.hash = action.payload.hash;
        localStorage.setItem("hash", action.payload.hash)
      })
      .addCase(updatePhoneNumber.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //CHANGE PHONE NIMBER CREATOR AT FIRST TIME
      .addCase(updatePhoneCreatorNumber.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(updatePhoneCreatorNumber.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(updatePhoneCreatorNumber.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //PATCH BOT LANGUAGE

      .addCase(changeLanguage.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(changeLanguage.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.language = action.payload.language;

      })
      .addCase(changeLanguage.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET PERSONAL CREATOR INFO REQUEST
      .addCase(fetchPersonalDataCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(fetchPersonalDataCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.creator = action.payload;
      })
      .addCase(fetchPersonalDataCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE PERSONAL INFO REQUEST

      .addCase(patchPersonalData.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchPersonalData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(patchPersonalData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE PERSONAL CREATOR INFO REQUEST

      .addCase(patchPersonalDataCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchPersonalDataCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(patchPersonalDataCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE AVATAR REQUEST

      .addCase(patchAvatar.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchAvatar.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.user.profile.picture = action.payload.image;
      })
      .addCase(patchAvatar.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //UPDATE AVATAR CREATOR REQUEST

      .addCase(patchAvatarCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(patchAvatarCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.creator.picture = `${API_BASE_URL}${action.payload.image}`;
      })
      .addCase(patchAvatarCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //DELETE ACCOUNT REQUEST
      .addCase(deleteAccount.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(deleteAccount.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //SWITCH TO CREATOR 
      .addCase(switchToCreator.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(switchToCreator.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.user = {
          is_anonymous: true,
          interests: [],
          profile: {
            id: null,
            first_name: null,
            gender: null,
            birth_date: null,
            bio: null,
            picture: null,
            user: null,
            city: null
          },
          subscription: {
            id: null,
            type: null,
            buy_date: null
          },
          location: { longitude: null, latitude: null }
        }
      })
      .addCase(switchToCreator.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //SWITCH TO USER

      .addCase(switchToUser.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(switchToUser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(switchToUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET FREE SUBSCRIPTION

      .addCase(getFreeSubscription.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getFreeSubscription.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(getFreeSubscription.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )

      //CHANGE LOCATION

      .addCase(changeLocation.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(changeLocation.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(changeLocation.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )

      //BUY SUBSCRIPTION

      .addCase(buySubscription.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(buySubscription.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(buySubscription.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )




      //SETUP PHONE

      .addCase(setupPhone.pending, (state, action) => {
        state.loading = 'loading';
      }
      )
      .addCase(setupPhone.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      }
      )
      .addCase(setupPhone.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      }
      )






  }
});

export const selectProfileUser = (state: RootState) => state.profileUser.user;
export const selectProfileCreator = (state: RootState) => state.profileUser.creator;
export const selectPersonalInfo = (state: RootState) => state.profileUser.personal_info;
export const selectLoading = (state: RootState) => state.profileUser.loading;
export const selectAllProfileInfo = (state: RootState) => state.profileUser;
export const selectLanguage = (state: RootState) => state.profileUser.personal_info.language;



export const selectHash = (state: RootState) => state.profileUser.hash;
export const selectProfileType = (state: RootState) => state.profileUser.personal_info.type;

export const { setBaseParams, setBottomBoxForIphone, resetState, setProfileForm } = profileSlice.actions

export default profileSlice.reducer;
