import React, {useState} from 'react';
import styles from './SelectButtons.module.scss';

interface SelectButtonsProps {
  options: any;
  onChange: (value: string) => void;
  className?: string;
  title?: string;
  defaultValue?: string;
}

const SelectButtons = ({options, onChange, title, className, defaultValue}: SelectButtonsProps) => {
  const [selected, setSelected] = useState(defaultValue || options[0].value);
  return (
    <div className={`${className} ${styles.container}`}>
      {title && <span className={styles.title}>{title}</span>}
      <div className={styles.buttons}>
        {options.map(({value, id, label}: any) => (
          <button
            className={`${styles.button} ${value === selected && styles.active}`}
            key={id}
            onClick={() => {
              onChange(value);
              setSelected(value);
            }}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectButtons;
