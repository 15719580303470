import React, {useCallback, useEffect, useState, useRef} from 'react';
import styles from './RangeSlider.module.scss';
import {useTranslation} from 'react-i18next';

interface RangeSliderProps {
  min: number;
  max: number;
  setMinAge: (params: number) => any;
  setMaxAge: (params: number) => any;
  onChange?: (value: {min: number; max: number}) => void;
}

const RangeSlider = ({min, max, onChange, setMaxAge, setMinAge}: RangeSliderProps) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(min);
  const maxValRef = useRef(max);
  const range = useRef<HTMLInputElement>(null);
  const {t} = useTranslation();

  // Convert to percentage
  const getPercent = useCallback((value: any) => Math.round(((value - min) / (max - min)) * 100), [min, max]);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal);
    const maxPercent = getPercent(maxValRef.current);

    if (range.current) {
      range.current.style.left = `${minPercent}%`;
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current);
    const maxPercent = getPercent(maxVal);

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`;
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    setMaxAge(maxVal);
    setMinAge(minVal);
  }, [minVal, maxVal, onChange]);

  const zIndexValue = minVal > max - 100 ? '5' : '1';

  return (
    <div className={styles.container}>
      <input
        type='range'
        min={min}
        max={max}
        value={minVal}
        onChange={(event) => {
          const value = Math.min(Number(event.target.value), maxVal - 1);
          setMinVal(value);
          minValRef.current = value;
        }}
        className={`${styles.thumb} ${styles.thumb__left}`}
        style={{zIndex: zIndexValue}}
      />

      <input
        type='range'
        min={min}
        max={max}
        value={maxVal}
        onChange={(event) => {
          const value = Math.max(Number(event.target.value), minVal + 1);
          setMaxVal(value);
          maxValRef.current = value;
        }}
        className={`${styles.thumb} ${styles.thumb__right}`}
      />

      <div className={styles.slider}>
        <div className={styles.slider__track} />
        <div ref={range} className={styles.slider__range} />
        <div className={styles.slider__left_value}>{t('AGERANGE.age')}</div>
        <div className={styles.slider__right_value}>
          {minVal}
          {t('AGERANGE.yo')} - {maxVal}
          {t('AGERANGE.yo')}
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
