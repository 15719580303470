import styles from './FirstStep.module.scss';
import cherry from './image/cherry.png';
import {ReactComponent as Arrow} from './image/arrow.svg';
import {langListSignUP} from '../../../../app/const';
import i18n from 'i18next';
import {useNavigate} from 'react-router-dom';
import {Path} from '../../../../app/const';
interface FirstStepProps {
  nextStep?: (() => any) | any;
}

const FirstStep = ({nextStep}: FirstStepProps) => {
  const navigate = useNavigate();
  const changeLanguage = (value: any) => {
    i18n.changeLanguage(value);
    navigate(Path.SignUp);
  };

  return (
    <div className={styles['root']}>
      <div className={styles['hero']}>
        <h2 className={styles['hero__title']}>
          <img src={cherry} alt='cherry' />
          Welcome to Cherry app!
        </h2>
        <p className={styles['hero__text']}>
          Chat with interesting people or search for events that happening around you.
        </p>
        <p className={styles['hero__text']}>Be ready for “around-you-here-and-now-for-24h”.</p>
      </div>

      <p className={`${styles['hero__text']} mt32`}>
        <b>Let's get started! </b>
        <br />
        First of all, select the app language. You can change it at any time in the profile settings.
      </p>

      <div className='btnGroup mb32 '>
        {langListSignUP.map(({value, icon, label}) => (
          <label key={value} className={`${styles.langBtn} navBtn textInitial`}>
            <input className='visuallyHidden' type='radio' value={value} onClick={(e) => changeLanguage(value)} />
            <img src={icon} alt='Flag' width='24px' height='24px' />
            <span className='label'>{label}</span>
            <Arrow />
          </label>
        ))}
      </div>
    </div>
  );
};

export default FirstStep;
