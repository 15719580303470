import {useState, useRef, useEffect} from 'react';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './EditInfo.module.scss';
import {useAppSelector, useAppDispatch} from '../../../app/hooks';
import {scrollToElement} from '../../../app/const';
import {
  selectProfileUser,
  patchPersonalData,
  patchPersonalDataCreator,
  selectProfileType,
  fetchPersonalData,
  fetchPersonalDataCreator,
  selectProfileCreator,
  selectPersonalInfo,
  patchAvatarCreator,
  setProfileForm,
  selectAllProfileInfo,
  getSmsCode
} from '../../../app/profileSlice';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import anonymous from '../../../assets/images/anonym.png';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow.svg';
import {Path} from '../../../app/const';
import {useNavigate, useSearchParams, useLocation} from 'react-router-dom';

export default function EditInfo() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const navigate = useNavigate();
  const [touched, setTouched] = useState(false);
  const {profile, interests} = useAppSelector(selectProfileUser);

  const creator = useAppSelector(selectProfileCreator);
  const hash = localStorage.getItem('hash');
  const profile_type = useAppSelector(selectProfileType);
  const {profile_form} = useAppSelector(selectAllProfileInfo);

  const personalInfo = useAppSelector(selectPersonalInfo);

  const [profileInfo, setProfileInfo] = useState<any>('');
  let [interestsInfo, setInterestsInfo] = useState<any>([]);
  const {t} = useTranslation();
  const toastText = t('EDITINFO.yourdata');

  const {state} = useLocation();

  // split string into array of interests

  let arrayOfInterests = interestsInfo.toString().split(',');
  let interestsArr: any = [];

  interests &&
    interests.map((el: any) => {
      interestsArr.push(el.name);
    });

  const mainPatchFunction = profile_type === 'default' ? patchPersonalData : patchPersonalDataCreator;
  const mainFetchFunction = profile_type === 'default' ? fetchPersonalData : fetchPersonalDataCreator;

  //Input refs
  const nameRef = useRef<HTMLInputElement>(null);
  const nameRef2 = useRef<HTMLInputElement>(null);
  const bioRef = useRef<HTMLTextAreaElement>(null);
  const interestsRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let interestsArr: any = [];
    interests && interests.map((el: any) => interestsArr.push(el.name));
    setInterestsInfo(interestsArr.toString().split(','));
  }, [interests]);

  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];

  const formData = new FormData();
  const isBioInvalid = profileInfo?.bio?.length > 140;

  const sendFile = (file: any) => {
    let fileItem = file[0];
    if (fileItem) {
      if (validImageTypes.includes(fileItem.type)) {
        formData.append('image', fileItem);
      } else {
        toast.error(toastText);
      }
    }
    dispatch(patchAvatarCreator(formData)).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        dispatch(fetchPersonalData());
      }
    });
  };

  useEffect(() => {
    if (type === 'create') {
      setProfileInfo({...profileInfo, full_name: state?.user_full_name});
      dispatch(setProfileForm({full_name: state?.user_full_name}));
      setTouched(true);
    }
  }, []);

  //It was made foe one case, when user enter validated number CHERRY-239 #14
  useEffect(() => {
    type === 'create' &&
      profile_form &&
      profile_form?.full_name &&
      setProfileInfo({...profileInfo, full_name: profile_form?.full_name});
  }, [profile_form]);

  return (
    <PageWrap
      mainClass={styles.root}
      title={
        profile_type === 'default' ? t('EDITINFO.edit') : type === 'create' ? t('EDITINFO.create') : t('EDITINFO.edit2')
      }
      goBack='/profile'
      stateClear={() => {
        setProfileInfo('');
        setInterestsInfo('');
      }}
    >
      {profile_type === 'creator' && <p className='mb-16'>{t('EDITINFO.personal')}</p>}
      {profile_type === 'creator' && !creator?.full_name && (
        <div className={styles['avatar_block']}>
          <img
            className={styles['avatar_img']}
            src={creator?.picture ? `${creator?.picture}` : anonymous}
            alt='avatar_image'
          />
          <div className={styles['avatar_txts']}>
            <p className={styles['avatar_title']}>{t('EDITINFO.profile')}</p>
            <label className={styles['avatar_input']} aria-label='Add photo'>
              <input
                className='visuallyHidden'
                type='file'
                accept='image/*'
                onChange={(e) => sendFile(e.target?.files)}
              />
              <p>{creator?.picture ? t('EDITINFO.change') : t('EDITINFO.upload')}</p>
            </label>
          </div>
        </div>
      )}
      {profile_type === 'default' ? (
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.name')}</span>

          <input
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, first_name: e?.target?.value});
              setTouched(true);
            }}
            ref={nameRef}
            onFocus={() => scrollToElement(nameRef)}
            defaultValue={profileInfo.first_name ? profileInfo.first_name : profile?.first_name}
            type='text'
            placeholder={t('EDITINFO.your')}
          />
        </label>
      ) : (
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.full')} </span>

          <input
            onChange={(e: any) => {
              setProfileInfo({...profileInfo, full_name: e?.target?.value});
              dispatch(setProfileForm({...profileInfo, full_name: e?.target?.value}));
              setTouched(true);
            }}
            ref={nameRef2}
            onFocus={() => scrollToElement(nameRef2)}
            defaultValue={
              type === 'create'
                ? profile_form?.full_name
                  ? profile_form?.full_name
                  : state?.user_full_name
                : creator?.full_name
            }
            type='text'
            placeholder={t('EDITINFO.your2')}
          />
        </label>
      )}
      {profile_type === 'default' && (
        <label className='inputWrap sex'>
          <span className='innerLabel'>{t('EDITINFO.gender')}</span>
          <select
            onChange={(e) => {
              setProfileInfo({...profileInfo, gender: e.target.value});
              setTouched(true);
            }}
            defaultValue={profileInfo.gender ? profileInfo.gender : profile?.gender}
          >
            <option value='' disabled hidden>
              {t('EDITINFO.not')}
            </option>
            <option value='male'>{t('EDITINFO.man')}</option>
            <option value='female'>{t('EDITINFO.woman')}</option>
          </select>
        </label>
      )}

      {console.log(creator)}

      {profile_type !== 'default' && (
        <label className='inputWrap'>
          <span className='inputLabel'>{t('EDITINFO.phone')}</span>

          <div className={styles.edit_number} onClick={() => navigate(`${Path.EditPhoneNumber}?type=creation`)}>
            <p>
              {personalInfo.phone_number && personalInfo.is_phone_number_verified
                ? `${creator.phone_number ? creator.phone_number : personalInfo.phone_number}`
                : t('SIGNIN.your')}{' '}
            </p>{' '}
            <ArrowIcon />
          </div>
        </label>
      )}
      {profile_type === 'default' && (
        <>
          <label
            className={`inputWrap  label-wrapper ${navigator.platform === 'iPhone' ? ' birth-iphone' : 'birth'} mb-16 ${
              styles.date
            }`}
          >
            <span className='innerLabel'>{t('EDITINFO.date')}</span>
            <input
              type='date'
              style={{color: 'transparent'}}
              onChange={(e: any) => {
                setProfileInfo({...profileInfo, birth_date: e?.target?.value});
                setTouched(true);
              }}
              defaultValue={profileInfo.birth_date ? profileInfo.birth_date : profile?.birth_date}
            />

            <span className='datetime-local'>
              {!!!profileInfo.birth_date && !!!profile?.birth_date
                ? t('EVENTCREATE.format2')
                : profileInfo.birth_date
                ? profileInfo.birth_date
                : profile?.birth_date}
            </span>
          </label>
          <label className='inputWrap'>
            <span className='inputLabel'>{t('EDITINFO.bio')}</span>
            <textarea
              onChange={(e: any) => {
                setProfileInfo({...profileInfo, bio: e?.target?.value});
                setTouched(true);
              }}
              defaultValue={profileInfo.bio ? profileInfo.bio : profile?.bio}
              ref={bioRef}
              onFocus={() => scrollToElement(bioRef)}
              placeholder={t('EDITINFO.a')}
            />
            {isBioInvalid && <span className={styles.error}>{t('EDITINFO.error')}</span>}
          </label>
          <label className='inputWrap'>
            <span className='inputLabel'>{t('EDITINFO.interests')}</span>
            <input
              onChange={(e: any) => {
                setInterestsInfo(e?.target?.value);
                setTouched(true);
              }}
              type='text'
              ref={interestsRef}
              onFocus={() => scrollToElement(interestsRef)}
              defaultValue={interestsArr.join(', ')}
              placeholder={t('EDITINFO.your4')}
            />
          </label>
          <label className='inputWrap'>
            <span className='inputLabel'>{t('EDITINFO.where')}</span>
            <input
              onChange={(e: any) => {
                setProfileInfo({...profileInfo, city: e?.target?.value});
                setTouched(true);
              }}
              ref={locationRef}
              onFocus={() => scrollToElement(locationRef)}
              defaultValue={profileInfo.city ? profileInfo.city : profile?.city}
              type='text'
              placeholder={t('EDITINFO.your5')}
            />
          </label>{' '}
        </>
      )}
      <Button
        className={styles.btn}
        disabled={!touched || isBioInvalid}
        onClick={() => {
          type === 'create' &&
            personalInfo.phone_number &&
            personalInfo.is_phone_number_verified &&
            dispatch(
              getSmsCode({
                phone_number: personalInfo.phone_number,
                purpose: 'creator_profile_creation'
              })
            );
          dispatch(
            mainPatchFunction(
              profile_type === 'default'
                ? {body: {...profileInfo, interests: arrayOfInterests}, hash}
                : {profileInfo, hash}
            )
          ).then((res: any) => {
            if (res.type && res.type.includes('fulfilled')) {
              dispatch(mainFetchFunction());
              type === 'create' && navigate('/');
              type !== 'create' && toast.success(toastText);
            }
          });
        }}
      >
        {t('EDITINFO.save')}
      </Button>
    </PageWrap>
  );
}
