import {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {Path} from '../../app/const';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {getEventsList, selectEventsAllInfo, selectedInterest, selectLoading, attendedEvents} from './eventsSlice';
import PageWrap from '../../layout/PageWrap';
import Button from '../../shared/Button';
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {ReactComponent as Ongoing} from '../../assets/icons/ongoing.svg';
import {ReactComponent as Calendar} from '../../assets/icons/calendar.svg';
import {ReactComponent as Location} from '../../assets/icons/location_icon.svg';
import styles from './AttendTheEvent.module.scss';
import {format} from 'date-fns';
import {API_BASE_URL} from '../../app/const';
import defaultImage from '../../../src/assets/images/anonym.png';
import SkeletonSquare from '../../shared/SkeletonSquare';
import {useTranslation} from 'react-i18next';
import {ru, enUS, de} from 'date-fns/locale';
import {selectLanguage} from '../../app/profileSlice';
import {utcToZonedTime} from 'date-fns-tz';

export default function AttendTheEvent() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const attendedList = useAppSelector(attendedEvents);
  const loading = useAppSelector(selectLoading);
  const {isAttendedEventsRequestUsed} = useAppSelector(selectEventsAllInfo);
  const {t} = useTranslation();
  const language = useAppSelector(selectLanguage);
  // const baseLanguage = language === ('ua' || 'ru') ? ru : enUS;

  let baseLanguage = enUS;

  switch (language) {
    case 'ua':
    case 'ru':
      baseLanguage = ru;
      break;
    case 'ee':
      baseLanguage = enUS;
      break;
    case 'de':
      baseLanguage = de;
      break;
    default:
      baseLanguage = enUS;
  }

  useEffect(() => {
    dispatch(getEventsList());
  }, []);

  useEffect(() => {
    attendedList.attended_events.length === 0 &&
      attendedList.interested_events.length === 0 &&
      isAttendedEventsRequestUsed &&
      navigate(Path.AttendTheEventChangeParams);
  }, [isAttendedEventsRequestUsed]);

  const selectedInterests = useAppSelector(selectedInterest);
  const interestsNames: any = [];

  selectedInterests.map((interest: any) => {
    interestsNames.push(interest.name);
  });

  return (
    <PageWrap mainClass={styles.root} title={t('ATTENDTHEEVENT.attend')} onClick={() => navigate(Path.Root)}>
      <>
        {loading !== 'succeeded' && [...Array(3)].map((element, index) => <SkeletonSquare key={index} />)}
        {attendedList?.attended_events.length > 0 && (
          <>
            <p className={`mt8 mb16 ${styles.subtitle}`}>{t('ATTENDTHEEVENT.attended')}</p>
            {attendedList &&
              attendedList.attended_events.map(({picture, id, name, venue, start_date, status}, index) => (
                <Link key={index} className={styles.card} to={`${Path.EventDetails}/${id}`}>
                  <img
                    className={styles.cardImg}
                    src={picture ? (picture.includes('https:') ? picture : `${API_BASE_URL}${picture}`) : defaultImage}
                    width='64px'
                    height='64px'
                    alt='Event'
                  />

                  <div className={styles.cardCol}>
                    <h2 className={`${styles.cardTitle} truncated`}>{name}</h2>

                    <div className={`${styles.cardRow} truncated`}>
                      <div className={styles.cardText}>
                        <Location />
                        <Link to={`${Path.VenueDetails}/${venue.id}`}>
                          <span className={styles.cardVenue}>
                            <b>{venue.name} </b>
                          </span>
                        </Link>
                      </div>
                      {status === 'ongoing' ? (
                        <div className={styles.cardText}>
                          <Ongoing />
                          <span className={styles.cardTime}>
                            <b>{t('ATTENDTHEEVENT.ongoing')}</b>
                          </span>
                        </div>
                      ) : (
                        <div className={styles.cardText}>
                          <Calendar />
                          <b>
                            {format(
                              new Date(utcToZonedTime(start_date, 'Europe/Paris')),
                              baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm',
                              {locale: baseLanguage}
                            )}
                          </b>
                        </div>
                      )}
                    </div>
                    <ArrowIcon className={styles.cardArrow} />
                  </div>
                </Link>
              ))}
          </>
        )}
        {attendedList?.interested_events.length > 0 && (
          <>
            <p className={`mt8 mb16 ${styles.subtitle}`}>{t('ATTENDTHEEVENT.interested')}</p>
            {attendedList &&
              attendedList.interested_events.map(({picture, id, name, venue, start_date, status}, index) => (
                <Link key={index} className={styles.card} to={`${Path.EventDetails}/${id}`}>
                  <img
                    className={styles.cardImg}
                    src={picture ? (picture.includes('https:') ? picture : `${API_BASE_URL}${picture}`) : defaultImage}
                    width='64px'
                    height='64px'
                    alt='Event'
                  />

                  <div className={styles.cardCol}>
                    <h2 className={`${styles.cardTitle} truncated`}>{name}</h2>
                    <div className={`${styles.cardRow} truncated`}>
                      <div className={styles.cardText}>
                        <Location />
                        <Link to={`${Path.VenueDetails}/${venue.id}`}>
                          <span className={styles.cardVenue}>
                            <b>{venue.name} </b>
                          </span>
                        </Link>
                      </div>
                      {status === 'ongoing' ? (
                        <div className={styles.cardText}>
                          <Ongoing />
                          <span className={styles.cardTime}>
                            <b>{t('ATTENDTHEEVENT.ongoing')}</b>
                          </span>
                        </div>
                      ) : (
                        <div className={styles.cardText}>
                          <Calendar />
                          <b>
                            {format(
                              new Date(utcToZonedTime(start_date, 'Europe/Paris')),
                              baseLanguage === ru ? 'dd MMM, HH:mm' : 'EEE, dd MMM, HH:mm',
                              {locale: baseLanguage}
                            )}
                          </b>
                        </div>
                      )}
                    </div>
                    <ArrowIcon className={styles.cardArrow} />
                  </div>
                </Link>
              ))}
          </>
        )}

        <Button className={styles.btn} outlined onClick={() => navigate(Path.AttendTheEventChangeParams)}>
          {t('ATTENDTHEEVENT.search')}
        </Button>
      </>
    </PageWrap>
  );
}
