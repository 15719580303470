import React from 'react';
import PageWrap from '../../../layout/PageWrap';
import styles from './AttendTheEventEmptyResults.module.scss';
import {Path} from '../../../app/const';
import {useTranslation} from 'react-i18next';
import image from './image/empty_list.png';
import image2 from './image/image2.png';
import image3 from './image/empty.png';
import attend from './image/attend.png';
import attendEmpty from './image/attend-empty.png';
import Button from '../../../shared/Button';
import {useAppSelector} from '../../../app/hooks';
import {selectInterestsList} from '../../BrowseInterests/interestsSlice';
import {selectCandidates} from '../../MeetSomeone/searchSlice';
import {searchResults, getSearchedData} from '../eventsSlice';
import {useAppDispatch} from '../../../app/hooks';
import {useNavigate} from 'react-router-dom';

interface propsType {
  type?: 'meetsomeone' | 'attend';
}

const AttendTheEventEmptyResults = ({type = 'meetsomeone'}: propsType) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {candidates} = useAppSelector(selectCandidates);
  const attendTheEventData = useAppSelector(searchResults);
  //Attend the event search results
  const isEmpty = attendTheEventData.events === 0;
  const dispatch = useAppDispatch();

  const isEmpty2 = candidates.length === 0 && candidates.length === 0 ? true : false;

  // dispatch(getSearchedData({id: uuid, type: type === 'matching' ? 'matching' : 'other'}))

  const getSliderData = (type: 'matching' | 'other') => {
    dispatch(getSearchedData({id: attendTheEventData.id, type: type})).then((res) => {
      if (res.type && res.type.includes('fulfilled')) {
        navigate(`${Path.MatchingResultsEvent}?uuid=${attendTheEventData.id}&type=${type}`);
      }
    });
  };

  type === 'attend'
    ? t('ATTENDTHEEVENTCHANGEPARAMS.attend')
    : type === 'meetsomeone'
    ? t('ATTENDTHEEVENTCHANGEPARAMS.attend')
    : t('ATTENDTHEEVENTCHANGEPARAMS.attend');

  return (
    <PageWrap
      title={
        type === 'attend'
          ? t('ATTENDTHEEVENTCHANGEPARAMS.attend')
          : type === 'meetsomeone'
          ? t('MEETSOMEONE.meet')
          : t('ATTENDTHEEVENTCHANGEPARAMS.attend')
      }
      goBack={
        type === 'attend'
          ? Path.AttendTheEventChangeParams
          : type === 'meetsomeone'
          ? Path.MeetSomeone
          : Path.BrowseInterests
      }
      mainClass={styles.root}
    >
      {type === 'attend' && isEmpty && <img className='mb16' src={attendEmpty} alt='empty' />}
      {type === 'attend' && !isEmpty && <img className='mb16' src={attend} alt='empty' />}

      {type === 'meetsomeone' && !isEmpty2 && <img className='mb16' src={image2} alt='empty' />}
      {type === 'meetsomeone' && isEmpty2 && <img className='mb16' src={image3} alt='empty' />}

      {type === 'attend' &&
        (isEmpty ? (
          <>
            <b> {t('BROWSEINTERESTS.sorry')} </b>
            <p> {t('BROWSEINTERESTS.anything')}</p>
            <p className='mt16'> {t('BROWSEINTERESTS.change')}</p>
            <Button className={styles.button} to={Path.AttendTheEventChangeParams} outlined>
              {t('BROWSEINTERESTS.change2')}
            </Button>
          </>
        ) : (
          <>
            <b> {t('BROWSEINTERESTS.amazing')}</b>
            <p>
              {t('BROWSEINTERESTS.found')} <b>{attendTheEventData.events}</b>
              {t('BROWSEINTERESTS.wanted3')} <b>{attendTheEventData.interested}</b>
              {t('BROWSEINTERESTS.other2')}
            </p>
            <p>
              {t('BROWSEINTERESTS.but')}
              <b>{attendTheEventData.more_events}</b>
              {t('BROWSEINTERESTS.more')}
              <b>{attendTheEventData.more_interested}</b>
              {t('BROWSEINTERESTS.nearby')}
            </p>

            <Button className={styles.button} onClick={() => getSliderData('matching')}>
              {t('BROWSEINTERESTS.seematch')}
            </Button>
            {attendTheEventData.more_events > 0 && (
              <Button className={'mt16'} onClick={() => getSliderData('other')} outlined>
                {t('BROWSEINTERESTS.seeother')}
              </Button>
            )}
            <Button className={'mt16'} to={Path.AttendTheEventChangeParams} outlined>
              {t('BROWSEINTERESTS.change2')}
            </Button>
          </>
        ))}

      {type === 'meetsomeone' &&
        (isEmpty2 ? (
          <>
            <b> {t('BROWSEINTERESTS.sorry')} </b>
            <p> {t('BROWSEINTERESTS.anything')}</p>
            <p className='mt16'> {t('BROWSEINTERESTS.change')}</p>
            <Button className={styles.button} to={Path.MeetSomeone} outlined>
              {t('BROWSEINTERESTS.change2')}
            </Button>
          </>
        ) : (
          <>
            <b> {t('BROWSEINTERESTS.amazing')}</b>
            <p>
              {t('BROWSEINTERESTS.found2')} <b>{candidates.length}</b>
              {t('BROWSEINTERESTS.persons')}
            </p>
            <p className='mt16'> {t('BROWSEINTERESTS.choose')}</p>

            <Button className={styles.button} to={Path.SearchResults}>
              {t('BROWSEINTERESTS.see')}
            </Button>

            <Button className={'mt16'} to={Path.MeetSomeone} outlined>
              {t('BROWSEINTERESTS.change2')}
            </Button>
          </>
        ))}
    </PageWrap>
  );
};

export default AttendTheEventEmptyResults;
