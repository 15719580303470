import React from 'react';
import PageWrap from '../../../layout/PageWrap';
import EnterCode from '../../SignIn/steps/EnterCode';
import {useTranslation} from 'react-i18next';
import {Path} from '../../../app/const';

function VenuePhoneNumber() {
  const {t} = useTranslation();
  return (
    <PageWrap title={t('VENUECHANGENUMBER.contact')} goBack={Path.VenueSetLocation}>
      <EnterCode type='venue_creation' />
    </PageWrap>
  );
}

export default VenuePhoneNumber;
