import {Path} from '../../app/const';
import Button from '../Button';
import anonymousImg from '../../assets/images/anonymous.png';
import styles from './MissingData.module.scss';
import {useTranslation} from 'react-i18next';

interface MissingDataProps {
  onClick: () => any;
}

export default function MissingData({onClick}: MissingDataProps) {
  const {t} = useTranslation();
  return (
    <div className={styles.root}>
      <img className={styles.img} src={anonymousImg} width='343px' height='259px' alt='Anonymous' />
      <p>{t('MISSINGDATA.you')}</p>
      <p>{t('MISSINGDATA.it')}</p>

      <Button className={styles.btn} to={Path.Profile}>
        {t('MISSINGDATA.profile')}
      </Button>
      <Button outlined onClick={() => onClick()}>
        {t('MISSINGDATA.continue')}
      </Button>
    </div>
  );
}
