import {ReactComponent as MaleIcon} from '../../assets/icons/male.svg';
import {ReactComponent as FemaleIcon} from '../../assets/icons/female.svg';
import styles from './ProfileCard.module.scss';
import {calculateAge} from '../../app/const';
import anonymous from '../../assets/images/anonym.png';
import {API_BASE_URL} from '../../app/const';
import {ReactComponent as Verified} from '../../assets/icons/is_approved.svg';

interface ProfileCardProps {
  className?: string;
  picture: string;
  first_name: string;
  gender: string;
  city: string;
  birth_date: string | null;
  interests?: string[];
  tagList?: string[];
  bio?: string;
  children?: any;
  profile_type?: string;
  is_verified?: boolean;
}

export default function ProfileCard({
  className = '',
  picture,
  first_name,
  gender,
  city,
  birth_date,
  interests,
  bio,
  children,
  is_verified
}: ProfileCardProps) {
  const age = calculateAge(birth_date);
  const pictureLink = picture ? (picture.includes('https://') ? picture : `${API_BASE_URL}${picture}`) : anonymous;
 
  return (
    <div className={`${styles.root}${className && ` ${className}`}`}>
      <img className={styles.photo} src={pictureLink} width='343px' height='343px' alt='Profile' />
      <div className='profileCardInfo'>
        <div className={styles.name}>
          <span className='title truncated'>{first_name ? first_name : 'Anonymous User'}</span>
          {is_verified && <Verified />}
        </div>
        <span className='details truncated row'>
          {gender === 'male' && <MaleIcon height={'14px'} className={styles.gender} aria-label='Male gender' />}
          {gender === 'female' && <FemaleIcon height={'14px'} className={styles.gender} aria-label='Female gender' />}
          {city && `${city}, `} {} {age} y.o.
        </span>
        {interests?.length && interests?.length > 0 ? (
          <div className={styles.tagList}>
            {interests.map((tag: string, index: any) => (
              <span key={index} className={styles.tag}>
                {tag}
              </span>
            ))}
          </div>
        ) : null}
        {bio && <p className={styles.bio}>{bio}</p>}
      </div>
      {children}
    </div>
  );
}
