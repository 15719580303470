import {Path} from '../../app/const';
import Button from '../Button';
import creator from '../../assets/images/creator.png';
import user from '../../assets/images/usermode.png';
import styles from './CreatorMode.module.scss';
import PageWrap from '../../layout/PageWrap';
import {switchToCreator, switchToUser, selectProfileType, selectProfileUser} from '../../app/profileSlice';
import {useAppSelector, useAppDispatch} from '../../app/hooks';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export default function CreatorMode() {
  const dispatch = useAppDispatch();
  const profile_type = useAppSelector(selectProfileType);
  const {profile} = useAppSelector(selectProfileUser);
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <PageWrap
      mainClass={styles.root}
      title={profile_type === 'default' ? 'Creator mode' : 'User mode'}
      goBack={Path.Profile}
      // onClick={select && closeSelect}
    >
      <div className={styles.root}>
        <img
          className={styles.img}
          src={profile_type === 'default' ? creator : user}
          width='343px'
          height='259px'
          alt='Anonymous'
        />

        {profile_type === 'default' ? (
          <p className='mt-8'>
            {t('CREATORMODE.want')}
            <b>{t('CREATORMODE.want4')} </b>
            {t('CREATORMODE.want5')}
          </p>
        ) : (
          <>
            <p className='mt-8'>
              {t('CREATORMODE.want1')}
              <b>{t('CREATORMODE.want2')} </b>
              {t('CREATORMODE.want3')}
            </p>
          </>
        )}

        {profile_type === 'default' ? (
          <p className='mt-8'>{t('CREATORMODE.you')}</p>
        ) : (
          <p className='mt-8'>{t('CREATORMODE.you1')}</p>
        )}

        <Button
          className={styles.btn}
          onClick={() =>
            profile_type === 'default'
              ? dispatch(switchToCreator()).then((res) => {
                  if (res.type && res.type.includes('fulfilled')) {
                    res.payload.full_name
                      ? navigate('/')
                      : navigate(`${Path.EditPersonalInformation}?type=create`, {
                          state: {user_full_name: profile.first_name}
                        });
                  }
                })
              : dispatch(switchToUser()).then((res) => {
                  if (res.type && res.type.includes('fulfilled')) {
                    navigate(Path.Root);
                  }
                })
          }
        >
          {t('CREATORMODE.switch')}
        </Button>
      </div>
    </PageWrap>
  );
}
