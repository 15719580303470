import {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {deleteVenueImage, getVenueImages, postVenueImage} from '../venuesAPI';
import {deleteEventImage, getEventImages, postEventImage} from '../../EventCreator/eventsAPI';
import PageWrap from '../../../layout/PageWrap';
// import Button from '../../../shared/Button';
import {ReactComponent as RemoveIcon} from '../../../assets/icons/remove.svg';
import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';
import styles from './VenuePhotos.module.scss';
import {useTranslation} from 'react-i18next';
import {useSearchParams, useLocation} from 'react-router-dom';
import Button from '../../../shared/Button';
import {Path} from '../../../app/const';

export default function VenuePhotos() {
  const {venueId} = useParams();
  const [searchParams] = useSearchParams();
  const [images, setImages] = useState([]);
  const type = searchParams.get('type');
  const {pathname} = useLocation();
  const url = pathname.split('/').slice(0, -1).join('/');
  const id = pathname.split('/').slice(-1)[0];

  // const url = searchParams.get('url');
  // const id = searchParams.get('id');

  const input = useRef<any>(null);

  const pageType = url === '/event-creator/event-photos' ? 'event' : 'venue';

  useEffect(() => {
    pageType === 'event' ? id && getEventImages(id, setImages) : venueId && getVenueImages(venueId, setImages);
  }, []);

  const formData = new FormData();

  const addImage = (event: any) => {
    let fileItem = event?.target?.files[0];
    if (fileItem) {
      formData.append('image', fileItem);
      pageType === 'venue' &&
        venueId &&
        postVenueImage(venueId, formData).then(() => {
          input.current.value = null;
          getVenueImages(venueId, setImages);
        });
      pageType === 'event' &&
        id &&
        postEventImage(id, formData).then(() => {
          input.current.value = null;
          getEventImages(id, setImages);
        });
    }
  };

  const deleteImage = (imageId: string) => () => {
    pageType === 'venue' &&
      venueId &&
      deleteVenueImage(venueId, imageId).then(() => getVenueImages(venueId, setImages));
    pageType === 'event' && id && deleteEventImage(id, imageId).then(() => getEventImages(id, setImages));
  };

  const {t} = useTranslation();

  console.log(pageType);

  return (
    <PageWrap
      mainClass={styles.root}
      title={pageType === 'event' ? t('VENUEPHOTOS.event') : t('VENUEPHOTOS.venue')}
      goBack={-1}
    >
      <p className='mb24'>{t('VENUEPHOTOS.attach')}</p>

      <div className={styles.grid}>
        {images?.length
          ? images.map(({id, image}) => (
              <div key={id} className={styles.imgWrap}>
                <img className={styles.img} src={image ? image : ''} alt={t('VENUEPHOTOS.venue1')} />
                <button
                  className={`${styles.imgRemoveBtn} hover`}
                  type='button'
                  aria-label='Remove photo'
                  onClick={deleteImage(id)}
                >
                  <RemoveIcon />
                </button>
              </div>
            ))
          : null}
        {images?.length < 5 && (
          <div className={styles.imgWrap}>
            <label className={`${styles.imgAddBtn} hover`} aria-label='Add photo'>
              <input className='visuallyHidden' type='file' ref={input} accept='image/*' onChange={addImage} />
              <AddIcon />
            </label>
          </div>
        )}
      </div>
      <footer className={styles.footer}>
        {type === 'creator' && <Button to={Path.EventCreator}>{t('NOEVENTS.add')}</Button>}
      </footer>
    </PageWrap>
  );
}
