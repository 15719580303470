import {useParams} from 'react-router-dom';
import {interestedEvents} from '../../../app/const';
import PageWrap from '../../../layout/PageWrap';
import EventEditCard from '../../../shared/EventEditCard';
import {useTranslation} from 'react-i18next';

export default function EventDetails() {
  const {eventId} = useParams();
  const {t} = useTranslation();

  return (
    <PageWrap mainClass='p16' goBack={'/event-creator'} title={t("EVENTDETAILS.edit")}>
      <EventEditCard id={eventId} {...interestedEvents[Number(eventId)]} />
    </PageWrap>
  );
}
