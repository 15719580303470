import PageWrap from '../../layout/PageWrap';
import {useTranslation} from 'react-i18next';

import FirstStep from '../SignUp/steps/FirstStep';
import styles from './ChooseLanguage.module.scss';

const ChooseLanguage = () => {
  const {t} = useTranslation();

  return (
    <PageWrap isProtected={false}>
      <div className={styles['root']}>
        <FirstStep />
      </div>
    </PageWrap>
  );
};

export default ChooseLanguage;
