import {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {deleteVenue} from '../venuesAPI';
import PageWrap from '../../../layout/PageWrap';
import Button from '../../../shared/Button';
import styles from './VenueRemove.module.scss';
import {useTranslation} from 'react-i18next';
import removeImg from './images/remove.png';

export default function VenueRemove() {
  const {venueId} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const removeVenue = () => venueId && deleteVenue(venueId, setLoading, navigate);
  const goBack = () => navigate(-1);
  const {t} = useTranslation();

  return (
    <PageWrap mainClass={styles.root} title={t('VENUEREMOVE.remove1')} goBack={-1}>
      <img className={styles.img} src={removeImg} width='343px' height='259px' alt='Venue' />
      <p>{t('VENUEREMOVE.are')}</p>
      <Button className={styles.btn} disabled={loading} onClick={removeVenue}>
        {loading ? t('VENUEREMOVE.removing') : t('VENUEREMOVE.remove')}
      </Button>
      <Button className={'mt16'} outlined disabled={loading} onClick={goBack}>
        {t('VENUEREMOVE.no')}
      </Button>
    </PageWrap>
  );
}
