import React from 'react';
import Skeleton from 'react-skeleton-loader';
import styles from './SkeletonChat.module.scss';

function SkeletonChat() {
  return (
    <div className={styles.skeleton__template}>
      <div className={styles.skeleton__template_img}>
        <Skeleton width='64px' height='64px' widthRandomness='0' borderRadius='100%' />
      </div>

      <div className={styles.skeleton__template_col}>
        <Skeleton width='285px' widthRandomness='0' height='24px' />
        <Skeleton width='285px' widthRandomness='0' height='24px' />
      </div>
    </div>
  );
}

export default SkeletonChat;
