import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useAppDispatch} from '../../../app/hooks';
import {Path} from '../../../app/const';
import PageWrap from '../../../layout/PageWrap';
import EventCard from '../../../shared/EventCard';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {useNavigate} from 'react-router-dom';
import {getSingleEvent} from '../../AttendTheEvent/eventsSlice';
import styles from './EventDetails.module.scss';
import {useTranslation} from 'react-i18next';

export default function EventDetails() {
  const {eventId} = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getSingleEvent(eventId));
  }, []);

  const handleBackBtn = () => {
    navigate(Path.EventCreator);
  };

  return (
    <PageWrap
      mainClass={styles.root}
      goBack={'/attend-the-event'}
      headerButtonFunction={() => navigate(`/event-creator/event-edit/${eventId}`)}
      icon={<Edit />}
      title={t('EVENTDETAILS.event')}
      onClick={handleBackBtn}
    >
      <EventCard />
    </PageWrap>
  );
}
