import PageWrap from '../../layout/PageWrap';
import {useTranslation} from 'react-i18next';
import EnterCode from '../SignIn/steps/EnterCode';
import {useSearchParams} from 'react-router-dom';

const EditNumber = () => {
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  return (
    <PageWrap isProtected={true} title={t('PROFILE.change2')} goBack={-1}>
      <EnterCode type={type === 'creation' ? type : 'update'} />
    </PageWrap>
  );
};

export default EditNumber;
