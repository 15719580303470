import styles from './SecondStep.module.scss';
import cherry from './image/cherry.png';
import Button from '../../../../shared/Button';
import {Path} from '../../../../app/const';
import {useTranslation} from 'react-i18next';

interface SecondStepProps {
  nextStep: (() => any) | any;
}

const SecondStep = ({nextStep}: SecondStepProps) => {
  const {t} = useTranslation();
  return (
    <div className={styles['root']}>
      <div className={styles['hero']}>
        <h2 className={styles['hero__title']}>
          <img src={cherry} alt='cherry' />
          {t('SIGNIN.welcome')}
        </h2>
        <p className={styles['hero__text']}>{t('SIGNIN.chat')}</p>
        <p className={styles['hero__text']}>{t('SIGNIN.ready')}</p>
      </div>

      <p className={`${styles['hero__text']} mt32`}>
        <b>{t('SIGNIN.login')}</b>
        {t('SIGNIN.or')} <b>{t('SIGNIN.create')}</b>
        {t('SIGNIN.if')}
        <br />
        <br />
        {t('SIGNIN.have')}
      </p>
      <Button to={Path.SignIn} onClick={() => localStorage.setItem('type', 'old')} className={'mb16'}>
        {t('SIGNIN.yes')}
      </Button>
      <Button onClick={() => nextStep(3)} className={styles.btn} outlined>
        {t('SIGNIN.no')}
      </Button>
    </div>
  );
};

export default SecondStep;
