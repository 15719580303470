import React from 'react';
import {GoogleMap, Marker, InfoWindow, useJsApiLoader} from '@react-google-maps/api';
import styles from './SelectPosition.module.scss';

// Map Settings
import {center, options} from './settings';

import CurrentLocation from './CurrentLocation';

import {GOOGLE_MAP_KEY} from '../../app/const';

// Components

import SearchPanel from './SearchPanel/SearchPanel';

// Image

export type WeatherType = {
  temp: number;
  text: string;
};

export type MarkerType = {
  id: string;
  location: google.maps.LatLngLiteral;
  name: string;
  phone_number: string;
  website: string;
};

interface SelectPositionProps {
  setPosition: any;
  mapHeigh?: number;
  position?: {
    latitude: number;
    longitude: number;
  } | null;
  basicPosition?: {
    lat: number;
    lng: number;
  } | null;
  basicCenter?: {
    lat: number;
    lng: number;
  } | null;
}

interface coordiantesProps {
  lat: number;
  lng: number;
}

const SelectPosition = ({
  setPosition,
  mapHeigh = 504,
  basicCenter = null,
  position,
  basicPosition
}: SelectPositionProps) => {
  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries: ['places']
  });

  // Save map in ref if we want to access the map
  const mapRef = React.useRef<google.maps.Map | null>(null);

  const [clickedPos, setClickedPos] = React.useState<google.maps.LatLngLiteral>({} as google.maps.LatLngLiteral);
  const [selectedMarker, setSelectedMarker] = React.useState<MarkerType>({} as MarkerType);

  const panTo = React.useCallback(({lat, lng}: coordiantesProps) => {
    mapRef?.current?.panTo({lat, lng});
    mapRef?.current?.setZoom(16);
    setClickedPos({lat: lat, lng: lng});
    setPosition({latitude: lat, longitude: lng});
  }, []);

  const onLoad = (map: google.maps.Map): void => {
    mapRef.current = map;
  };

  const onUnMount = (): void => {
    mapRef.current = null;
  };

  const onMapClick = (e: any) => {
    setClickedPos({lat: e.latLng.lat(), lng: e.latLng.lng()});
    setPosition({latitude: e.latLng.lat(), longitude: e.latLng.lng()});
    setSelectedMarker({} as MarkerType);
  };

  const onMarkerClick = (marker: MarkerType) => setSelectedMarker(marker);

  if (!isLoaded) return <div>Map Loading ...</div>;

  const nearbyPositions: any = [];

  const moveTo = (position: google.maps.LatLngLiteral) => {
    if (mapRef.current) {
      mapRef.current.panTo({lat: position.lat, lng: position.lng});
      setPosition({latitude: position.lat, longitude: position.lng});
      mapRef.current.setZoom(15);
      setClickedPos(position);
    }
  };

  return (
    <>
      <div className={`${styles.root} mb32`}>
        <SearchPanel panTo={panTo} />

        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: `${mapHeigh}px`
          }}
          options={options as google.maps.MapOptions}
          center={position ? {lat: position.latitude, lng: position.longitude} : center}
          zoom={5}
          onLoad={onLoad}
          onUnmount={onUnMount}
          onClick={onMapClick}
        >
          {clickedPos.lat ? (
            <Marker position={clickedPos} />
          ) : (
            <Marker
              position={{
                lat: basicPosition?.lat ? basicPosition?.lat : center.lat,
                lng: basicPosition?.lng ? basicPosition?.lng : center.lng
              }}
            />
          )}

          {nearbyPositions.length > 0 &&
            nearbyPositions?.map((marker: any) => (
              <Marker key={marker.id} position={marker.location} onClick={() => onMarkerClick(marker)} />
            ))}
          {selectedMarker.location && (
            <InfoWindow position={selectedMarker.location} onCloseClick={() => setSelectedMarker({} as MarkerType)}>
              <div>Your current position</div>
            </InfoWindow>
          )}
        </GoogleMap>

        <CurrentLocation moveTo={moveTo} />
      </div>
    </>
  );
};

export default SelectPosition;
