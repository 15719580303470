import React from 'react';
import styles from './CurrentLocation.module.scss';
import location from './icon/location.png';

type Props = {
  moveTo: (position: google.maps.LatLngLiteral) => void;
};

const CurrentLocation: React.FC<Props> = ({moveTo}) => {
  const [disabled, setDisabled] = React.useState(false);

  return (
    <button
      disabled={disabled}
      className={styles.btn}
      onClick={() => {
        // Deactivate button when geolocation is working
        setDisabled(true);
        navigator.geolocation.getCurrentPosition((position) => {
          // Activate button when geolocation has finished
          setDisabled(false);
          // Call the callback function
          moveTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        });
      }}
    >
      <img src={location} alt='location' />
    </button>
  );
};

export default CurrentLocation;
