import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../app/store';
import { API_BASE_URL } from '../../app/const';

export interface Interest {
  id: number;
  name: string;
}
export interface Profile {
  id: number;
  first_name: string;
  picture: string;
  gender: string;
  bio: string;
  birth_date: string;
  interests: any;
  city: string;
}
export interface searchInterface {
  loading: string;
  error: string | undefined;
  params: {
    gender: 'male' | 'female' | null;
    age_from: number;
    age_to: number;
    interests: [];
  };
  search_id: string,
  last_coordinates: {
    latitude: number,
    longitude: number
  } | null,
  candidates: Profile[];
  singleCandidate: Profile | null;
}

export interface Root {
  profiles: Profile[];
}

export const initialState: searchInterface = {
  loading: '',
  error: '',
  params: {
    gender: null,
    age_from: 18,
    age_to: 60,
    interests: []
  },
  search_id: "",
  last_coordinates: null,
  candidates: [],
  singleCandidate: null
};

export const fetchPersonalData = createAsyncThunk('GET_PERSONAL_INFO', async () => {
  const response = await axios.get(`${API_BASE_URL}/profiles/my/`);
  return response.data;
});

export const getCandidates = createAsyncThunk('GET_CANDIDATES', async (body: any) => {
  const response = await axios.post(`${API_BASE_URL}/searches/default/`, body);
  return response.data;
});

export const getCandidatesArr = createAsyncThunk('GET_CANDIDATES_ARRAY', async (body: {
  coordiantes: {
    latitude: number,
    longitude: number
  }
  id: string
}) => {
  const response = await axios.post(`${API_BASE_URL}/searches/default/${body.id}/`, body.coordiantes);
  return response.data;
});

export const getCandidatesList = createAsyncThunk('GET_CANDIDATES_LIST', async (uuid: any) => {
  const response = await axios.get(`${API_BASE_URL}/searches/default/${uuid}/`);
  return response.data;
});


export const getLastSearchCoordinates = createAsyncThunk('GET_LAST_COORDINATES', async () => {
  const response = await axios.get(`${API_BASE_URL}/searches/my/last-search/`);
  return response.data;
});



export const getSingleUser = createAsyncThunk('GET_SINGLE_PROFILE', async (id: number) => {
  const response = await axios.get(`${API_BASE_URL}/profiles/from-chat/${id}/`);
  return response.data;
});

export const getListOfInteredPeople = createAsyncThunk(
  'GET_LIST_OF_INTERESTED_PEOPLE',
  async (requestparams: { uuid: string; interest: string }) => {
    const response = await axios.get(
      `${API_BASE_URL}/searches/browse/${requestparams.uuid}/?interest=${requestparams.interest}`
    );
    return response.data;
  }
);

export const getAttendedPeople = createAsyncThunk(
  'GET_ATTENDED_PEOPLE',
  async (requestParams: {
    id: string | number;
    type: string;
    body: {
      gender?: string;
      age_from: number;
      age_to: number;
    };
  }) => {
    const response = await axios.post(
      `${API_BASE_URL}/searches/event/${requestParams.id}/${requestParams.type}/`,
      requestParams.body
    );
    return response.data;
  }
);

const searchSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSearchParams: (state: any, action: PayloadAction<{} | null>) => {
      state.params = action.payload;
    }
  },
  extraReducers(builder) {
    builder

      //GET CANDIDATES INFO REQUEST
      .addCase(getCandidates.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getCandidates.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.search_id = action.payload.id
      })
      .addCase(getCandidates.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET CANDIDATES LIST REQUEST
      .addCase(getCandidatesList.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getCandidatesList.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.candidates = action.payload.profiles;
      })
      .addCase(getCandidatesList.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET CANDIDATES LIST REQUEST
      .addCase(getCandidatesArr.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getCandidatesArr.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.candidates = action.payload.profiles;
      })
      .addCase(getCandidatesArr.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })


      //GET LAST SEARCH COORDINATES
      .addCase(getLastSearchCoordinates.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getLastSearchCoordinates.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.last_coordinates = action.payload
      })
      .addCase(getLastSearchCoordinates.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })




      //GET LIST OF INTERESTED PEOPLE

      .addCase(getListOfInteredPeople.pending, (state, action) => {
        state.loading = 'loading';
        state.candidates = [];
      })
      .addCase(getListOfInteredPeople.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.candidates = action.payload.profiles;
      })
      .addCase(getListOfInteredPeople.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET ATTENDED PEOPLE FOR SINGLE EVENT (USER MODE)

      .addCase(getAttendedPeople.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getAttendedPeople.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.candidates = action.payload.profiles;
      })
      .addCase(getAttendedPeople.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      })

      //GET SINGLE USER INFO REQUEST
      .addCase(getSingleUser.pending, (state, action) => {
        state.loading = 'loading';
      })
      .addCase(getSingleUser.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.singleCandidate = action.payload;
      })
      .addCase(getSingleUser.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.error.message;
      });
  }
});

export const selectCandidates = (state: RootState) => state.candidates;
export const singleCandidate = (state: RootState) => state.candidates.singleCandidate;

export const { setSearchParams } = searchSlice.actions;

export default searchSlice.reducer;
